@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 660px) {
}
@media only screen and (min-width: 770px) {
}
@media only screen and (min-width: 1000px) {
}

@media only screen and (min-width: 0px) and (max-width: 1199px) {
    body {
        padding-top: $header-height-sp;
    }

    html:not(.global-nav-active) {
        .global-nav {
            transform: scale(1.2);
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
        }
    }

    html.global-nav-active {
        overflow: hidden;

        .global-nav {
            transform: scale(1);
            visibility: visible;
            opacity: 1;
            transition: all 250ms $easingDecelerate;
        }
    }


    header {
        // 共通
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 50000;
        box-sizing: border-box;
        width: 100%;
        // スマホ
        height: $header-height-sp;
        padding: 0 15px;
        box-shadow: 0 0 15px 0px rgba(0,0,0,0.16);
        background: $color-white;

        .logo-nav {
            margin-top: 0;
            margin-bottom: 0;

            .logo {
                display: block;
                font-size: 20px;
                letter-spacing: -0.05em;

                img {
                    display: block;
                    width: 200px;
                    height: 20px;
                }
            }
        }
        .global-nav-btn {
            display: block;
            position: absolute;
            top: 0;
            right: 2px;
            bottom: 0;
            z-index: 1000;
            transform: scale(0.8);
            width: 50px;
            height: 50px;
        }
        .global-nav {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow-x: auto;
            box-sizing: border-box;
            background: rgba(255,255,255,0.95);

            & > ul {
                padding: 50px 10px; // firefoxで余白がつかない
            }

            ul {
                flex-direction: column;
                justify-content: flex-start;
                flex: 1 0 auto;
                width: 230px;

                .item {
                    width: 100%;
                    a {
                        padding: 18px 15px;
                        font-weight: bold;
                        font-size: 1em;

                        &:not(.btn):hover {
                            background-color: $color-bg;
                        }
                    }
                }

                .nav-nest {
                    position: relative;
                    width: 100%;
                    height: auto;
                    margin: 0;
                    padding: 0;
                    border: 1px solid $color-border;

                    border-left: 0;
                    border-right: 0;

                    a {
                        padding-left: 52px;
                    }

                     + .nav-nest {
                        border-top: 0;
                    }

                    &.icon::before {
                        position: absolute;
                        top: 12px;
                        left: 15px;
                        z-index: 10;
                        font-size: 26px;
                    }
                }

                li + li {
                    margin-left: 0;
                }
                li.login {
                    margin-top: 30px;

                    a {
                        min-width: 230px;
                        padding: 18px 3em;
                    }
                }

                // ハンバーガーメニューを上下中央寄せにする
                & > li:first-child {
                    margin-top: auto;
                }

                & > li:last-child {
                    margin-bottom: auto;
                }
            }
        }
    }
}

@media only screen and (min-width: 660px) and (max-width: 1199px) {

    header {
        .global-nav {

            ul {
                width: 480px;

                .item a {
                    padding: 22px 20px;
                }

                .nav-nest a {
                    padding-left: 55px;
                }

                .nav-nest.icon::before {
                    top: 18px;
                    left: 20px;
                }
            }
        }
    }
}
@media only screen and (min-width: 1200px) {
    body {
        padding-top: $header-height-pc;
    }
    header {
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 50000;
        box-sizing: border-box;
        width: 100%;
        height: $header-height-pc;
        padding-right: 20px;
        padding-left: 20px;
        box-shadow: 0 0 15px 0px rgba(0,0,0,0.16);
        background: $color-white;
        font-weight: bold;

        .logo-nav {
            margin-top: 0;
            margin-bottom: 0;

            .logo {
                display: block;
                font-size: 30px;
                letter-spacing: -0.05em;

                img {
                    display: block;
                    height: 30px;
                }
            }
        }
        .global-nav-btn {
            display: none;
        }
        .global-nav {
            display: block;
            margin-left: auto;

            .logo-sp {
                display: none;
            }

            ul {
                width: 100%;

                & > li {
                    position: relative;

                    a {
                        height: $header-height-pc;
                    }

                     + li {
                        margin-left: 20px;
                    }
                }

                li.item a {
                    position: relative;
                    width: calc(100% + 20px);
                    padding: 10px 0px 11px;
                    color: $color-link;
                    letter-spacing: 0.01em;
                }

                li.current::after {
                    position: absolute;
                    right: 0;
                    bottom: 20px;
                    left: 0;
                    width: 100%;
                    height: 4px;
                    border-radius: 100em;
                    background-color: $color-link;
                    opacity: 0.2;
                    content: "";
                    pointer-events: none;
                }

                li.nav-nest {
                    padding: 0 20px;

                    // アイコン
                    &::before {
                        margin-right: 20px;
                        font-size: 26px;
                    }

                    &.nav-recruitment {
                        &::before {
                            color: $color-recruitment;
                        }
                    }

                    &.nav-job-seeking {
                        &::before {
                            color: $color-job;
                        }
                    }
                }

                li.login {
                    .btn {
                        min-width: 110px;
                        height: 36px;
                        font-size: 14px;

                        &::after {
                            content: none;
                        }
                    }
                }
            }

            .global-nav-line {
                position: absolute;
                bottom: 20px;
                height: 4px;
                border-radius: 100em;
                background-color: $color-link;
                pointer-events: none;
                transition: all 250ms $easingDecelerate;
            }
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1270px) {
    header {
        .global-nav {
            li.item-home {
                position: absolute;
                overflow: hidden;
                visibility: hidden;
                width: 0;
            }
        }
    }
}




/* ハンバーガーメニューボタン */
.global-nav-btn {
    display: block;
    position: relative;
    width: 32px;
    height: 32px;
    padding: 0;
    border: none;
    cursor: pointer;
    transition: all 200ms $easingStandard;

    span {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 30px;
        height: 2px;
        margin: auto;
        border-radius: 1px;
        background: $color-btn;
        @mixin mix-transition;
    }
    span:nth-child(1) {
        transform: translate(0px, -10px);
    }
    span:nth-child(4) {
        transform: translate(0px, 10px);
    }

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
        display: none;
    }
}

html.global-nav-active {
    .global-nav-btn {
        transform: rotate(90deg);

        span:nth-child(1) {
            transform: translate(0px, 0);
            opacity: 0;
        }
        span:nth-child(4) {
            transform: translate(0px, 0);
            opacity: 0;
        }
        span:nth-child(2) {
            transform: rotate(45deg);
        }
        span:nth-child(3) {
            transform: rotate(-45deg);
        }
    }
}



.skip-link {
    position: absolute;
    top: 1em;
    left: 1em;
    z-index: 100000;

    &:not(:focus) {
        overflow: hidden;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        white-space: nowrap;

        clip: rect(0 0 0 0);
        clip-path: inset(50%);
    }
}
