img {
    max-width: 100%;
}

figure {
    figcaption {
        margin-top: 1em;
        line-height: $line-height-title;
        text-align: center;
    }

    img {
        display: block;
        margin-right: auto;
        margin-left: auto;
    }
}
