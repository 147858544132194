/* アラート、メッセージ、Nag */
/* https://getbootstrap.com/docs/4.1/components/alerts/ */
/* メッセージ */
/* https://semantic-ui.com/collections/message.html */
/* Nag */
/* https://semantic-ui.com/modules/nag.html */
/*----------------------------------------------------------------------------*/
.alert {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.75em 1em;
    border-radius: 4px;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #aaaaaa;
    color: $color-white;
    line-height: $line-height-text;

    .btn {
        display: flex;
        flex: 0 0 2em;
        align-items: center;
        justify-content: center;
        width: 2em;
        min-width: auto;
        height: 2em;
        min-height: auto;
        margin-left: auto;
        padding: 0;
        border: none;
        border-radius: 50%;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.2);
        color: $color-white;
        font-size: 0.5em;
        line-height: 1;
    }
    .btn:hover {
        background-color: rgba(0, 0, 0, 0.5);
        color: $color-white;
    }

    &.alert-danger {
        background-color: $color-danger;
    }
    &.alert-warning {
        background-color: $color-warning;
    }
    &.alert-success {
        background-color: $color-success;
    }
}

.alert-group {
    position: fixed;
    top: calc($header-height-sp + 5px);
    right: 5px;
    left: 5px;
    z-index: 10000;

    .alert:not(:last-child) {
        margin-bottom: 5px;
    }

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1200px) {
        top: calc($header-height-pc + 5px);
    }
}
