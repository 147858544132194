body {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    overflow-x: hidden;
}

article,
.lead {
    margin-bottom: 48px;

    @media only screen and (min-width: 660px) {
        margin-bottom: 40px;
    }
}

main {
    box-sizing: border-box;

    .main-header {
        margin-bottom: 40px;

        @media only screen and (min-width: 480px) {

        }
        @media only screen and (min-width: 660px) {
            margin-bottom: 80px;
        }
        @media only screen and (min-width: 770px) {

        }
        @media only screen and (min-width: 1000px) {
            margin-bottom: 120px;
        }

        & + {
            section {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }
    .main-footer {
        margin-top: 120px;

        .container {
            display: flex;
            justify-content: center;

            & > *:not(:last-child) {
                margin-right: 1em;
            }
        }

        .btn-group {
            width: 100%;

            & > *:not(:last-child) {
                margin-bottom: 0.5em;
            }

            .btn {
                width: 100%;
            }
        }

        @media only screen and (min-width: 480px) {
            .btn-group {
                display: flex;
                justify-content: center;

                & > *:not(:last-child) {
                    margin-bottom: 0;
                    margin-right: 0.5em;
                }

                .btn {
                    width: auto;
                    flex: 0 1 320px;
                }
            }
        }
        @media only screen and (min-width: 660px) {
        }
        @media only screen and (min-width: 770px) {
        }
        @media only screen and (min-width: 1000px) {

        }
    }

}

@media only screen and (min-width: 660px) {

    main > section:last-child {
        margin-bottom: 120px;
    }
}

section {
    margin: 60px 0;

    .section-header {
        margin-bottom: 40px;

        @media only screen and (min-width: 770px) {
            margin-bottom: 60px;
        }
    }

    .section-content + .section-content {
        margin-top: 20px;
    }

    .section-footer {
        margin-top: 30px;
    }

    section:last-child {
        margin-bottom: 0;
    }

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
        margin: 80px 0;

        .section-footer {
            margin-top: 40px;
        }
    }
    @media only screen and (min-width: 770px) {
        /* padding: 120px 0; */
    }
    @media only screen and (min-width: 1000px) {

    }

}


// section 2階層目
article section,
section section {
    margin: 30px 0;

    @media only screen and (min-width: 660px) {
        margin: 40px 0;
    }

    .section-header {
        margin-bottom: 20px;

        @media only screen and (min-width: 480px) {
        }
        @media only screen and (min-width: 660px) {

        }
        @media only screen and (min-width: 770px) {
            margin-bottom: 30px;
        }
        @media only screen and (min-width: 1000px) {

        }
    }

    section:last-child {
        margin-bottom: 0;
    }
}

// section 3階層目
article section section,
section section section {
    margin: 20px 0;

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {
        padding: 30px 0;
    }
    @media only screen and (min-width: 1000px) {

    }

    .section-header {
        margin-bottom: 10px;

        @media only screen and (min-width: 480px) {
            .title {

            }
        }
        @media only screen and (min-width: 660px) {

        }
        @media only screen and (min-width: 770px) {
            margin-bottom: 15px;
        }
        @media only screen and (min-width: 1000px) {

        }
    }

    section:last-child {
        margin-bottom: 0;
    }
}


/*----------------------------------------------------------------------------*/
.container,
.container-harf,
.container-fluid {
    margin: 0 15px;
}
@media only screen and (min-width: 480px) {
    .container,
    .container-harf,
    .container-fluid {
        margin: 0 20px;
    }
}
@media only screen and (min-width: 660px) {
    .container,
    .container-harf,
    .container-fluid {

    }
    .container-sm {
        margin: 0 auto;
        width: 480px;
    }
}
@media only screen and (min-width: 770px) {
    .container-fluid {
        margin: 0 40px;
    }
    .container {
        margin: 0 auto;
        width: 730px;
    }
    .container-sm {
        margin: 0 auto;
        width: 480px;
    }
    .container-md {
        margin: 0 auto;
        width: 660px;
    }
    .container-lg {
        margin: 0 40px;
        width: auto;
    }
    .container-harf {

    }
}
@media only screen and (min-width: 1000px) {
    .container-fluid {
        margin: 0 80px;
    }
    .container {
        margin: 0 auto;
        width: 1000px;
    }
    .container-sm {
        margin: 0 auto;
        width: 480px;
    }
    .container-md {
        margin: 0 auto;
        width: 700px;
    }
    .container-lg {
        margin: 0 80px;
        width: auto;
    }
    .container-harf {

    }
}
@media only screen and (min-width: 1200px) {
}
@media only screen and (min-width: 1400px) {
}

.layout-group > *:not(:last-child) {
    margin-bottom: 20px;
}

.layout-group > *,
.layout {
    & > *:not(:last-child) {
        margin-bottom: 20px;

        @media only screen and (min-width: 480px) {

        }
        @media only screen and (min-width: 660px) {

        }
        @media only screen and (min-width: 770px) {

        }
        @media only screen and (min-width: 1000px) {

        }
    }

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {
        display: flex;

        & > * {
            margin-top: 0;
            margin-bottom: 0;
            flex: 1 1 0%;
            width: auto;
        }
        & > *:not(:last-child) {
            margin-bottom: 0;
            margin-right: 20px;
        }
    }
    @media only screen and (min-width: 1000px) {

    }
}

.layout-lg {
    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {

    }
    @media only screen and (min-width: 1000px) {

    }
}
