@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 660px) {
}
@media only screen and (min-width: 770px) {
}
@media only screen and (min-width: 1000px) {
}

/* フォーム共通 */
/*----------------------------------------------------------------------------*/
.l-form {
    margin-top: 0;
    padding-top: 0;

    section:not(.m-notice) {
        padding: 0;

        section:last-child {
            margin-bottom: 0;
        }

        table:last-child {
            margin-bottom: 0;
        }
    }

    section:first-child {
        margin-top: 0;
    }

    section section {
        margin: 30px 0;
    }

    section .title {
        margin-bottom: 30px;
    }

    .text-note {
        margin-top: 5px;
    }

    table {
        th {
            white-space: pre-line;
        }
    }

    .section-footer {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        font-size: 0;

        br {
            display: none;
        }

        input[name="submitBack"] {
            margin-top: 10px;
            box-shadow: none;
            background-color: transparent;

            &:hover {
                background-color: $color-bg;
                color: $color-btn;
            }
        }
    }

    @media only screen and (min-width: 660px) {
        section {
            margin: 80px 0;

            .title {
                margin-bottom: 40px;
            }

            section {
                margin: 80px 0;
            }
        }

        .text-note {
            margin-top: 10px;
        }


        .section-footer {
            flex-direction: row;
            justify-content: space-between;

            // inputが1つのとき
            input,
            .btn {
                margin-right: auto;
                margin-left: auto;
            }

            // inputが2つのとき
            input[name="submitBack"] {
                margin: 0;
                box-shadow: 0 0 0 1px $color-btn inset;
                background-color: transparent;
                color: $color-btn;

                &:hover {
                    background-color: $color-btn;
                    color: $color-white;
                }
            }

            input ~ input {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }
}

.field-note {
    margin-bottom: 5px;
    padding: 0 20px;
    color: #404040;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;

    @media only screen and (min-width: 660px) {
        margin-bottom: 18px;
        padding: 0;
    }
}

fieldset, .fieldset {
    margin: 2em 0;

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

    .title {
        margin-bottom: 0.5em;
        font-size: 0.8em;
    }

    & > .field {
        margin-top: 0;
        margin-bottom: 0;
    }

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
        margin: 3em 0;

        &:not(.fieldset-block) {
            display: flex;
            align-items: baseline;

            & > .title {
                width: 200px;
                margin-right: 1em;
                margin-bottom: 0;
            }
            & > .field, & > .field-group {
                flex: 1 1 0%;
            }
        }
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
    }
}

.field {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #404040;

    * {
        // IE 11 バグ
        // flex: 1 1 0%;
        flex: 1 1 auto;
    }

    & > span, & > label, & > .unit, & > .btn {
        // IE バグ
        // flex: initial;
        flex: 0 0 auto;
    }


    & > .unit {
        margin: 0;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    .field {
        margin-top: 0;
        margin-bottom: 0;
    }

    select, textarea {
        margin: 0;
    }

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
    }

     + .field {
        margin-top: 20px;
    }

    label {
        cursor: pointer;
    }
}

input, select, option, textarea {
    // firefox対策、.field内のUIの最小値を修正
    min-width: 1em;
    font-size: 1rem;

    &:focus, &.focus {
        outline: none;
    }
}

/* フォーム全般 */
input[type="text"], input[type="tel"], input[type="email"], input[type="url"], input[type="password"], input[type="number"], input[type="file"], input[type="search"], input[type="date"], select, option, textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: 38px;
    min-height: 38px;
    padding: 8px 20px;
    border-width: 1px;
    border-style: solid;
    border-color: $color-border-light;
    border-radius: 0;
    background-color: $color-field;
    font-family: $font-gothic;

    &.is-valid {
        border-color: $color-success;
    }
    &.is-invalid {
        border-color: $color-danger;
    }


    @media only screen and (max-width: 340px) {
        padding: 8px 15px;
    }

    @media only screen and (min-width: 660px) {
        height: 42px;
        min-height: 42px;
        padding: 8px 20px;
    }
}

input[type="tel"], input[name="postcode"], select {
    max-width: 195px;
}

/* 影、disabled */
input[type="text"], input[type="tel"], input[type="email"], input[type="url"], input[type="password"], input[type="number"], input[type="file"], input[type="search"], input[type="date"], textarea {
    &:focus {
        &:not(:disabled):not([readonly]) {
            border-color: $color-link;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1) inset;
            background-color: $color-white;
        }
    }
}
input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not([type="submit"]), textarea {
    // selectに「:read-only」が使用できない
    &:read-only, &:disabled, &[readonly] {
        box-shadow: none;
        background-color: $color-bg-base;
        color: rgba(0, 0, 0, 0.5);
    }
}
.field-disabled {
    input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not([type="submit"]), textarea {
        box-shadow: none;
        background-color: $color-bg-base;
        color: rgba(0, 0, 0, 0.5);
    }
}




textarea {
    display: block;
    width: 100%;
    resize: vertical !important;
}
/* input[type="number"] {
    flex: 0 0 6em;
    width: 6em;
    IE対策
    min-width: 6em;
} */
input[type="file"] {
    cursor: pointer;
}
input[type="radio"], input[type="checkbox"] {
    margin-right: 1rem;
    cursor: pointer;

    &.before-icon {
        width: 1rem;
        height: 1rem;
        margin: 0;
        opacity: 0;
        font-size: 1rem;

        & + .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            /* box-sizing: border-box; */
            // IE バグ border-boxを取ったため、widthとheightを少し小さくした
            width: 17px; // 19px
            height: 17px; // 19px
            margin: 0 10px 0 -1rem;
            padding: 0;
            border: 1px solid #868686;
            border-radius: 1px;
            background-color: $color-white;
            color: #777777;
            font-size: 19px;
            line-height: 1;
            vertical-align: inherit;
        }
        & + .icon::before {
            display: block;
            margin: 0;
            background-color: $color-white;
            animation: input-icon 80ms $easingStandard forwards;
        }

        // Firefoxでは「:read-only」はdisabled以外のときも適用されてしまう
        &:disabled, &[readonly] {
            &:checked {
                & + .icon::before, & ~ * {
                    color: $color-bg-base;
                    cursor: default;
                }
            }
        }
    }

    &:checked + .icon {
        color: $color-link;
    }
}


input[type="radio"].before-icon {

     + .icon {
        border-radius: 100%;
    }

    &:checked + .icon {

        &::before {
            @mixin icon-radio-checked;
        }
    }
}
input[type="checkbox"].before-icon {

    &:checked + .icon {
        &::before {
            @mixin icon-checked;
        }
    }
}

select {
    position: relative;
    padding-right: 24px;
    background-image: url(../img/common/icon-select-margin.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
    background-size: 9.4px 6.1px;
    line-height: 1;
    vertical-align: middle;
    cursor: pointer;

    appearance: none;

    // selectの場合「:read-only」はdisabled以外のときも適用されてしまう
    &:disabled, &[readonly] {
        box-shadow: none;
        background-color: $color-bg-base;
        color: rgba(0, 0, 0, 0.5);
    }

    &::-ms-expand {
        display: none;
    }

    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $color-base;
    }
}

select[size]:not([size="1"]), select[multiple] {
    overflow: auto;
    padding: 0;
    background-image: none;

    option {
        border: none;
    }
    optgroup {
        padding: 0.5em 0;
        color: #cccccc;
        font-weight: normal;
        font-size: 0.85em;
        text-indent: 5px;

        & > option {
            padding-right: 0;
            padding-left: 0;
            color: $color-text;
            font-size: 1rem;
            text-indent: 0;
        }
        & > option::before {
            display: inline-block;
            width: 1em;
            content: "";
        }

        & > option:first-child {
            margin-top: 5px;

            border-top: 1px dotted #cccccc;
        }
    }
    optgroup:not(:first-child) {
        border-top: 1px dotted #cccccc;
    }
    optgroup:last-child {
        padding-bottom: 0;
    }
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    border-color: #cbcbcb;
    box-shadow: 0 0 0 1000px $color-bg inset!important;
    background-color: $color-bg !important;
}
input:-webkit-autofill:focus, textarea:-webkit-autofill:focus, select:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px $color-bg inset !important;
}


.field-block, .field-checkbox {
     > span,  > label {
        width: 100%;
    }

    @media only screen and (max-width: 479px) {
         > span,  > label {
            display: flex;
            flex-direction: column;
            align-items: baseline !important;
            justify-content: center;

            em + span {
                margin: 10px 0 0 0;
                font-size: 11px;
            }
        }
    }

    @media only screen and (max-width: 479px) {
        label {

            em + span {
                margin: 5px 0 0 0;
                font-size: 10px;
            }
        }
    }
}


.field-radio, .field-checkbox {
    flex-wrap: wrap;
    padding: 0 0 0 10px;

    * {
        // IE 11 バグ
        // flex: unset;
        flex: 0 0 auto;
    }

     > span,  > label,  > label:not(:first-child),  > label:not(:last-child) {
        margin: 0;
    }
     > span,  > label {
        display: flex;
        // IEでラジオボタンが横方向に潰れる
        flex: 0 0 auto;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        min-width: 84px;
        max-width: calc(100% - 0.5em);
        font-weight: normal;

        label {
            padding: 10px 0 10px 40px;
            cursor: pointer;
        }

        .before-icon {
            position: absolute;
        }

        .icon {
            position: absolute;
            top: 8px;
            left: 24px;
        }

        em + span {
            margin-left: 0.25em;
        }
    }
    input ~ input, input ~ textarea {
        max-width: calc(100% - 47px);
        margin-top: 5px;
        margin-bottom: 0.5em;
        margin-left: 40px;
        padding: 8px 15px;

        @media only screen and (max-width: 999px) {
            min-height: 68px;
        }

        @media only screen and (max-width: 659px) {
            min-height: 54px;
        }
        @media only screen and (max-width: 340px) {
            padding: 8px 10px;
        }
    }
    label.disabled {
        &, & *, input[type="radio"], input[type="checkbox"] {
            color: #dddddd;
            cursor: default;
        }
    }

    &.field-flex > label, &.field-flex > span {
        min-width: calc( 100% / 2 );
    }

    @media only screen and (min-width: 480px) {
        & > span label {

            .icon {
                top: 12px;
            }
        }
    }
    @media only screen and (min-width: 660px) {
        margin: -0.5em;
        padding: 0;

        & > span label {
            padding: 7px 0 7px 36px;
            line-height: 1;

            .icon {
                top: 6px;
            }
        }

        &.field-flex > label, &.field-flex > span {
            min-width: calc( 90% / 3 );
        }
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
    }
}

.field.field-textarea-lg {
    textarea {
        height: 93px;
    }
}


.field.field-agree {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;

    & > span label * {
        flex: 0 0 auto;
    }

    & > span label {
        display: flex;
        align-items: center;
        margin-right: 0.5em;

        input[type="checkbox"].before-icon {
            position: absolute;
        }
        input[type="checkbox"].before-icon + .icon {
            margin-right: 0.5em;
        }
    }

    & > span {
        margin-left: 0;
    }

    a[target="_blank"]::after {
        content: none;
    }
}




.field-group {
    &.field-radio {
        & > span, & > label {
            min-width: 84px;
            margin-right: 20px;
        }
    }
}



/* バリデーションエラー */
/*----------------------------------------------------------------------------*/

.field + .invalid-feedback, .field + .valid-feedback {
    margin-top: 10px;
}

// .m-text-errorと.errorは01-text.cssに移動

table .m-text-error, .mw_wp_form form .error {
    padding: 10px 0 0 0;
    background-color: transparent;
    font-weight: bold;
}

.m-text-error:empty {
    display: none;
}

.mw_wp_form form {


    .field-radio, .field-checkbox {
        .error {
            padding: 10px 0 0 9px;
        }
    }

    .field-agree {
        .error {
            text-align: center;
        }
    }
}



/* プレースホルダ */
/*----------------------------------------------------------------------------*/
::placeholder {
    color: #848484;
    font-size: 12px;

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
        font-size: 16px;
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
    }
}

::-webkit-input-placeholder {
    color: rgba(50, 50, 50, 0.6);
}
::-moz-placeholder {
    opacity: 1;
    color: rgba(50, 50, 50, 0.6);
}
:-ms-input-placeholder {
    color: rgba(50, 50, 50, 0.6);
}
:input-placeholder {
    color: rgba(50, 50, 50, 0.6);
}



.invisible {
    display: none !important;
}
