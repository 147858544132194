/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    background-repeat: no-repeat;
    border-radius: 0;
}
/*
body {
    color: #333333;
    letter-spacing: 0.075em;
}
*/
main {
    display: block;
}
/*
input,
textarea,
select:not([size]),
option {
    padding: 0.75em 1.0em;
}
td,
th {
    padding: 0.25em 1.0em;
}
ul,
ol {
    padding-left: 1.5em;
    list-style-position: outside;
}
ul li { list-style-type: disc; }
ol li { list-style-type: decimal }
input,
textarea,
select,
td,
th {
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
}
select,
option,
textarea,
input,
button {
    font-size: 100%;
    background-repeat: no-repeat;
    border-radius: 0;
}
select,
option,
input,
button {
    line-height: 1;
}
*/
select,
option,
textarea,
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="url"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="file"],
input[type="date"],
input[type="submit"],
input[type="button"],
input[type="reset"],
button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}
/*
a {
    text-decoration: none;
}
td,
optgroup,
address {
    font-weight: normal;
}
a,
select,
input[type="checkbox"],
input[type="radio"],
input[type="submit"],
input[type="button"],
input[type="reset"],
button {
    cursor: pointer;
}
textarea {
    vertical-align: top;
    overflow: auto;
    resize: vertical;
}
*/
input::-moz-focus-inner,
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}
input[type="search"] {
    box-sizing: content-box;
}
input[type="search"]:focus {
    outline-offset: -2px;
}
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}

/* 配色 */


/* テンプレート設定 */ /* #98A0B5 */ /* #cbcbcb */

/* モジュール */


/* 問い合わせエリアカラー */


/* 基本ボタン、リンクカラー */

/* [求人情報] 基本カラー */

/* [求人情報] サブカラー */

/* [求職情報] 基本カラー */

/* [求職情報] サブカラー */


/* px数 (変数化しすぎると逆に大変なので極力増やさない) */
/*----------------------------------------------------------------------------*/

/* 行間 */
/*----------------------------------------------------------------------------*/

/* フォント */
/*----------------------------------------------------------------------------*/

/* 「03-mixin.css」を変更したい場合は、「03-mixin-pre-consolidate.css」を変更 */
/*----------------------------------------------------------------------------*/

@font-face {
    font-family: "original-icon-font";
    src: url('../font/original-icon-font.eot');
    src: url('../font/original-icon-font.eot?#iefix') format('eot'),
        url('../font/original-icon-font.woff2') format('woff2'),
        url('../font/original-icon-font.woff') format('woff'),
        url('../font/original-icon-font.ttf') format('truetype'),
        url('../font/original-icon-font.svg#original-icon-font') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.icon::before {
	display: inline-block;
	font-family: "original-icon-font";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	vertical-align: baseline;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transform: scale(1);
	transform: scale(1);
	letter-spacing: 0;
}

/* ズレ補正 */
.icon {
    /*
    vertical-align: middle;
    font-size: 110%;
    letter-spacing: 0;
    text-indent: 0;
    */
}
.icon::before {
    /*
    margin: -0.25rem 0 0 0;
    display: inline-block;
    */
}
.icon-arrow-simple::before { content: "\EA01"; }
.icon-building::before { content: "\EA02"; }
.icon-checked::before { content: "\EA03"; }
.icon-close::before { content: "\EA04"; }
.icon-email::before { content: "\EA05"; }
.icon-home::before { content: "\EA06"; }
.icon-outlink::before { content: "\EA07"; }
.icon-radio-checked::before { content: "\EA08"; }
.icon-search::before { content: "\EA09"; }
.icon-tel::before { content: "\EA0A"; }
.icon-time::before { content: "\EA0B"; }
.icon-user::before { content: "\EA0C"; }







/*------------------------------------------------------------------------------ */
/* アニメーション */
/* https://material.io/design/motion/speed.html#duration */
/*------------------------------------------------------------------------------ */

/* 通常 */
/* 上に登る */
/* 下に落ちる */


/* シンプルなアニメーション */
/*
@mixin mix-transition;
*/

/* ボタンのアニメーション */
/*
@mixin mix-transition-btn;
*/
@charset "UTF-8";

/* ページャー */
/*---------------------------------------------------*/
/*
.pager li {
    padding: 0 0.5em;
}
.pager a,
.pager a:hover,
.pager span {
    width: 40px;
    height: 40px;
    text-decoration: none;
    color: #111111;
}
.pager .current {
    background: #dddddd;
}
.pager a:hover {
    background: #111111;
    color: #ffffff;
}
*/



/* toggle / tab / popup */
/*---------------------------------------------------*/
/*
.toggle-button,
.parent-tab-button a,
.popup-button a {
    // padding: 15px 20px;
    // border: 1px solid #333333;
    font-weight: normal;
}
.toggle-button,
.popup-button a {
    background-color: transparent;
    color: #333333;
}
.toggle-button.active,
.toggle-button:hover,
.parent-tab-button li a,
.popup-button a:hover {
    // background-color: #333333;
    // color: #ffffff;
    color: #333333;
}
.parent-tab-button li a:hover {
    opacity: 0.7;
}
.toggle-button.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.toggle-content,
.parent-tab-content {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
.parent-tab-button li a {
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
}
.parent-tab-button li:first-child a {
    border-top-left-radius: 3px;
    border-left-width: 1px;
}
.parent-tab-button li:last-child a {
    border-top-right-radius: 3px;
    border-right-width: 1px;
}
.parent-tab-content {
    border: 1px solid #333333;
    border-top: none;
}
.parent-tab-button li.current a {
    color: #333333;
    background-color: transparent;
}
.parent-popup-content .container {
    // width: 770px;
}
.popup-content > .panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
}
.popup-content {
    opacity: 0.0;
}
.popup-content.active {
    opacity: 1.0;
}
.popup-content {
    transition: opacity 0.25s ease-in-out 0.4s;
}
.popup-button-close {
    width: 30px;
    height: 30px;
    background: url(../img/icon-close-big.png) no-repeat center center;
    background-size: 30px 30px;
    top: 10px;
    right: 10px;
}
.popup-button-close:hover {
    opacity: 0.35;
}
*/




/* thumbnailボタン */
/*---------------------------------------------------*/
a .thumbnail img {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
}
a:hover .thumbnail img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
/*
@media only screen and (min-width: 480px) {
    // input[type="submit"],
    // input[type="button"],
    // input[type="reset"],
    // button,
    // .button a {
    //     width: auto
    // }
}

@media only screen and (min-width: 770px) {
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="number"],
    input[type="file"],
    input[type="search"],
    select,
    body select[size="1"],
    select[multiple],
    option,
    textarea {
        padding: 20px;
        // border: 2px solid #eeeeee;
    }

    select[size],
    select[multiple],
    select:not([size]) {
        padding: 20px;
    }

    // input[type="button"],
    // input[type="reset"],
    // input[type="submit"],
    // button,
    // .button > a,
    // .button > span:not(.icon),
    // a.button {
    //     padding: 0.5em 1em;
    //     font-size: 16px;
    // }
}
*/


/* pagerボタン */
/*---------------------------------------------------*/
.pager ul,
.pager ol,
ul.pager ,
ol.pager {
    margin: 0 auto;
    padding: 0;
    display: table;
    border-collapse: separate;
    border-spacing: 1px 0;
}
.pager li,
.pager li {
    display: table-cell;
}
.pager li a,
.pager li span {
/*
    display: table-cell;
    text-align: center;
    vertical-align: middle;
*/
}
.pager li a,
.pager li span {
    min-width: 2em;
    min-height: 2em;
    line-height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pager .first a,
.pager .prev a,
.pager .next a,
.pager .last a {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px 15px;
}

/* WordPressの管理者用ヘッダー */
body.admin-bar #wpadminbar {
        position: fixed;
        top: 0;
        background: #161629;
    }
body.admin-bar header {
        top: 46px;
    }
body.admin-bar header .global-nav nav {
            top: calc(46px + 50px);
        }
body.admin-bar .alert-group {
        top: calc(46px + 50px + 5px);
    }

@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 783px) {
        body.admin-bar header {
            top: 32px;
        }

            body.admin-bar header .global-nav nav {
                top: calc(32px + 50px);
            }
        body.admin-bar .alert-group {
            top: calc(32px + 50px + 5px);
        }

}
@media only screen and (min-width: 1000px) {
        body.customize-support header header .global-nav nav, body.admin-bar header .global-nav nav {
            top: auto;
        }
        body.customize-support header .alert-group, body.admin-bar .alert-group {
            top: calc(32px + 90px + 5px);
        }
}


/* WordPress管理画面対応 */
/*
html.global-nav-active {
    body.customize-support .toggle-content {
        max-height: calc(100vh - 46px - 70px);

        @media only screen and (min-width: 783px) {
            max-height: calc(100vh - 32px - 70px);
        }
    }
}
*/

/* WordPress */
.mw_wp_form form .error {
        padding: 5px 10px;
        background-color: #ee9999;
        color: #ffffff;
    }
.mw_wp_form .horizontal-item + .horizontal-item {
        margin-left: 0;
    }

/* .quicklinks */
#wp-admin-bar-customize {
    display: none!important;
}
/** VARIABLES
===================================*/
/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
  position: relative;
  margin-bottom: 60px;
  padding: 0;
  *zoom: 1;
  touch-action: pan-y;
}
.bx-wrapper img {
  max-width: 100%;
  display: block;
}
.bxslider {
  margin: 0;
  padding: 0;
}
ul.bxslider {
  list-style: none;
}
.bx-viewport {
  /* fix other elements on the page moving (in Chrome) */
  -webkit-transform: translateZ(0);
}
/** THEME
===================================*/
.bx-wrapper {
  box-shadow: 0 0 5px #ccc;
  border: 5px solid #fff;
  background: #fff;
}
.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: -30px;
  width: 100%;
}
/* LOADER */
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url('images/bx_loader.gif') center center no-repeat #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}
/* PAGER */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px;
}
.bx-wrapper .bx-pager.bx-default-pager a {
  background: #666;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  border-radius: 5px;
}
.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus {
  background: #000;
}
.bx-wrapper .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  vertical-align: bottom;
  *zoom: 1;
  *display: inline;
}
.bx-wrapper .bx-pager-item {
  font-size: 0;
  line-height: 0;
}
/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper .bx-prev {
  left: 10px;
  background: url('images/controls.png') no-repeat 0 -32px;
}
.bx-wrapper .bx-prev:hover,
.bx-wrapper .bx-prev:focus {
  background-position: 0 0;
}
.bx-wrapper .bx-next {
  right: 10px;
  background: url('images/controls.png') no-repeat -43px -32px;
}
.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-next:focus {
  background-position: -43px 0;
}
.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999;
}
.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}
/* AUTO CONTROLS (START / STOP) */
.bx-wrapper .bx-controls-auto {
  text-align: center;
}
.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -11px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active,
.bx-wrapper .bx-controls-auto .bx-start:focus {
  background-position: -86px 0;
}
.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -44px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active,
.bx-wrapper .bx-controls-auto .bx-stop:focus {
  background-position: -86px -33px;
}
/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%;
}
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px;
}
/* IMAGE CAPTIONS */
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
}
.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: .85em;
  padding: 10px;
}


.xdsoft_datetimepicker {
	box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.506);
	background: #fff;
	border-bottom: 1px solid #bbb;
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-top: 1px solid #ccc;
	color: #333;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	padding: 8px;
	padding-left: 0;
	padding-top: 2px;
	position: absolute;
	z-index: 9999;
	box-sizing: border-box;
	display: none;
}
.xdsoft_datetimepicker.xdsoft_rtl {
	padding: 8px 0 8px 8px;
}

.xdsoft_datetimepicker iframe {
	position: absolute;
	left: 0;
	top: 0;
	width: 75px;
	height: 210px;
	background: transparent;
	border: none;
}

/*For IE8 or lower*/
.xdsoft_datetimepicker button {
	border: none !important;
}

.xdsoft_noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.xdsoft_noselect::-moz-selection { background: transparent }

.xdsoft_noselect::selection { background: transparent }
.xdsoft_noselect::-moz-selection { background: transparent }

.xdsoft_datetimepicker.xdsoft_inline {
	display: inline-block;
	position: static;
	box-shadow: none;
}

.xdsoft_datetimepicker * {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.xdsoft_datetimepicker .xdsoft_datepicker, .xdsoft_datetimepicker .xdsoft_timepicker {
	display: none;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active, .xdsoft_datetimepicker .xdsoft_timepicker.active {
	display: block;
}

.xdsoft_datetimepicker .xdsoft_datepicker {
	width: 224px;
	float: left;
	margin-left: 8px;
}
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_datepicker {
	float: right;
	margin-right: 8px;
	margin-left: 0;
}

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_datepicker {
	width: 256px;
}

.xdsoft_datetimepicker .xdsoft_timepicker {
	width: 58px;
	float: left;
	text-align: center;
	margin-left: 8px;
	margin-top: 0;
}
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker {
	float: right;
	margin-right: 8px;
	margin-left: 0;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active+.xdsoft_timepicker {
	margin-top: 8px;
	margin-bottom: 3px
}

.xdsoft_datetimepicker .xdsoft_monthpicker {
	position: relative;
	text-align: center;
}

.xdsoft_datetimepicker .xdsoft_label i,
.xdsoft_datetimepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_today_button {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0NBRjI1NjM0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0NBRjI1NjQ0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQ0FGMjU2MTQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQ0FGMjU2MjQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoNEP54AAAIOSURBVHja7Jq9TsMwEMcxrZD4WpBYeKUCe+kTMCACHZh4BFfHO/AAIHZGFhYkBBsSEqxsLCAgXKhbXYOTxh9pfJVP+qutnZ5s/5Lz2Y5I03QhWji2GIcgAokWgfCxNvcOCCGKqiSqhUp0laHOne05vdEyGMfkdxJDVjgwDlEQgYQBgx+ULJaWSXXS6r/ER5FBVR8VfGftTKcITNs+a1XpcFoExREIDF14AVIFxgQUS+h520cdud6wNkC0UBw6BCO/HoCYwBhD8QCkQ/x1mwDyD4plh4D6DDV0TAGyo4HcawLIBBSLDkHeH0Mg2yVP3l4TQMZQDDsEOl/MgHQqhMNuE0D+oBh0CIr8MAKyazBH9WyBuKxDWgbXfjNf32TZ1KWm/Ap1oSk/R53UtQ5xTh3LUlMmT8gt6g51Q9p+SobxgJQ/qmsfZhWywGFSl0yBjCLJCMgXail3b7+rumdVJ2YRss4cN+r6qAHDkPWjPjdJCF4n9RmAD/V9A/Wp4NQassDjwlB6XBiCxcJQWmZZb8THFilfy/lfrTvLghq2TqTHrRMTKNJ0sIhdo15RT+RpyWwFdY96UZ/LdQKBGjcXpcc1AlSFEfLmouD+1knuxBDUVrvOBmoOC/rEcN7OQxKVeJTCiAdUzUJhA2Oez9QTkp72OTVcxDcXY8iKNkxGAJXmJCOQwOa6dhyXsOa6XwEGAKdeb5ET3rQdAAAAAElFTkSuQmCC);
}

.xdsoft_datetimepicker .xdsoft_label i {
	opacity: 0.5;
	background-position: -92px -19px;
	display: inline-block;
	width: 9px;
	height: 20px;
	vertical-align: middle;
}

.xdsoft_datetimepicker .xdsoft_prev {
	float: left;
	background-position: -20px 0;
}
.xdsoft_datetimepicker .xdsoft_today_button {
	float: left;
	background-position: -70px 0;
	margin-left: 5px;
}

.xdsoft_datetimepicker .xdsoft_next {
	float: right;
	background-position: 0 0;
}

.xdsoft_datetimepicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_prev ,
.xdsoft_datetimepicker .xdsoft_today_button {
	background-color: transparent;
	background-repeat: no-repeat;
	border: 0 none;
	cursor: pointer;
	display: block;
	height: 30px;
	opacity: 0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	outline: medium none;
	overflow: hidden;
	padding: 0;
	position: relative;
	text-indent: 100%;
	white-space: nowrap;
	width: 20px;
	min-width: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next {
	float: none;
	background-position: -40px -15px;
	height: 15px;
	width: 30px;
	display: block;
	margin-left: 14px;
	margin-top: 7px;
}
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker .xdsoft_prev,
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker .xdsoft_next {
	float: none;
	margin-left: 0;
	margin-right: 14px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev {
	background-position: -40px 0;
	margin-bottom: 7px;
	margin-top: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box {
	height: 151px;
	overflow: hidden;
	border-bottom: 1px solid #ddd;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div {
	background: #f5f5f5;
	border-top: 1px solid #ddd;
	color: #666;
	font-size: 12px;
	text-align: center;
	border-collapse: collapse;
	cursor: pointer;
	border-bottom-width: 0;
	height: 25px;
	line-height: 25px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div > div:first-child {
	border-top-width: 0;
}

.xdsoft_datetimepicker .xdsoft_today_button:hover,
.xdsoft_datetimepicker .xdsoft_next:hover,
.xdsoft_datetimepicker .xdsoft_prev:hover {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.xdsoft_datetimepicker .xdsoft_label {
	display: inline;
	position: relative;
	z-index: 9999;
	margin: 0;
	padding: 5px 3px;
	font-size: 14px;
	line-height: 20px;
	font-weight: bold;
	background-color: #fff;
	float: left;
	width: 182px;
	text-align: center;
	cursor: pointer;
}

.xdsoft_datetimepicker .xdsoft_label:hover>span {
	text-decoration: underline;
}

.xdsoft_datetimepicker .xdsoft_label:hover i {
	opacity: 1.0;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select {
	border: 1px solid #ccc;
	position: absolute;
	right: 0;
	top: 30px;
	z-index: 101;
	display: none;
	background: #fff;
	max-height: 160px;
	overflow-y: hidden;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_monthselect{ right: -7px }
.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_yearselect{ right: 2px }
.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
	color: #fff;
	background: #ff8000;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option {
	padding: 2px 10px 2px 5px;
	text-decoration: none !important;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
	background: #33aaff;
	box-shadow: #178fe5 0 1px 3px 0 inset;
	color: #fff;
	font-weight: 700;
}

.xdsoft_datetimepicker .xdsoft_month {
	width: 100px;
	text-align: right;
}

.xdsoft_datetimepicker .xdsoft_calendar {
	clear: both;
}

.xdsoft_datetimepicker .xdsoft_year{
	width: 48px;
	margin-left: 5px;
}

.xdsoft_datetimepicker .xdsoft_calendar table {
	border-collapse: collapse;
	width: 100%;

}

.xdsoft_datetimepicker .xdsoft_calendar td > div {
	padding-right: 5px;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	height: 25px;
}

.xdsoft_datetimepicker .xdsoft_calendar td,.xdsoft_datetimepicker .xdsoft_calendar th {
	width: 14.2857142%;
	background: #f5f5f5;
	border: 1px solid #ddd;
	color: #666;
	font-size: 12px;
	text-align: right;
	vertical-align: middle;
	padding: 0;
	border-collapse: collapse;
	cursor: pointer;
	height: 25px;
}
.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar td,.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar th {
	width: 12.5%;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	background: #f1f1f1;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
	color: #33aaff;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_highlighted_default {
	background: #ffe9d2;
	box-shadow: #ffb871 0 1px 4px 0 inset;
	color: #000;
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_highlighted_mint {
	background: #c1ffc9;
	box-shadow: #00dd1c 0 1px 4px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_current {
	background: #33aaff;
	box-shadow: #178fe5 0 1px 3px 0 inset;
	color: #fff;
	font-weight: 700;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled,
.xdsoft_datetimepicker .xdsoft_time_box >div >div.xdsoft_disabled {
	opacity: 0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	cursor: default;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month.xdsoft_disabled {
	opacity: 0.2;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div:hover {
	color: #fff !important;
	background: #ff8000 !important;
	box-shadow: none !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current.xdsoft_disabled:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div.xdsoft_current.xdsoft_disabled:hover {
	background: #33aaff !important;
	box-shadow: #178fe5 0 1px 3px 0 inset !important;
	color: #fff !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_disabled:hover {
	color: inherit	!important;
	background: inherit !important;
	box-shadow: inherit !important;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	font-weight: 700;
	text-align: center;
	color: #999;
	cursor: default;
}

.xdsoft_datetimepicker .xdsoft_copyright {
	color: #ccc !important;
	font-size: 10px;
	clear: both;
	float: none;
	margin-left: 8px;
}

.xdsoft_datetimepicker .xdsoft_copyright a { color: #eee !important }
.xdsoft_datetimepicker .xdsoft_copyright a:hover { color: #aaa !important }

.xdsoft_time_box {
	position: relative;
	border: 1px solid #ccc;
}
.xdsoft_scrollbar >.xdsoft_scroller {
	background: #ccc !important;
	height: 20px;
	border-radius: 3px;
}
.xdsoft_scrollbar {
	position: absolute;
	width: 7px;
	right: 0;
	top: 0;
	bottom: 0;
	cursor: pointer;
}
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_scrollbar {
	left: 0;
	right: auto;
}
.xdsoft_scroller_box {
	position: relative;
}

.xdsoft_datetimepicker.xdsoft_dark {
	box-shadow: 0 5px 15px -5px rgba(255, 255, 255, 0.506);
	background: #000;
	border-bottom: 1px solid #444;
	border-left: 1px solid #333;
	border-right: 1px solid #333;
	border-top: 1px solid #333;
	color: #ccc;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box {
	border-bottom: 1px solid #222;
}
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box >div >div {
	background: #0a0a0a;
	border-top: 1px solid #222;
	color: #999;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label {
	background-color: #000;
}
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select {
	border: 1px solid #333;
	background: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
	color: #000;
	background: #007fff;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
	background: #cc5500;
	box-shadow: #b03e00 0 1px 3px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label i,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_prev,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_next,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_today_button {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QUExQUUzOTA0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QUExQUUzOTE0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBQTFBRTM4RTQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBQTFBRTM4RjQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pp0VxGEAAAIASURBVHja7JrNSgMxEMebtgh+3MSLr1T1Xn2CHoSKB08+QmR8Bx9A8e7RixdB9CKCoNdexIugxFlJa7rNZneTbLIpM/CnNLsdMvNjM8l0mRCiQ9Ye61IKCAgZAUnH+mU3MMZaHYChBnJUDzWOFZdVfc5+ZFLbrWDeXPwbxIqrLLfaeS0hEBVGIRQCEiZoHQwtlGSByCCdYBl8g8egTTAWoKQMRBRBcZxYlhzhKegqMOageErsCHVkk3hXIFooDgHB1KkHIHVgzKB4ADJQ/A1jAFmAYhkQqA5TOBtocrKrgXwQA8gcFIuAIO8sQSA7hidvPwaQGZSaAYHOUWJABhWWw2EMIH9QagQERU4SArJXo0ZZL18uvaxejXt/Em8xjVBXmvFr1KVm/AJ10tRe2XnraNqaJvKE3KHuUbfK1E+VHB0q40/y3sdQSxY4FHWeKJCunP8UyDdqJZenT3ntVV5jIYCAh20vT7ioP8tpf6E2lfEMwERe+whV1MHjwZB7PBiCxcGQWwKZKD62lfGNnP/1poFAA60T7rF1UgcKd2id3KDeUS+oLWV8DfWAepOfq00CgQabi9zjcgJVYVD7PVzQUAUGAQkbNJTBICDhgwYTjDYD6XeW08ZKh+A4pYkzenOxXUbvZcWz7E8ykRMnIHGX1XPl+1m2vPYpL+2qdb8CDAARlKFEz/ZVkAAAAABJRU5ErkJggg==);
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	background: #0a0a0a;
	border: 1px solid #222;
	color: #999;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	background: #0e0e0e;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_today {
	color: #cc5500;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_highlighted_default {
	background: #ffe9d2;
	box-shadow: #ffb871 0 1px 4px 0 inset;
	color:#000;
}
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_highlighted_mint {
	background: #c1ffc9;
	box-shadow: #00dd1c 0 1px 4px 0 inset;
	color:#000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_current {
	background: #cc5500;
	box-shadow: #b03e00 0 1px 3px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td:hover,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box >div >div:hover {
	color: #000 !important;
	background: #007fff !important;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	color: #666;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright { color: #333 !important }
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a { color: #111 !important }
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a:hover { color: #555 !important }

.xdsoft_dark .xdsoft_time_box {
	border: 1px solid #333;
}

.xdsoft_dark .xdsoft_scrollbar >.xdsoft_scroller {
	background: #333 !important;
}
.xdsoft_datetimepicker .xdsoft_save_selected {
    display: block;
    border: 1px solid #dddddd !important;
    margin-top: 5px;
    width: 100%;
    color: #454551;
    font-size: 13px;
}
.xdsoft_datetimepicker .blue-gradient-button {
	font-family: "museo-sans", "Book Antiqua", sans-serif;
	font-size: 12px;
	font-weight: 300;
	color: #82878c;
	height: 28px;
	position: relative;
	padding: 4px 17px 4px 33px;
	border: 1px solid #d7d8da;
	/* FF3.6+ */
	/* Chrome,Safari4+ */
	/* Chrome10+,Safari5.1+ */
	/* Opera 11.10+ */
	/* IE10+ */
	background: linear-gradient(to bottom, #fff 0%, #f4f8fa 73%);
	/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#f4f8fa',GradientType=0 );
/* IE6-9 */
}
.xdsoft_datetimepicker .blue-gradient-button:hover, .xdsoft_datetimepicker .blue-gradient-button:focus, .xdsoft_datetimepicker .blue-gradient-button:hover span, .xdsoft_datetimepicker .blue-gradient-button:focus span {
  color: #454551;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #f4f8fa 0%, #FFF 73%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f8fa', endColorstr='#FFF',GradientType=0 );
  /* IE6-9 */
}

/* UIのカスタマイズ */
/*----------------------------------------------------------------------------------*/



/*
body .xdsoft_datetimepicker .xdsoft_next {
    background: none;
    display: block;

    &::before {
        content: "\e200";
        width: 100px;
        height: 100px;
        color: $color-text;
        line-height: 1;
        font-size: 1rem;
        font-family: 'icon','icon-sns' !important;
        transform: translate(0, 0.05em) rotate(90deg);
    }
}

body .xdsoft_datetimepicker .xdsoft_prev {
    background: none;
    display: block;

    &::before {
        content: "\e200";
        width: 100px;
        height: 100px;
        color: $color-text;
        line-height: 1;
        font-size: 1rem;
        font-family: 'icon','icon-sns' !important;
        transform: translate(0, 0.05em) rotate(-90deg);
    }
}
*/
body .xdsoft_datetimepicker .xdsoft_today_button {
    display: none;
}

.datetimepicker-date {
    padding-right: 32px;
    background-image: url(../img/common/icon-calender-margin.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 32px 16px;
    cursor: pointer;
}
body .xdsoft_calendar table {
            display: table;
        }
body .xdsoft_calendar table thead {
                display: table-header-group;
            }
body .xdsoft_calendar table tbody {
                display: table-row-group;
            }
body .xdsoft_calendar table tfoot {
                display: table-footer-group;
            }
body .xdsoft_calendar table tr {
                display: table-row;
            }
body .xdsoft_calendar table th,
            body .xdsoft_calendar table td {
                display: table-cell;
            }
/*
    .xdsoft_datetimepicker,
    .xdsoft_noselect {
        display: block !important;
    }
    */
body .xdsoft_datetimepicker {
        padding: 10px;
    }
body .xdsoft_datetimepicker .xdsoft_datepicker {
    	width: 260px;
    	float: none;
    	/*\/ margin-left: 8px; */
        margin: 0;
    }
body .xdsoft_datetimepicker.xdsoft_rtl .xdsoft_datepicker {
    	float: none;
    	/* margin-right: 8px; */
    	/* margin-left: 0; */
        margin: 0;
    }
body .xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_datepicker {
    	width: 256px;
    }
body .xdsoft_datetimepicker {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }
body .xdsoft_datetimepicker .xdsoft_year {
        width: 60px;
    }
/* マス */
body .xdsoft_datetimepicker .xdsoft_calendar table,
    body .xdsoft_datetimepicker .xdsoft_calendar thead,
    body .xdsoft_datetimepicker .xdsoft_calendar tbody,
    body .xdsoft_datetimepicker .xdsoft_calendar tfoot {
        border-left: none;
        border-right: none;
    }
/*
    .xdsoft_datetimepicker .xdsoft_label i,
    .xdsoft_datetimepicker .xdsoft_next,
    .xdsoft_datetimepicker .xdsoft_prev,
    .xdsoft_datetimepicker .xdsoft_today_button {
        background: none;

        &::before {
            content: url(../img/common/icon-arrow-simple.svg);
            display: block;
        }
    }
    */
body .xdsoft_datetimepicker .xdsoft_calendar td,
    body .xdsoft_datetimepicker .xdsoft_calendar th {
        color: #161629;
        border: 1px solid #8d95ad;
        border: 1px solid rgba(141, 149, 173, 0.9);
        border-left: none;
        border-right: none;
    }
/* 見出し */
body .xdsoft_datetimepicker .xdsoft_calendar th {
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        background: none;
        height: auto;
    }
body .xdsoft_datetimepicker .xdsoft_calendar td {
        height: 38px;
        text-align: center;
    }
body .xdsoft_datetimepicker .xdsoft_calendar td > div {
        padding: 0;
    }
/* 今日 */
body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
        background-color: #161629 !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 0.25;
    }
/* 選択中 */
body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
    body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
    body .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
        background-color: #213265 !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 1.0;
    }
/* ホバー */
body .xdsoft_datetimepicker .xdsoft_calendar td:hover,
    body .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
        background-color: #547FFF !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 1.0;
    }
/* 前月、翌月 */
body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled,
    body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month,
    body .xdsoft_datetimepicker .xdsoft_time_box > div > div.xdsoft_disabled {
        opacity: 0.25;
    }
/* select */
body .xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
        background-color: #213265 !important;
        color: #fff !important;
        box-shadow: none !important;
    }
body .xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
        background-color: #547FFF !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 1.0;
    }
/*
select,
body select[size="1"] {

    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 1;

    position: relative;
}
*/

/* 便利なCSSを記述 */
/*----------------------------------------------------------------------------*/
:first-child {
    margin-top: 0;
}
/* :last-child {
    margin-bottom: 0;
} */

/* clearfix、回り込み */
/*---------------------------------------------------*/
/*
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    zoom: 1;
}
.float-left { float: left; }
.float-right { float: right; }
.clear { clear: both; }
*/

/* ブロック */
/*---------------------------------------------------*/
.d-block { display: block; }
.d-inline { display: inline; }
.d-inline-block { display: inline-block; }
.d-table { display: table; }
.d-table-cell { display: table-cell; }
.d-flex { display: flex; }
.d-none { display: none; }

/* 余白 */
/*---------------------------------------------------*/
.mt-auto { margin-top: auto !important; }
.mt-0 { margin-top: 0px !important; }
.mt-5 { margin-top: 5px !important; }
.mt-10 { margin-top: 10px !important; }
.mt-15 { margin-top: 15px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-30 { margin-top: 30px !important; }
.mt-40 { margin-top: 40px !important; }
.mt-50 { margin-top: 50px !important; }
.mt-60 { margin-top: 60px !important; }
.mt-70 { margin-top: 70px !important; }
.mt-80 { margin-top: 80px !important; }
.mt-90 { margin-top: 90px !important; }
.mt-100 { margin-top: 100px !important; }
.mt-110 { margin-top: 110px !important; }
.mt-120 { margin-top: 120px !important; }
.mt-130 { margin-top: 130px !important; }
.mt-140 { margin-top: 140px !important; }
.mt-150 { margin-top: 150px !important; }
.mt-200 { margin-top: 200px !important; }
.mt-250 { margin-top: 250px !important; }

.mb-auto { margin-bottom: auto !important; }
.mb-0 { margin-bottom: 0px !important; }
.mb-5 { margin-bottom: 5px !important; }
.mb-10 { margin-bottom: 10px !important; }
.mb-15 { margin-bottom: 15px !important; }
.mb-20 { margin-bottom: 20px !important; }
.mb-30 { margin-bottom: 30px !important; }
.mb-40 { margin-bottom: 40px !important; }
.mb-50 { margin-bottom: 50px !important; }
.mb-60 { margin-bottom: 60px !important; }
.mb-70 { margin-bottom: 70px !important; }
.mb-80 { margin-bottom: 80px !important; }
.mb-90 { margin-bottom: 90px !important; }
.mb-100 { margin-bottom: 100px !important; }
.mb-110 { margin-bottom: 110px !important; }
.mb-120 { margin-bottom: 120px !important; }
.mb-130 { margin-bottom: 130px !important; }
.mb-140 { margin-bottom: 140px !important; }
.mb-150 { margin-bottom: 150px !important; }
.mb-200 { margin-bottom: 200px !important; }
.mb-250 { margin-bottom: 250px !important; }

.ml-auto { margin-left: auto !important; }
.ml-0 { margin-left: 0px !important; }
.ml-5 { margin-left: 5px !important; }
.ml-10 { margin-left: 10px !important; }
.ml-15 { margin-left: 15px !important; }
.ml-20 { margin-left: 20px !important; }
.ml-30 { margin-left: 30px !important; }
.ml-40 { margin-left: 40px !important; }
.ml-50 { margin-left: 50px !important; }
.ml-60 { margin-left: 60px !important; }
.ml-70 { margin-left: 70px !important; }
.ml-80 { margin-left: 80px !important; }
.ml-90 { margin-left: 90px !important; }
.ml-100 { margin-left: 100px !important; }
.ml-110 { margin-left: 110px !important; }
.ml-120 { margin-left: 120px !important; }
.ml-130 { margin-left: 130px !important; }
.ml-140 { margin-left: 140px !important; }
.ml-150 { margin-left: 150px !important; }
.ml-200 { margin-left: 200px !important; }
.ml-250 { margin-left: 250px !important; }

.mr-auto { margin-right: auto !important; }
.mr-0 { margin-right: 0px !important; }
.mr-5 { margin-right: 5px !important; }
.mr-10 { margin-right: 10px !important; }
.mr-15 { margin-right: 15px !important; }
.mr-20 { margin-right: 20px !important; }
.mr-30 { margin-right: 30px !important; }
.mr-40 { margin-right: 40px !important; }
.mr-50 { margin-right: 50px !important; }
.mr-60 { margin-right: 60px !important; }
.mr-70 { margin-right: 70px !important; }
.mr-80 { margin-right: 80px !important; }
.mr-90 { margin-right: 90px !important; }
.mr-100 { margin-right: 100px !important; }
.mr-110 { margin-right: 110px !important; }
.mr-120 { margin-right: 120px !important; }
.mr-130 { margin-right: 130px !important; }
.mr-140 { margin-right: 140px !important; }
.mr-150 { margin-right: 150px !important; }
.mr-200 { margin-right: 200px !important; }
.mr-250 { margin-right: 250px !important; }

.pt-0 { padding-top: 0px !important; }
.pt-5 { padding-top: 5px !important; }
.pt-10 { padding-top: 10px !important; }
.pt-15 { padding-top: 15px !important; }
.pt-20 { padding-top: 20px !important; }
.pt-30 { padding-top: 30px !important; }
.pt-40 { padding-top: 40px !important; }
.pt-50 { padding-top: 50px !important; }
.pt-60 { padding-top: 60px !important; }
.pt-70 { padding-top: 70px !important; }
.pt-80 { padding-top: 80px !important; }
.pt-90 { padding-top: 90px !important; }
.pt-100 { padding-top: 100px !important; }
.pt-110 { padding-top: 110px !important; }
.pt-120 { padding-top: 120px !important; }
.pt-130 { padding-top: 130px !important; }
.pt-140 { padding-top: 140px !important; }
.pt-150 { padding-top: 150px !important; }
.pt-200 { padding-top: 200px !important; }
.pt-250 { padding-top: 250px !important; }

.pb-0 { padding-bottom: 0px !important; }
.pb-5 { padding-bottom: 5px !important; }
.pb-10 { padding-bottom: 10px !important; }
.pb-15 { padding-bottom: 15px !important; }
.pb-20 { padding-bottom: 20px !important; }
.pb-30 { padding-bottom: 30px !important; }
.pb-40 { padding-bottom: 40px !important; }
.pb-50 { padding-bottom: 50px !important; }
.pb-60 { padding-bottom: 60px !important; }
.pb-70 { padding-bottom: 70px !important; }
.pb-80 { padding-bottom: 80px !important; }
.pb-90 { padding-bottom: 90px !important; }
.pb-100 { padding-bottom: 100px !important; }
.pb-110 { padding-bottom: 110px !important; }
.pb-120 { padding-bottom: 120px !important; }
.pb-130 { padding-bottom: 130px !important; }
.pb-140 { padding-bottom: 140px !important; }
.pb-150 { padding-bottom: 150px !important; }
.pb-200 { padding-bottom: 200px !important; }
.pb-250 { padding-bottom: 250px !important; }

.pl-0 { padding-left: 0px !important; }
.pl-5 { padding-left: 5px !important; }
.pl-10 { padding-left: 10px !important; }
.pl-15 { padding-left: 15px !important; }
.pl-20 { padding-left: 20px !important; }
.pl-30 { padding-left: 30px !important; }
.pl-40 { padding-left: 40px !important; }
.pl-50 { padding-left: 50px !important; }
.pl-60 { padding-left: 60px !important; }
.pl-70 { padding-left: 70px !important; }
.pl-80 { padding-left: 80px !important; }
.pl-90 { padding-left: 90px !important; }
.pl-100 { padding-left: 100px !important; }
.pl-110 { padding-left: 110px !important; }
.pl-120 { padding-left: 120px !important; }
.pl-130 { padding-left: 130px !important; }
.pl-140 { padding-left: 140px !important; }
.pl-150 { padding-left: 150px !important; }
.pl-200 { padding-left: 200px !important; }
.pl-250 { padding-left: 250px !important; }

.pr-0 { padding-right: 0px !important; }
.pr-5 { padding-right: 5px !important; }
.pr-10 { padding-right: 10px !important; }
.pr-15 { padding-right: 15px !important; }
.pr-20 { padding-right: 20px !important; }
.pr-30 { padding-right: 30px !important; }
.pr-40 { padding-right: 40px !important; }
.pr-50 { padding-right: 50px !important; }
.pr-60 { padding-right: 60px !important; }
.pr-70 { padding-right: 70px !important; }
.pr-80 { padding-right: 80px !important; }
.pr-90 { padding-right: 90px !important; }
.pr-100 { padding-right: 100px !important; }
.pr-110 { padding-right: 110px !important; }
.pr-120 { padding-right: 120px !important; }
.pr-130 { padding-right: 130px !important; }
.pr-140 { padding-right: 140px !important; }
.pr-150 { padding-right: 150px !important; }
.pr-200 { padding-right: 200px !important; }
.pr-250 { padding-right: 250px !important; }

/* 幅 */
/*---------------------------------------------------*/
.w-5 { width: 5%; }
.w-10 { width: 10%; }
.w-15 { width: 15%; }
.w-20 { width: 20%; }
.w-25 { width: 25%; }
.w-30 { width: 30%; }
.w-35 { width: 35%; }
.w-40 { width: 40%; }
.w-45 { width: 45%; }
.w-50 { width: 50%; }
.w-55 { width: 55%; }
.w-60 { width: 60%; }
.w-65 { width: 65%; }
.w-70 { width: 70%; }
.w-75 { width: 75%; }
.w-80 { width: 80%; }
.w-85 { width: 85%; }
.w-90 { width: 90%; }
.w-95 { width: 95%; }
.w-100 { width: 100%; }


/* HTMLで改行した場合に生じる左右の余白を消す */
/*---------------------------------------------------*/
.parent-inline-block {
    letter-spacing: -1em;
    word-spacing: -1em;
}
:root .parent-inline-block {
    font-size: 0 !important;
    letter-spacing: -1px;
}
.parent-inline-block > * {
    font-size: 14px;
    font-size: 1rem;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    display: inline-block;
    *display: inline;
    *zoom: 1;
}


/* 動画 */
/*---------------------------------------------------*/
.parent-youtube {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}
.parent-youtube iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}


/* その他 */
/*---------------------------------------------------*/
/* いいねボタンの横幅が途切れてしまう現象への対策 */
.fb-like iframe {
    max-width: none !important;
}
.white-space {
    white-space: nowrap;
}


/* 画面幅によって非表示 */
/*----------------------------------------------------------------------------*/
@media only screen and (min-width: 0px) and (max-width: 479px) {
    .only-pc { display: none !important; }
    .only-tab { display: none !important; }
    .only-sp {  }

    .over-pc { display: none !important; }
    .over-tab { display: none !important; }
    .over-sp {  }

    .under-pc {  }
    .under-tab {  }
    .under-sp {  }
}
@media only screen and (min-width: 480px) and (max-width: 659px) {
    .only-pc { display: none !important; }
    .only-tab {  }
    .only-sp { display: none !important; }

    .over-pc { display: none !important; }
    .over-tab {  }
    .over-sp {  }

    .under-pc {  }
    .under-tab {  }
    .under-sp { display: none !important; }
}
@media only screen and (min-width: 660px) {
    .only-pc {  }
    .only-tab { display: none !important; }
    .only-sp { display: none !important; }

    .over-pc {  }
    .over-tab {  }
    .over-sp {  }

    .under-pc {  }
    .under-tab { display: none !important; }
    .under-sp { display: none !important; }
}

html {
    color: #161629;
    font-size: 14px;
    font-family: HiraginoCustom, 'Hiragino Kaku Gothic ProN', YuGothic, 'Yu Gothic Medium', Meiryo, sans-serif;
    -webkit-font-feature-settings: "pkna" 1;
            font-feature-settings: "pkna" 1;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.035em;
    word-wrap: break-word;

    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

html {
        font-size: 16px
}
    }

@media only screen and (min-width: 1000px) {

    }

p {
    margin-top: 1.0em;
    margin-bottom: 1.0em;
    line-height: calc(1em + 10px);
}

.text {
    line-height: calc(1em + 10px);
}

/* a:link, a:visited を追加するとボタンの文字色にも影響がある */
a {
    color: #213265;
    text-decoration: none;
}
a:not(.btn):not(.btn-responce):not(.logo):not(.page-numbers):hover,
a:not(.btn):not(.btn-responce):not(.logo):not(.page-numbers).active {
    /* color: $color-link-hover; */
    /* opacity: .5; */
    text-decoration: underline;
}

.global-nav a:not(.btn):not(.btn-responce):not(.logo):hover,
.global-nav a:not(.btn):not(.btn-responce):not(.logo):active {
    /* color: $color-link-hover; */
    /* opacity: .5; */
    text-decoration: none;
}

[target="_blank"]::after {
        margin-left: 0.25em;
        display: inline-block;
        font-family: "original-icon-font";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: baseline;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transform: scale(1);
        transform: scale(1);
        letter-spacing: 0;
        content: "\EA07";
        color: #213265;
    }

@media only screen and (min-width: 480px) {

    [href^="tel:"] {
        pointer-events: none;
    }
}

/*----------------------------------------------------------------------------*/
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-center { text-align: center !important; }
.text-justify { text-align: justify !important; }
.vertical-top { vertical-align: top !important; }
.vertical-middle { vertical-align: middle !important; }
.vertical-bottom { vertical-align: bottom !important; }
.vertical-baseline { vertical-align: baseline !important; }
.text-notmal { font-weight: normal; }
.text-bold { font-weight: bold; }
.text-italic { font-style: italic; }
.text-oblique { font-style: oblique; }
.text-underline { text-decoration: underline; }
.text-line-through { text-decoration: line-through; }
.text-nowrap { white-space: nowrap; }
.text-lg {
    font-size: 1.2em;
}
.text-sm {
    font-size: 0.8em;
}
.text-note {
    display: block;
    color: #404040;
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px;
    line-height: calc(1em + 10px);
    white-space: pre-wrap;
}
@media only screen and (min-width: 660px) {
.text-note {
        font-size: 14px;
        margin-top: 15px
}
    }

.invalid-feedback, .text-attention {
    display: block;
    color: #ad0000;
    font-size: 14px;
}
.valid-feedback {
    display: block;
    color: #00865a;
}
.text-base {
    color: #161629;
}
em, strong {
    font-weight: bold;
}

.required {
    display: inline-block;
    margin-left: 0.5em;
    font-weight: normal;
    font-size: 12px;
    color: #ad0000;
}

@media only screen and (min-width: 660px) {

.required {
        font-size: 14px
}
    }

.m-text-error,
.error,
.mw_wp_form form .error {
    width: 100%;
    font-size: 14px;
    color: #ad0000;
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    line-height: 1;
    /* margin-top: 2em;
    margin-bottom: 1em; */
}

h1 { font-size: 20px; }
h2 { font-size: 18px; }
h3 { font-size: 14px; }
h4 { font-size: 14px; }
h5 { font-size: 14px; }
h6 { font-size: 14px; }

@media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 660px) {
    h1 { font-size: 30px; }
    h2 { font-size: 26px; }
    h3 { font-size: 16px; }
    h4 { font-size: 14px; }
    h5 { font-size: 14px; }
    h6 { font-size: 14px; }
}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {

}


.title {
    font-weight: bold;
    line-height: calc(1em + 10px);
}


.title span {
        display: inline-block;
    }


.title .title-small {
        font-size: calc(1em - 0.2em);
    }


.title.icon::before {
        margin-right: 15px;
        font-size: calc(1em + 15px);
        vertical-align: bottom;
    }


@media only screen and (min-width: 660px) {


.title {
        line-height: 1
}
    }


.title-01 {
    font-size: 20px;
    letter-spacing: 0.05em;
}


@media only screen and (min-width: 660px) {


.title-01 {
        font-size: 30px
}
    }

.title-02 {
    font-size: 18px;
}

@media only screen and (min-width: 660px) {

.title-02 {
        font-size: 26px
}
    }

.title-03 {
    font-size: 14px;
}

@media only screen and (min-width: 660px) {

.title-03 {
        font-size: 16px
}
    }

ul, ol {
    margin-top: 1.0em;
    margin-bottom: 1.0em;
    padding-left: 1.5em;
    line-height: calc(1em + 10px);
    list-style-position: outside;
}

ul ul, ul ol, ol ul, ol ol {
        margin-top: 1.0em;
        margin-bottom: 1.0em;
    }

.title + ul, .title + ol {
        margin-top: 0;
    }

@media only screen and (min-width: 480px) {

ul, ol {
        /* margin-top: 1.5em; */
        /* margin-bottom: 1.5em; */
}
    }

@media only screen and (min-width: 660px) {
    }

@media only screen and (min-width: 770px) {

ul, ol {
        /* margin-top: 2em; */
        /* margin-bottom: 2em; */
}
    }

@media only screen and (min-width: 1000px) {
    }
li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
li:first-child {
        margin-top: 0;
    }
li:last-child {
        margin-bottom: 0;
    }
li li {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }
li li:first-child {
            margin-top: 0;
        }
li li:last-child {
            margin-bottom: 0;
        }
li > p {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
    }
ul > li {
        list-style-type: disc;
    }
ul ul > li {
            list-style-type: circle;
        }
ol > li {
        list-style-type: decimal;
    }
ol ol {
        padding-left: 0;
    }
ol ol > li {
            display: flex;
            /* list-style-type: decimal; */
            list-style-type: none;
            counter-increment: cnt;
        }
ol ol > li::before {
                display: marker;
                margin-right: 0.5em;
                content: "(" counter(cnt) ") ";
            }

/* 注釈リスト */
/*----------------------------------------------------------------------------*/
.list-note {
    list-style-position: outside;
}
.list-note > li {
        list-style-type: none;
    }
.list-note > li::before {
        display: marker;
        width: 1em;
        margin-right: 0.5em;
        margin-left: -1.5em;
        content: "※";
    }



/* 通常リスト */
/*----------------------------------------------------------------------------*/

.list-text {
    padding: 0;
}

.list-text li {
        position: relative;
        margin: 0;
        padding-left: 1em;
        list-style-type: none;
    }

.list-text li::before {
            position: absolute;
            left: 0;
            counter-increment: li;
            content: "\30FB";
        }

@media all and (-ms-high-contrast:none){
            .list-text li *::-ms-backdrop, .list-text li::before { top: 10px; }
        }

/* テーブル全般 */
/*----------------------------------------------------------------------------*/
table {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 2em;
    line-height: 1;
    table-layout: fixed;
}
table th, table td {
        vertical-align: middle;
    }
table th img, table th iframe, table td img, table td iframe {
            vertical-align: top;
        }
table th {
        background: #F4F5FA !important;
        text-align: left;
        white-space: nowrap;
        padding: 10px 15px;
    }
table th p {
            margin: 0; /* WordPressの自動整形機能 */
        }
table td {
        padding: 15px 0;
    }
table, table thead, table tbody, table tfoot, table tr, table th, table td {
        box-sizing: border-box;
    }
table, table thead, table tbody, table tfoot, table tr {
        border-top: 1px none rgba(181, 181, 181, 0.7);
        border-bottom: 1px none rgba(181, 181, 181, 0.7);
    }
table caption {
        margin-bottom: 0.75em;
        font-weight: bold;
        text-align: left;
    }
table tbody tr {
            font-weight: bold;
        }
@media only screen and (min-width: 340px) {
        table th {
            padding: 10px 20px;
        }
        table td {
            padding: 15px 0;
        }
    }
@media only screen and (min-width: 480px) {
        table th, table td {
            padding: 0.75em;
            padding: 20px 10px;
            line-height: calc(1em + 10px);
        }
            table tbody th:first-child {
                width: 140px;
            }
    }
@media only screen and (min-width: 660px) {
            table thead th {
                padding: 10px;
            }
            table tbody th {
                width: 200px;
            }

                table tbody th:first-child  {
                    width: 200px;
                }
    }
@media only screen and (min-width: 770px) {
    }
@media only screen and (min-width: 1000px) {
    }

.table-sm {
    font-size: 0.85em;
}

.table-sm th, .table-sm td {
        padding: 0.5em;
    }
.table-lg {
    font-size: 1.2em;
}
.table-lg th, .table-lg td {
        padding: 1.0em;
    }
.table-fixed {
    table-layout: fixed;
}


/* テーブル (横線) */
/*----------------------------------------------------------------------------*/
table, table thead, table tbody, table tfoot, table tr, table th, table td, table.table-border-horizontal, table.table-border-horizontal thead, table.table-border-horizontal tbody, table.table-border-horizontal tfoot, table.table-border-horizontal tr, table.table-border-horizontal th, table.table-border-horizontal td {
        border-top-style: solid;
        border-bottom-style: solid;
    }
@media only screen and (min-width: 0px) and (max-width: 769px) {
    }
@media only screen and (min-width: 770px) {
                table:not(.table-calender):not(.table-bordered) th:first-child, table:not(.table-calender):not(.table-bordered) td:first-child, table.table-border-horizontal:not(.table-calender):not(.table-bordered) th:first-child, table.table-border-horizontal:not(.table-calender):not(.table-bordered) td:first-child {
                    /* padding-left: 0; */
                }
                table:not(.table-calender):not(.table-bordered) th:last-child, table:not(.table-calender):not(.table-bordered) td:last-child, table.table-border-horizontal:not(.table-calender):not(.table-bordered) th:last-child, table.table-border-horizontal:not(.table-calender):not(.table-bordered) td:last-child {
                    padding-right: 0;
                }
    }


/* テーブル (枠線) */
/*----------------------------------------------------------------------------*/
table.table-bordered th {
        background: #F4F5FA;
    }
table.table-bordered, table.table-bordered thead, table.table-bordered tbody, table.table-bordered tfoot, table.table-bordered tr, table.table-bordered th, table.table-bordered td {
        border-style: solid;
    }


/* テーブル (枠線なし) */
/*----------------------------------------------------------------------------*/
table.table-border-none, table.table-border-none thead, table.table-border-none tbody, table.table-border-none tfoot, table.table-border-none tr, table.table-border-none th, table.table-border-none td {
        border-width: 0;
        border-style: none;
        border-color: transparent;
        background: none;
    }


/* レスポンシブ */
/*----------------------------------------------------------------------------*/

@media only screen and (min-width: 0px) and (max-width: 479px) {
        table:not(.not-responsive), table:not(.not-responsive) caption, table:not(.not-responsive) thead, table:not(.not-responsive) tbody, table:not(.not-responsive) tfoot, table:not(.not-responsive) tr, table:not(.not-responsive) th, table:not(.not-responsive) td {
            display: block;
            border-style: none;
        }
            table:not(.not-responsive):not(.table-border-horizontal) thead, table:not(.not-responsive):not(.table-border-horizontal) tbody, table:not(.not-responsive):not(.table-border-horizontal) tfoot {
                border-right-style: solid;
                border-left-style: solid;
            }
            table:not(.not-responsive) thead:not(:last-child), table:not(.not-responsive) tbody:not(:last-child), table:not(.not-responsive) tfoot:not(:last-child) {
                margin-bottom: 0.5em;
            }
        table:not(.not-responsive) tr {
            /* margin-bottom: 0.5em; */
            border-top-style: solid;
        }
        table:not(.not-responsive) tr:last-child {
            margin-bottom: 0;
            border-bottom-style: solid;
        }
        table:not(.not-responsive) th, table:not(.not-responsive) td {
            border-bottom-style: dotted;
        }
        table:not(.not-responsive) td:last-child, table:not(.not-responsive) th:last-child {
            border-bottom-style: none;
        }

        table:not(.not-responsive) th:empty {
            display: none;
        }

            table:not(.not-responsive) th:empty::before {
                content: "-";
            }
        table:not(.not-responsive) th {
            /* padding-top: 0.5em; */
            /* padding-bottom: 0.5em; */
        }
    }


.fc table, .not-responsive {
    display: table;
}


.fc table thead, .not-responsive thead {
        display: table-header-group;
    }


.fc table tbody, .not-responsive tbody {
        display: table-row-group;
    }


.fc table tfoot, .not-responsive tfoot {
        display: table-footer-group;
    }


.fc table tr, .not-responsive tr {
        display: table-row;
    }


.fc table td, .fc table th, .not-responsive td, .not-responsive th {
        display: table-cell;
    }



/* スマホとPCとで縦横が入れ替わるテーブル。折り返しが発生すると崩れるのでいまいち */
@media only screen and (min-width: 480px) {
    }
@media only screen and (min-width: 660px) {
    }
@media only screen and (min-width: 770px) {
    }
@media only screen and (min-width: 1000px) {
    }
@media only screen and (min-width: 1300px) {
table.not-responsive.table-reverse {
        display: block
}

        table.not-responsive.table-reverse tbody {
            display: flex;
        }

        table.not-responsive.table-reverse tr {
            display: block;
        }
        table.not-responsive.table-reverse td, table.not-responsive.table-reverse th {
            display: block;
        }
            table.not-responsive.table-reverse tr:first-child td {
                white-space: nowrap;
            }
            table.not-responsive.table-reverse tr:not(:last-child) th, table.not-responsive.table-reverse tr:not(:last-child) td {
                border-right: none;
            }
            table.not-responsive.table-reverse tr th:not(:last-child), table.not-responsive.table-reverse tr td:not(:last-child) {
                border-bottom: none;
            }
    }


/* レスポンシブ対応のテーブル */
@media only screen and (min-width: 0px) and (max-width: 479px) {
    table:not(.not-responsive).table-scroll {
        display: flex;
    }

        table:not(.not-responsive).table-scroll thead {
            display: flex;
        }
        table:not(.not-responsive).table-scroll tbody, table:not(.not-responsive).table-scroll tfoot {
            display: flex;
            overflow-x: auto;
        }
        table:not(.not-responsive).table-scroll tr {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }
        table:not(.not-responsive).table-scroll th {
            height: 100%;
            font-size: 0.85em;
        }
        table:not(.not-responsive).table-scroll thead th:not(:first-child), table:not(.not-responsive).table-scroll thead td:not(:first-child) {
            height: 100%;
        }
        table:not(.not-responsive).table-scroll thead {
            flex-grow: 1;
        }
        table:not(.not-responsive).table-scroll tbody td {
            display: block;
            height: 100%;
            white-space: nowrap;
        }
    table:not(.not-responsive):not(.no-border).table-scroll tr + tr {
        margin-top: 0;
    }
    table:not(.not-responsive):not(.no-border).table-scroll thead {
        margin-bottom: 0;
    }




    table.table-smart {
        border: 0;
    }

        table.table-smart thead {
            position: absolute;
            overflow: hidden;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;

            clip: rect(0 0 0 0);
        }
        table.table-smart tr {
            display: block;
            margin-bottom: 0.625em;
        }
        table.table-smart td {
            display: block;
            min-height: 44px;
        }
        table.table-smart td:before {
            display: block;
            margin-bottom: 0.5em;
            font-weight: bold;
            font-size: 0.85em;
            content: attr(data-label);
        }
        table.table-smart td:last-child {
            border-bottom: 0;
        }

        table.table-smart ul, table.table-smart ol {
            clear: both;
            margin-top: 10px;
            text-align: left;
        }
}




/* テーブルのソート機能 */
/*----------------------------------------------------------------------------*/

.table-sort {
    color: #213265;
    cursor: pointer;
    display: block;
    border: 0;
    background-color: transparent;
    font-size: 16px;
    width: 100%;
    text-align: left;
    padding: 0;
}

.table-sort::after {
        font-size: 10px;
        transition: inherit;
        display: inline-block;
        font-family: "original-icon-font";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: baseline;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transform: scale(1);
        transform: scale(1);
        letter-spacing: 0;
        content: "\EA01";
        -webkit-transform: scale(1) rotate(90deg);
                transform: scale(1) rotate(90deg);
        margin-left: 8px;
    }

.table-sort[data-order="asc"]::after {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
    }

.table-sort[data-order="desc"]::after {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }

img {
    max-width: 100%;
}

figure figcaption {
        margin-top: 1em;
        line-height: 1;
        text-align: center;
    }

figure img {
        display: block;
        margin-right: auto;
        margin-left: auto;
    }

iframe {
    display: block;
    max-width: 100%;
}

/*
アイコンのsvgファイルを以下のディレクトリに保存するとアイコンフォントとして使用可能。
1. /src/font/svg/ に.svgファイルを保存
2. gulpを実行
3. 保存したファイル名が「icon-arrow.svg」の場合、
　 「<i class="icon icon-arrow"></i>」でアイコンが表示される

上記、処理が「03-mixin-pre-consolidate.css」内に記述。
*/

/*
.icon-arrow::before {
    @mixin icon;
}
*/





/* 以下、フォント以外のアイコン */
/*----------------------------------------------------------------------------*/

.icon-user {
    color: #1a878e;
}

.icon-building {
    color: #2C50A5;
}


.icon-arrow-animation {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
}


.icon-arrow-animation span {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 24px;
        height: 2px;
        margin: auto;
        background: #000000;
        transition: all 0.15s ease-in-out 0s;
    }
.icon-arrow-animation span:nth-child(1), .icon-arrow-animation span:nth-child(2) {
        -webkit-transform-origin: 100% 50%;
                transform-origin: 100% 50%;
        width: 12px;
        margin-right: 3px;
    }
.icon-arrow-animation span:nth-child(1) {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
    }
.icon-arrow-animation span:nth-child(2) {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }
.icon-arrow-animation:hover span:nth-child(1), .icon-arrow-animation:hover span:nth-child(2) {
        -webkit-transform-origin: 100% 50%;
                transform-origin: 100% 50%;
        width: 12px;
        margin-right: -7px;
    }
.icon-arrow-animation:hover span:nth-child(3) {
        box-shadow: 10px 0 0 0 #111111;
    }

html .loading {
    position: relative;
    color: transparent !important;
    pointer-events: none;
}

html .loading *, html .loading *::before, html .loading *::after {
        color: transparent !important;
        pointer-events: none;
    }

html .loading > * {
        opacity: 0.5 !important;
    }

html .loading::before, html .loading::after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        width: 14px;
        width: 1rem;
        height: 14px;
        height: 1rem;
        margin: auto;
        border-radius: 0.5rem;
        content: "";
    }

html .loading::before {
        z-index: 900;
        border: 2px solid #000000;
        border: 2px solid rgba(0, 0, 0, 0.5);
    }

html .loading::after {
        z-index: 1000;
        border: 2px solid #ffffff;
        border: 2px solid rgba(255, 255, 255, 1.0);
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
        -webkit-animation: icon-load-animation 1.0s linear 0s infinite;
                animation: icon-load-animation 1.0s linear 0s infinite;
    }

html .loading ::-webkit-input-placeholder {
        color: #000000;
        color: rgba(0, 0, 0, 0);
    }

html .loading ::-moz-placeholder {
        opacity: 1;
        color: #000000;
        color: rgba(0, 0, 0, 0);
    }

html .loading :-ms-input-placeholder {
        color: #000000;
        color: rgba(0, 0, 0, 0);
    }

html .loading :input-placeholder {
        color: #000000;
        color: rgba(0, 0, 0, 0);
    }

@-webkit-keyframes icon-load-animation {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    40% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
    100% { -webkit-transform: rotate(720deg); transform: rotate(720deg); }
}

@keyframes icon-load-animation {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    40% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
    100% { -webkit-transform: rotate(720deg); transform: rotate(720deg); }
}
@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 660px) {
}
@media only screen and (min-width: 770px) {
}
@media only screen and (min-width: 1000px) {
}

/* フォーム共通 */
/*----------------------------------------------------------------------------*/
.l-form {
    margin-top: 0;
    padding-top: 0;
}
.l-form section:not(.m-notice) {
        padding: 0;
    }
.l-form section:not(.m-notice) section:last-child {
            margin-bottom: 0;
        }
.l-form section:not(.m-notice) table:last-child {
            margin-bottom: 0;
        }
.l-form section:first-child {
        margin-top: 0;
    }
.l-form section section {
        margin: 30px 0;
    }
.l-form section .title {
        margin-bottom: 30px;
    }
.l-form .text-note {
        margin-top: 5px;
    }
.l-form table th {
            white-space: pre-line;
        }
.l-form .section-footer {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        font-size: 0;
    }
.l-form .section-footer br {
            display: none;
        }
.l-form .section-footer input[name="submitBack"] {
            margin-top: 10px;
            box-shadow: none;
            background-color: transparent;
        }
.l-form .section-footer input[name="submitBack"]:hover {
                background-color: #F4F5FA;
                color: #083B71;
            }
@media only screen and (min-width: 660px) {
        .l-form section {
            margin: 80px 0;
        }

            .l-form section .title {
                margin-bottom: 40px;
            }

            .l-form section section {
                margin: 80px 0;
            }

        .l-form .text-note {
            margin-top: 10px;
        }


        .l-form .section-footer {
            flex-direction: row;
            justify-content: space-between;
        }

            /* inputが1つのとき */
            .l-form .section-footer input,
            .l-form .section-footer .btn {
                margin-right: auto;
                margin-left: auto;
            }

            /* inputが2つのとき */
            .l-form .section-footer input[name="submitBack"] {
                margin: 0;
                box-shadow: 0 0 0 1px #083B71 inset;
                background-color: transparent;
                color: #083B71;
            }

                .l-form .section-footer input[name="submitBack"]:hover {
                    background-color: #083B71;
                    color: #ffffff;
                }

            .l-form .section-footer input ~ input {
                margin-left: auto;
                margin-right: 0;
            }
    }

.field-note {
    margin-bottom: 5px;
    padding: 0 20px;
    color: #404040;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
}

@media only screen and (min-width: 660px) {

.field-note {
        margin-bottom: 18px;
        padding: 0
}
    }

fieldset, .fieldset {
    margin: 2em 0;
}

fieldset:first-child, .fieldset:first-child {
        margin-top: 0;
    }

fieldset:last-child, .fieldset:last-child {
        margin-bottom: 0;
    }

fieldset .title, .fieldset .title {
        margin-bottom: 0.5em;
        font-size: 0.8em;
    }

fieldset > .field, .fieldset > .field {
        margin-top: 0;
        margin-bottom: 0;
    }

@media only screen and (min-width: 480px) {
    }

@media only screen and (min-width: 660px) {

fieldset, .fieldset {
        margin: 3em 0
}

        fieldset:not(.fieldset-block), .fieldset:not(.fieldset-block) {
            display: flex;
            align-items: baseline;
        }

            fieldset:not(.fieldset-block) > .title, .fieldset:not(.fieldset-block) > .title {
                width: 200px;
                margin-right: 1em;
                margin-bottom: 0;
            }
            fieldset:not(.fieldset-block) > .field, fieldset:not(.fieldset-block) > .field-group, .fieldset:not(.fieldset-block) > .field, .fieldset:not(.fieldset-block) > .field-group {
                flex: 1 1 0%;
            }
    }

@media only screen and (min-width: 770px) {
    }

@media only screen and (min-width: 1000px) {
    }

.field {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #404040;
}

.field * {
        /* IE 11 バグ */
        /* flex: 1 1 0%; */
        flex: 1 1 auto;
    }

.field > span, .field > label, .field > .unit, .field > .btn {
        /* IE バグ */
        /* flex: initial; */
        flex: 0 0 auto;
    }

.field > .unit {
        margin: 0;
    }

.field > .unit:first-child {
            margin-left: 0;
        }

.field > .unit:last-child {
            margin-right: 0;
        }

.field .field {
        margin-top: 0;
        margin-bottom: 0;
    }

.field select, .field textarea {
        margin: 0;
    }

@media only screen and (min-width: 480px) {
    }

@media only screen and (min-width: 660px) {
    }

@media only screen and (min-width: 770px) {
    }

@media only screen and (min-width: 1000px) {
    }

.field + .field {
        margin-top: 20px;
    }

.field label {
        cursor: pointer;
    }

input, select, option, textarea {
    /* firefox対策、.field内のUIの最小値を修正 */
    min-width: 1em;
    font-size: 14px;
    font-size: 1rem;
}

input:focus, input.focus, select:focus, select.focus, option:focus, option.focus, textarea:focus, textarea.focus {
        outline: none;
    }

/* フォーム全般 */
input[type="text"], input[type="tel"], input[type="email"], input[type="url"], input[type="password"], input[type="number"], input[type="file"], input[type="search"], input[type="date"], select, option, textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: 38px;
    min-height: 38px;
    padding: 8px 20px;
    border-width: 1px;
    border-style: solid;
    border-color: #b5b5b5;
    border-color: rgba(181, 181, 181, 0.7);
    border-radius: 0;
    background-color: #ffffff;
    font-family: HiraginoCustom, 'Hiragino Kaku Gothic ProN', YuGothic, 'Yu Gothic Medium', Meiryo, sans-serif;
}
input[type="text"].is-valid, input[type="tel"].is-valid, input[type="email"].is-valid, input[type="url"].is-valid, input[type="password"].is-valid, input[type="number"].is-valid, input[type="file"].is-valid, input[type="search"].is-valid, input[type="date"].is-valid, select.is-valid, option.is-valid, textarea.is-valid {
        border-color: #00865a;
    }
input[type="text"].is-invalid, input[type="tel"].is-invalid, input[type="email"].is-invalid, input[type="url"].is-invalid, input[type="password"].is-invalid, input[type="number"].is-invalid, input[type="file"].is-invalid, input[type="search"].is-invalid, input[type="date"].is-invalid, select.is-invalid, option.is-invalid, textarea.is-invalid {
        border-color: #ad0000;
    }
@media only screen and (max-width: 340px) {
input[type="text"], input[type="tel"], input[type="email"], input[type="url"], input[type="password"], input[type="number"], input[type="file"], input[type="search"], input[type="date"], select, option, textarea {
        padding: 8px 15px
}
    }
@media only screen and (min-width: 660px) {
input[type="text"], input[type="tel"], input[type="email"], input[type="url"], input[type="password"], input[type="number"], input[type="file"], input[type="search"], input[type="date"], select, option, textarea {
        height: 42px;
        min-height: 42px;
        padding: 8px 20px
}
    }

input[type="tel"], input[name="postcode"], select {
    max-width: 195px;
}

/* 影、disabled */
input[type="text"]:focus:not(:disabled):not([readonly]), input[type="tel"]:focus:not(:disabled):not([readonly]), input[type="email"]:focus:not(:disabled):not([readonly]), input[type="url"]:focus:not(:disabled):not([readonly]), input[type="password"]:focus:not(:disabled):not([readonly]), input[type="number"]:focus:not(:disabled):not([readonly]), input[type="file"]:focus:not(:disabled):not([readonly]), input[type="search"]:focus:not(:disabled):not([readonly]), input[type="date"]:focus:not(:disabled):not([readonly]), textarea:focus:not(:disabled):not([readonly]) {
            border-color: #213265;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1) inset;
            background-color: #ffffff;
        }
/* selectに「:read-only」が使用できない */
input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not([type="submit"]):-moz-read-only, input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not([type="submit"]):disabled, input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not([type="submit"])[readonly], textarea:-moz-read-only, textarea:disabled, textarea[readonly] {
        box-shadow: none;
        background-color: #e0e0e5;
        color: #000000;
        color: rgba(0, 0, 0, 0.5);
    }
input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not([type="submit"]):read-only, input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not([type="submit"]):disabled, input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not([type="submit"])[readonly], textarea:read-only, textarea:disabled, textarea[readonly] {
        box-shadow: none;
        background-color: #e0e0e5;
        color: #000000;
        color: rgba(0, 0, 0, 0.5);
    }
.field-disabled input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not([type="submit"]), .field-disabled textarea {
        box-shadow: none;
        background-color: #e0e0e5;
        color: #000000;
        color: rgba(0, 0, 0, 0.5);
    }




textarea {
    display: block;
    width: 100%;
    resize: vertical !important;
}
/* input[type="number"] {
    flex: 0 0 6em;
    width: 6em;
    IE対策
    min-width: 6em;
} */
input[type="file"] {
    cursor: pointer;
}
input[type="radio"], input[type="checkbox"] {
    margin-right: 14px;
    margin-right: 1rem;
    cursor: pointer;
}
input[type="radio"].before-icon, input[type="checkbox"].before-icon {
        width: 14px;
        width: 1rem;
        height: 14px;
        height: 1rem;
        margin: 0;
        opacity: 0;
        font-size: 14px;
        font-size: 1rem;
    }
input[type="radio"].before-icon + .icon, input[type="checkbox"].before-icon + .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            /* box-sizing: border-box; */
            /* IE バグ border-boxを取ったため、widthとheightを少し小さくした */
            width: 17px; /* 19px */
            height: 17px; /* 19px */
            margin: 0 10px 0 -14px;
            margin: 0 10px 0 -1rem;
            padding: 0;
            border: 1px solid #868686;
            border-radius: 1px;
            background-color: #ffffff;
            color: #777777;
            font-size: 19px;
            line-height: 1;
            vertical-align: inherit;
        }
input[type="radio"].before-icon + .icon::before, input[type="checkbox"].before-icon + .icon::before {
            display: block;
            margin: 0;
            background-color: #ffffff;
            -webkit-animation: input-icon 80ms cubic-bezier(0.4, 0.0, 0.2, 1) forwards;
                    animation: input-icon 80ms cubic-bezier(0.4, 0.0, 0.2, 1) forwards;
        }
/* Firefoxでは「:read-only」はdisabled以外のときも適用されてしまう */
input[type="radio"].before-icon:disabled:checked + .icon::before, input[type="radio"].before-icon:disabled:checked ~ *, input[type="radio"].before-icon[readonly]:checked + .icon::before, input[type="radio"].before-icon[readonly]:checked ~ *, input[type="checkbox"].before-icon:disabled:checked + .icon::before, input[type="checkbox"].before-icon:disabled:checked ~ *, input[type="checkbox"].before-icon[readonly]:checked + .icon::before, input[type="checkbox"].before-icon[readonly]:checked ~ * {
                    color: #e0e0e5;
                    cursor: default;
                }
input[type="radio"]:checked + .icon, input[type="checkbox"]:checked + .icon {
        color: #213265;
    }


input[type="radio"].before-icon + .icon {
        border-radius: 100%;
    }


input[type="radio"].before-icon:checked + .icon::before {
            content: "\EA08";
        }
input[type="checkbox"].before-icon:checked + .icon::before {
            content: "\EA03";
        }

select {
    position: relative;
    padding-right: 24px;
    background-image: url(../img/common/icon-select-margin.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
    background-size: 9.4px 6.1px;
    line-height: 1;
    vertical-align: middle;
    cursor: pointer;

    -webkit-appearance: none;

       -moz-appearance: none;

            appearance: none;
}

/* selectの場合「:read-only」はdisabled以外のときも適用されてしまう */

select:disabled, select[readonly] {
        box-shadow: none;
        background-color: #e0e0e5;
        color: #000000;
        color: rgba(0, 0, 0, 0.5);
    }

select::-ms-expand {
        display: none;
    }

select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #161629;
    }

select[size]:not([size="1"]), select[multiple] {
    overflow: auto;
    padding: 0;
    background-image: none;
}

select[size]:not([size="1"]) option, select[multiple] option {
        border: none;
    }

select[size]:not([size="1"]) optgroup, select[multiple] optgroup {
        padding: 0.5em 0;
        color: #cccccc;
        font-weight: normal;
        font-size: 0.85em;
        text-indent: 5px;
    }

select[size]:not([size="1"]) optgroup > option, select[multiple] optgroup > option {
            padding-right: 0;
            padding-left: 0;
            color: #161629;
            font-size: 14px;
            font-size: 1rem;
            text-indent: 0;
        }

select[size]:not([size="1"]) optgroup > option::before, select[multiple] optgroup > option::before {
            display: inline-block;
            width: 1em;
            content: "";
        }

select[size]:not([size="1"]) optgroup > option:first-child, select[multiple] optgroup > option:first-child {
            margin-top: 5px;

            border-top: 1px dotted #cccccc;
        }

select[size]:not([size="1"]) optgroup:not(:first-child), select[multiple] optgroup:not(:first-child) {
        border-top: 1px dotted #cccccc;
    }

select[size]:not([size="1"]) optgroup:last-child, select[multiple] optgroup:last-child {
        padding-bottom: 0;
    }

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    border-color: #cbcbcb;
    box-shadow: 0 0 0 1000px #F4F5FA inset!important;
    background-color: #F4F5FA !important;
}
input:-webkit-autofill:focus, textarea:-webkit-autofill:focus, select:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px #F4F5FA inset !important;
}


.field-block > span,  .field-block > label,  .field-checkbox > span,  .field-checkbox > label {
        width: 100%;
    }


@media only screen and (max-width: 479px) {
         .field-block > span,  .field-block > label,  .field-checkbox > span,  .field-checkbox > label {
            display: flex;
            flex-direction: column;
            align-items: baseline !important;
            justify-content: center;
        }

            .field-block > span em + span, .field-block > label em + span, .field-checkbox > span em + span, .field-checkbox > label em + span {
                margin: 10px 0 0 0;
                font-size: 11px;
            }
    }


@media only screen and (max-width: 479px) {

            .field-block label em + span, .field-checkbox label em + span {
                margin: 5px 0 0 0;
                font-size: 10px;
            }
    }


.field-radio, .field-checkbox {
    flex-wrap: wrap;
    padding: 0 0 0 10px;
}


.field-radio *, .field-checkbox * {
        /* IE 11 バグ */
        /* flex: unset; */
        flex: 0 0 auto;
    }


.field-radio > span,  .field-radio > label,  .field-radio > label:not(:first-child),  .field-radio > label:not(:last-child),  .field-checkbox > span,  .field-checkbox > label,  .field-checkbox > label:not(:first-child),  .field-checkbox > label:not(:last-child) {
        margin: 0;
    }


.field-radio > span,  .field-radio > label,  .field-checkbox > span,  .field-checkbox > label {
        display: flex;
        /* IEでラジオボタンが横方向に潰れる */
        flex: 0 0 auto;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        min-width: 84px;
        max-width: calc(100% - 0.5em);
        font-weight: normal;
    }


.field-radio > span label, .field-radio > label label, .field-checkbox > span label, .field-checkbox > label label {
            padding: 10px 0 10px 40px;
            cursor: pointer;
        }


.field-radio > span .before-icon, .field-radio > label .before-icon, .field-checkbox > span .before-icon, .field-checkbox > label .before-icon {
            position: absolute;
        }


.field-radio > span .icon, .field-radio > label .icon, .field-checkbox > span .icon, .field-checkbox > label .icon {
            position: absolute;
            top: 8px;
            left: 24px;
        }


.field-radio > span em + span, .field-radio > label em + span, .field-checkbox > span em + span, .field-checkbox > label em + span {
            margin-left: 0.25em;
        }


.field-radio input ~ input, .field-radio input ~ textarea, .field-checkbox input ~ input, .field-checkbox input ~ textarea {
        max-width: calc(100% - 47px);
        margin-top: 5px;
        margin-bottom: 0.5em;
        margin-left: 40px;
        padding: 8px 15px;
    }


@media only screen and (max-width: 999px) {


.field-radio input ~ input, .field-radio input ~ textarea, .field-checkbox input ~ input, .field-checkbox input ~ textarea {
            min-height: 68px
    }
        }


@media only screen and (max-width: 659px) {


.field-radio input ~ input, .field-radio input ~ textarea, .field-checkbox input ~ input, .field-checkbox input ~ textarea {
            min-height: 54px
    }
        }


@media only screen and (max-width: 340px) {


.field-radio input ~ input, .field-radio input ~ textarea, .field-checkbox input ~ input, .field-checkbox input ~ textarea {
            padding: 8px 10px
    }
        }


.field-radio label.disabled, .field-radio label.disabled *, .field-radio label.disabled input[type="radio"], .field-radio label.disabled input[type="checkbox"], .field-checkbox label.disabled, .field-checkbox label.disabled *, .field-checkbox label.disabled input[type="radio"], .field-checkbox label.disabled input[type="checkbox"] {
            color: #dddddd;
            cursor: default;
        }


.field-radio.field-flex > label, .field-radio.field-flex > span, .field-checkbox.field-flex > label, .field-checkbox.field-flex > span {
        min-width: calc( 100% / 2 );
    }


@media only screen and (min-width: 480px) {

            .field-radio > span label .icon, .field-checkbox > span label .icon {
                top: 12px;
            }
    }


@media only screen and (min-width: 660px) {


.field-radio, .field-checkbox {
        margin: -0.5em;
        padding: 0
}

        .field-radio > span label, .field-checkbox > span label {
            padding: 7px 0 7px 36px;
            line-height: 1;
        }

            .field-radio > span label .icon, .field-checkbox > span label .icon {
                top: 6px;
            }

        .field-radio.field-flex > label, .field-radio.field-flex > span, .field-checkbox.field-flex > label, .field-checkbox.field-flex > span {
            min-width: calc( 90% / 3 );
        }
    }


@media only screen and (min-width: 770px) {
    }


@media only screen and (min-width: 1000px) {
    }

.field.field-textarea-lg textarea {
        height: 93px;
    }


.field.field-agree {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
}


.field.field-agree > span label * {
        flex: 0 0 auto;
    }


.field.field-agree > span label {
        display: flex;
        align-items: center;
        margin-right: 0.5em;
    }


.field.field-agree > span label input[type="checkbox"].before-icon {
            position: absolute;
        }


.field.field-agree > span label input[type="checkbox"].before-icon + .icon {
            margin-right: 0.5em;
        }


.field.field-agree > span {
        margin-left: 0;
    }


.field.field-agree a[target="_blank"]::after {
        content: none;
    }




.field-group.field-radio > span, .field-group.field-radio > label {
            min-width: 84px;
            margin-right: 20px;
        }



/* バリデーションエラー */
/*----------------------------------------------------------------------------*/

.field + .invalid-feedback, .field + .valid-feedback {
    margin-top: 10px;
}

/* .m-text-errorと.errorは01-text.cssに移動 */

table .m-text-error, .mw_wp_form form .error {
    padding: 10px 0 0 0;
    background-color: transparent;
    font-weight: bold;
}

.m-text-error:empty {
    display: none;
}

.mw_wp_form form .field-radio .error, .mw_wp_form form .field-checkbox .error {
            padding: 10px 0 0 9px;
        }

.mw_wp_form form .field-agree .error {
            text-align: center;
        }



/* プレースホルダ */
/*----------------------------------------------------------------------------*/
::-webkit-input-placeholder {
    color: #848484;
    font-size: 12px;
}
@media only screen and (min-width: 480px) {
    }
@media only screen and (min-width: 660px) {
::-webkit-input-placeholder {
        font-size: 16px
}
    }
@media only screen and (min-width: 770px) {
    }
@media only screen and (min-width: 1000px) {
    }
::-ms-input-placeholder {
    color: #848484;
    font-size: 12px;
}
@media only screen and (min-width: 480px) {
    }
@media only screen and (min-width: 660px) {
::-ms-input-placeholder {
        font-size: 16px
}
    }
@media only screen and (min-width: 770px) {
    }
@media only screen and (min-width: 1000px) {
    }
::placeholder {
    color: #848484;
    font-size: 12px;
}
@media only screen and (min-width: 480px) {
    }
@media only screen and (min-width: 660px) {
::placeholder {
        font-size: 16px
}
    }
@media only screen and (min-width: 770px) {
    }
@media only screen and (min-width: 1000px) {
    }

::-webkit-input-placeholder {
    color: #323232;
    color: rgba(50, 50, 50, 0.6);
}
::-moz-placeholder {
    opacity: 1;
    color: #323232;
    color: rgba(50, 50, 50, 0.6);
}
:-ms-input-placeholder {
    color: #323232;
    color: rgba(50, 50, 50, 0.6);
}
:input-placeholder {
    color: #323232;
    color: rgba(50, 50, 50, 0.6);
}



.invisible {
    display: none !important;
}

/* アラート、メッセージ、Nag */
/* https://getbootstrap.com/docs/4.1/components/alerts/ */
/* メッセージ */
/* https://semantic-ui.com/collections/message.html */
/* Nag */
/* https://semantic-ui.com/modules/nag.html */
/*----------------------------------------------------------------------------*/
.alert {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.75em 1em;
    border-radius: 4px;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #aaaaaa;
    color: #ffffff;
    line-height: calc(1em + 10px);
}
.alert .btn {
        display: flex;
        flex: 0 0 2em;
        align-items: center;
        justify-content: center;
        width: 2em;
        min-width: auto;
        height: 2em;
        min-height: auto;
        margin-left: auto;
        padding: 0;
        border: none;
        border-radius: 50%;
        box-shadow: none;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.2);
        color: #ffffff;
        font-size: 0.5em;
        line-height: 1;
    }
.alert .btn:hover {
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.5);
        color: #ffffff;
    }
.alert.alert-danger {
        background-color: #ad0000;
    }
.alert.alert-warning {
        background-color: #768600;
    }
.alert.alert-success {
        background-color: #00865a;
    }

.alert-group {
    position: fixed;
    top: calc(50px + 5px);
    right: 5px;
    left: 5px;
    z-index: 10000;
}

.alert-group .alert:not(:last-child) {
        margin-bottom: 5px;
    }

@media only screen and (min-width: 480px) {
    }

@media only screen and (min-width: 660px) {
    }

@media only screen and (min-width: 770px) {
    }

@media only screen and (min-width: 1200px) {

.alert-group {
        top: calc(90px + 5px)
}
    }

/* タグ、バッジ、ラベル(Semantic UI) */
/* https://getbootstrap.com/docs/4.1/components/alerts/ */
/* https://semantic-ui.com/elements/label.html */
/*----------------------------------------------------------------------------*/

.badge-group {
    margin: -0.25em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.badge-group > * {
        margin-right: 0.25em;
    }

@media only screen and (min-width: 480px) {
    }

@media only screen and (min-width: 660px) {
    }

@media only screen and (min-width: 770px) {
    }

@media only screen and (min-width: 1000px) {
    }

.badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: 0 0 0 1px #083B71 inset;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
}

/* ボタンのサイズ */

.badge.badge-lg {
        font-size: 14px;
        font-size: 1rem;
    }

.badge.badge-sm {
        font-size: 10px;
    }

/* ボタンの形 */

.badge, .badge.badge-rect {
        padding: 0.5em 1.0em;
        border-radius: 4px;
    }

.badge.badge-pill {
        padding: 0.5em 1.0em;
        border-radius: calc(1em + 1em + 1em);
    }

.badge.badge-circle {
        width: 2em;
        height: 2em;
        padding: 0;
        border-radius: 2em;
    }

.badge.badge-text {
        background: transparent;
        color: #161629;
        box-shadow: none;
        padding: 0;
        margin: 0 10px;
    }

/* ボタンの枠線、背景色 */

.badge, .badge.badge-fill {
        background-color: #083B71;
        color: #ffffff;
    }

.badge.badge-outline {
        box-shadow: 0 0 0 1px #083B71 inset;
        background-color: transparent;
        color: #083B71;
    }

.badge.new {
        color: #C33838;
    }

@page {
    size: A4;
    margin: 10mm;
}

@media print {

    body.single-recruitment,
    body.single-job_seeking {
        width: 100%;
        -webkit-print-color-adjust: exact;
    }

        body.single-recruitment header,
        body.single-recruitment .m-notice,
        body.single-recruitment .m-page-prev,
        body.single-recruitment .l-footer,
        body.single-job_seeking header,
        body.single-job_seeking .m-notice,
        body.single-job_seeking .m-page-prev,
        body.single-job_seeking .l-footer {
            display: none;
        }

        body.single-recruitment .l-form .container .section-footer, body.single-job_seeking .l-form .container .section-footer {
            display: none;
        }

        body.single-recruitment .container, body.single-job_seeking .container {
            width: 100%;
        }
}

/* [課題] 複数ボタンのときのクラスを用意 */
/* https://getbootstrap.com/docs/4.1/components/buttons/*/
/* https://semantic-ui.com/elements/button.html */
/*----------------------------------------------------------------------------*/

.btn-group > *:not(:last-child) {
        margin-bottom: 0.5em;
    }

@media only screen and (min-width: 480px) {

.btn-group {
        display: flex;
        align-items: center
}

        .btn-group > *:not(:last-child) {
            margin-right: 0.5em;
            margin-bottom: 0;
        }
    }

@media only screen and (min-width: 660px) {
    }

@media only screen and (min-width: 770px) {
    }

@media only screen and (min-width: 1000px) {
    }

input.btn, button.btn {
    outline: none;
}

.btn, input[type="submit"] {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    min-width: 200px;
    max-width: 230px;
    padding: 17px 3em;
    border: none;
    box-shadow: 0 0 0 1px #083B71 inset;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
}

@media only screen and (min-width: 660px) {

.btn, input[type="submit"] {
        width: auto;
        min-width: 200px;
        max-width: unset;
        font-size: 16px
}
    }

/* ボタンのサイズ */

.btn.btn-lg, input[type="submit"].btn-lg {
        max-width: 280px;
        font-size: 14px;
    }

@media only screen and (min-width: 480px) {
        }

@media only screen and (min-width: 660px) {

.btn.btn-lg, input[type="submit"].btn-lg {
            width: 100%;
            max-width: 410px;
            font-size: 18px
    }
        }

@media only screen and (min-width: 770px) {
        }

@media only screen and (min-width: 1000px) {
        }

.btn.btn-md, input[type="submit"].btn-md {
        width: 100%;
        max-width: 230px;
        font-size: 14px;
    }

@media only screen and (min-width: 660px) {

.btn.btn-md, input[type="submit"].btn-md {
            max-width: 200px;
            font-size: 16px
    }
        }

.btn.btn-sm, input[type="submit"].btn-sm {
        min-width: auto;
        font-size: 14px;
    }

.btn.btn-block, input[type="submit"].btn-block {
        display: block;
        max-width: 320px;
    }

/* ボタンの形 */

.btn.btn-rect, input[type="submit"].btn-rect {
        padding: 1.25em 2em;
        border-radius: 4px;
    }

.btn.btn-rect.btn-lg, input[type="submit"].btn-rect.btn-lg {
            padding: 1.25em 2em;
        }

.btn.btn-rect.btn-sm, input[type="submit"].btn-rect.btn-sm {
            padding: 0.75em 1.5em;
        }

.btn, .btn.btn-pill, input[type="submit"], input[type="submit"].btn-pill {
        padding: 17px 3em;
        border-radius: calc(1em + 1em + 1em);
    }

.btn.btn-lg, .btn.btn-pill.btn-lg, input[type="submit"].btn-lg, input[type="submit"].btn-pill.btn-lg {
            box-sizing: border-box;
            padding: 18px 3em;
        }

@media only screen and (min-width: 660px) {

.btn.btn-lg, .btn.btn-pill.btn-lg, input[type="submit"].btn-lg, input[type="submit"].btn-pill.btn-lg {
                padding: 26px 3em
        }
            }

.btn.btn-md, .btn.btn-pill.btn-md, input[type="submit"].btn-md, input[type="submit"].btn-pill.btn-md {
            box-sizing: border-box;
            padding: 18px 3em;
        }

@media only screen and (min-width: 660px) {

.btn.btn-md, .btn.btn-pill.btn-md, input[type="submit"].btn-md, input[type="submit"].btn-pill.btn-md {
                padding: 17px 3em
        }
            }

.btn.btn-sm, .btn.btn-pill.btn-sm, input[type="submit"].btn-sm, input[type="submit"].btn-pill.btn-sm {
            padding: 0.75em 1.5em;
        }

.btn.btn-circle, input[type="submit"].btn-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5em;
        min-width: auto;
        height: 2.5em;
        min-height: auto;
        padding: 0;
        border-radius: 2.5em;
        line-height: 1;
        letter-spacing: 0;
    }

.btn.btn-circle .icon, input[type="submit"].btn-circle .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1em;
            height: 1em;
            line-height: 1;
            letter-spacing: 0;
        }

.btn.btn-circle .icon::before, input[type="submit"].btn-circle .icon::before {
                display: block;
                line-height: 1;
                letter-spacing: 0;
            }

/* ボタンの文字色、背景色、枠線の色(box-shadow) */

.btn, .btn.btn-fill, input[type="submit"], input[type="submit"].btn-fill {
        background-color: #083B71;
        color: #ffffff;
    }

.btn:hover, .btn.btn-fill:hover, input[type="submit"]:hover, input[type="submit"].btn-fill:hover {
            background-color: transparent;
            color: #083B71;
        }

.btn.btn-outline, .btn[name="submitBack"], input[type="submit"].btn-outline, input[type="submit"][name="submitBack"] {
        background-color: transparent;
        color: #083B71;
    }

.btn.btn-outline:hover, .btn[name="submitBack"]:hover, input[type="submit"].btn-outline:hover, input[type="submit"][name="submitBack"]:hover {
            background-color: #083B71;
            color: #ffffff;
        }

.btn.btn-skeleton, input[type="submit"].btn-skeleton {
        box-shadow: none;
        background-color: transparent;
        color: #083B71;
    }

.btn.btn-skeleton:hover, input[type="submit"].btn-skeleton:hover {
            background-color: #F4F5FA;
            color: #161629;
        }

@media only screen and (min-width: 480px) {
    }

@media only screen and (min-width: 660px) {
    }

@media only screen and (min-width: 770px) {
    }

@media only screen and (min-width: 1000px) {
    }

.btn.disabled, .btn.disabled:hover, .btn:disabled, .btn:disabled:hover, .btn[readonly], .btn[readonly]:hover, input[type="submit"].disabled, input[type="submit"].disabled:hover, input[type="submit"]:disabled, input[type="submit"]:disabled:hover, input[type="submit"][readonly], input[type="submit"][readonly]:hover {
            box-shadow: 0 0 0 1px #777777 inset;
            background-color: #777777;
            opacity: 1.0;
            color: #aaaaaa;
            cursor: default;
        }

.btn.disabled::after, .btn.disabled:hover::after, .btn:disabled::after, .btn:disabled:hover::after, .btn[readonly]::after, .btn[readonly]:hover::after, input[type="submit"].disabled::after, input[type="submit"].disabled:hover::after, input[type="submit"]:disabled::after, input[type="submit"]:disabled:hover::after, input[type="submit"][readonly]::after, input[type="submit"][readonly]:hover::after {
                -webkit-transform: none;
                        transform: none;
            }

.btn-white {
    box-shadow: 0 0 0 2px #ffffff inset;
}

/* ボタンの文字色、背景色、枠線の色(box-shadow) */

.btn-white, .btn-white.btn-fill {
        background-color: #ffffff;
        color: #083B71;
    }

.btn-white:hover, .btn-white.btn-fill:hover {
            background-color: transparent;
            color: #ffffff;
        }

.btn-white.btn-outline {
        background-color: transparent;
        color: #ffffff;
    }

.btn-white.btn-outline:hover {
            background-color: #ffffff;
            color: #083B71;
        }

.btn-white.btn-skeleton {
        box-shadow: none;
        background-color: transparent;
        color: #ffffff;
    }

.btn-white.btn-skeleton:hover {
            background-color: #ffffff;
            background-color: rgba(255, 255, 255, 0.2);
            color: #ffffff;
        }



.pagination-area {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 40px 0;
    font-size: 14px;
}



.pagination-area p {
        margin: 0;
    }



.pagination-area .field select {
        box-sizing: border-box;
        width: 64px;
        height: 30px;
        min-height: 30px;
        margin-left: 10px;
        padding: 0 8px;
        border-color: #b5b5b5;
        border-color: rgba(181, 181, 181, 0.7);
        border-radius: 1px;
        background-image: url(../img/common/icon-select-margin-gray.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 8px) center;
        background-size: 9.4px 6.1px;
        font-size: 14px;
    }



/* PCファースト */



@media only screen and (max-width: 659px) {



.pagination-area {
        flex-direction: column;
        align-items: baseline;
        justify-content: space-between;
        font-size: 14px
}

        .pagination-area p {
            margin: 0;
            /* order: 1; */
        }

        .pagination-area .field {
            order: 2;
            margin: 15px 0;
        }

        .pagination-area .pagination {
            order: 3;
        }
    }


.pagination {
    display: flex;
    align-items: center;
    margin: auto;
    border: 1px solid #b5b5b5;
    border: 1px solid rgba(181, 181, 181, 0.7);
    border-radius: 1px;
}


.pagination > * {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        min-width: calc(1em + 25px);
        min-height: 37px;
        padding: 10px 10px;
        border: none;
        box-shadow: none;
        background-color: transparent;
        /* &.btn-skeleton { } からコピペ */
        background-color: transparent;
        color: #161629;
        color: #161629;
        font-size: 1em;
        font-size: 1em;
        line-height: 1;
        line-height: 1;
        text-align: center;
        text-align: center;
        white-space: nowrap;
        white-space: nowrap;
    }


.pagination > *:hover {
            position: relative;
            z-index: 1000;
            background: #e9ecf4;
            opacity: 1 !important;
        }


.pagination > *:not(:first-child) {
        border-left: 1px solid rgba(181, 181, 181, 0.7);
    }


.pagination > *.current, .pagination > *.current:hover {
        position: relative;
        z-index: 1000;
        box-shadow: 0 0 0 2px #083B71 inset;
        background-color: #083B71;
        color: #ffffff;
    }


.pagination > span, .pagination > span:hover, .pagination > *.disabled, .pagination > *.disabled:hover, .pagination > *:disabled, .pagination > *:disabled:hover, .pagination > *[readonly], .pagination > *[readonly]:hover {
            box-shadow: 0 0 0 1px #F4F5FA inset;
            background-color: #F4F5FA;
            opacity: 1.0;
            cursor: default;
        }


.pagination > span::after, .pagination > span:hover::after, .pagination > *.disabled::after, .pagination > *.disabled:hover::after, .pagination > *:disabled::after, .pagination > *:disabled:hover::after, .pagination > *[readonly]::after, .pagination > *[readonly]:hover::after {
                -webkit-transform: none;
                        transform: none;
            }


.pagination .page-numbers:hover::before, .pagination .page-numbers:hover::after {
            color: #161629;
        }


.pagination .prev.page-numbers, .pagination .next.page-numbers {
        width: 65px;
        min-height: 37px;
    }


.pagination .prev.page-numbers.disabled, .pagination .next.page-numbers.disabled {
            color: #777777;
            pointer-events: none;
        }


.pagination .prev.page-numbers::before, .pagination .next.page-numbers::after {
        color: #b5b5b5;
        color: rgba(181, 181, 181, 0.7);
        font-size: 10px;
        transition: inherit;
        display: inline-block;
        font-family: "original-icon-font";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: baseline;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transform: scale(1);
        transform: scale(1);
        letter-spacing: 0;
        content: "\EA01";
    }


.pagination .prev.page-numbers:hover::before, .pagination .next.page-numbers:hover::after {
        color: #161629 !important;
    }


.pagination .prev.page-numbers::before {
        -webkit-transform: scale(1) rotate(180deg);
                transform: scale(1) rotate(180deg);
        margin-right: 4px;
    }


.pagination .next.page-numbers::after {
        -webkit-transform: scale(1);
                transform: scale(1);
        margin-left: 4px;
    }


.m-page-prev {
    margin: 30px 0;
}


.m-page-prev a {
        margin: 30px 0;
        color: #213265;
    }


.m-page-prev a::before {
            display: inline-block;
            font-family: "original-icon-font";
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            vertical-align: baseline;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            -webkit-transform: scale(1);
            transform: scale(1);
            letter-spacing: 0;
            content: "\EA01";
            -webkit-transform: scale(1) rotate(180deg);
                    transform: scale(1) rotate(180deg);
            margin-right: 4px;
            font-size: 12px;
            transition: inherit;
        }


@media only screen and (min-width: 660px) {


.m-page-prev {
        margin: 35px 0
}
    }

html .fc .fc-view-container .fc-view .fc-widget-header {
                    border-top-color: transparent;
                    border-right-color: transparent;
                    border-left-color: transparent;
                }

html .fc .fc-view-container .fc-view .fc-widget-header th.fc-day-header {
                        border-color: transparent;
                        background: none;
                        font-weight: bold;
                    }

html .fc .fc-view-container .fc-view .fc-widget-header th.fc-sat {
                        color: #3c83af;
                    }

html .fc .fc-view-container .fc-view .fc-widget-header th.fc-sun {
                        color: #a23737;
                    }

html .fc .fc-day-grid-event {
            margin: 0;
        }

html .fc .fc-day-top {
            text-align: center;
        }

html .fc .fc-day-top .fc-day-number {
                float: none;
            }

html .fc .fc-day-top.fc-past {
            opacity: 0.25;
        }

/* 文字 */

html .fc .fc-content-skeleton {
            padding: 0;
        }

html .fc .fc-content-skeleton table, html .fc .fc-content-skeleton thead, html .fc .fc-content-skeleton tbody, html .fc .fc-content-skeleton tfoot, html .fc .fc-content-skeleton tr, html .fc .fc-content-skeleton th, html .fc .fc-content-skeleton td {
                border: none;
            }

html .fc .fc-content-skeleton .fc-event-container a.fc-event, html .fc .fc-content-skeleton .fc-event-container a.fc-event-dot {
                    border: none;
                    background-color: transparent;
                }

html .fc .fc-content-skeleton .fc-event-container a.fc-event {
                    font-size: 16px;
                    text-align: center;
                }

/* 青 */

html .fc .fc-content-skeleton .fc-event-container a.fc-event.possible, html .fc .fc-content-skeleton .fc-event-container a.fc-event.possible:hover {
                    color: #3c83af;
                }

/* 赤 */

html .fc .fc-content-skeleton .fc-event-container a.fc-event.few, html .fc .fc-content-skeleton .fc-event-container a.fc-event.few:hover {
                    color: #a23737;
                }

html .fc .fc-content-skeleton .fc-event-container a.fc-event.few .fc-title::before {
                        display: inline-block;
                        margin-right: 0.5em;
                        color: #161629;
                        font-size: 0.8em;
                        content: "残";
                    }

html .fc .fc-content-skeleton .fc-event-container a.fc-event.past {
                    color: transparent !important;
                }

/* グレー */

html .fc .fc-content-skeleton .fc-event-container a.fc-event.impossible, html .fc .fc-content-skeleton .fc-event-container a.fc-event.impossible:hover, html .fc .fc-content-skeleton .fc-event-container a.fc-event.closed, html .fc .fc-content-skeleton .fc-event-container a.fc-event.closed:hover {
                    color: #999999;
                }

html .fc .fc-bgevent-skeleton td.fc-bgevent {
                background: none;
            }

/* 背景色 */

/* 青 */

html .fc .fc-bg td.bg-possible {
                background: #e7f1f7;
            }

/*td.bg-possible:hover,*/

html .fc .fc-bg td.bg-possible.active {
                background: #cde5f3;
            }

/* 赤 */

html .fc .fc-bg td.bg-few {
                background: #f8e6e6;
            }

/*td.bg-impossible:hover,
            td.bg-few:hover,*/

html .fc .fc-bg td.bg-impossible.active, html .fc .fc-bg td.bg-few.active {
                background: #fdd2d2;
            }

/* 黄 */

html .fc .fc-bg td.fc-today {
                background: #fffacc;
            }

/*td.fc-today:hover,*/

html .fc .fc-bg td.fc-today.active {
                background: #fff8b9;
            }

/* グレー */

html .fc .fc-bg td.fc-past, html .fc .fc-bg td.bg-impossible, html .fc .fc-bg td.bg-closed {
                background: #f2f2f2 !important;
            }

html .fc .fc-bg td.fc-disabled-day {
                background: #dddddd;
                opacity: 1.0;
            }

html .fc .fc-toolbar.fc-header-toolbar .fc-left, html .fc .fc-toolbar.fc-header-toolbar .fc-right, html .fc .fc-toolbar.fc-header-toolbar .fc-center {
                display: block;
                margin-bottom: 1em;
            }

html .fc .fc-toolbar.fc-header-toolbar .fc-left, html .fc .fc-toolbar.fc-header-toolbar .fc-left > *, html .fc .fc-toolbar.fc-header-toolbar .fc-right, html .fc .fc-toolbar.fc-header-toolbar .fc-right > *, html .fc .fc-toolbar.fc-header-toolbar .fc-center, html .fc .fc-toolbar.fc-header-toolbar .fc-center > * {
                    float: none;
                    margin-right: auto;
                    margin-left: auto;
                    text-align: center;
                }

html .fc .fc-toolbar.fc-header-toolbar .fc-center > *:not(:last-child) {
                    margin-right: 1em;
                }

@media only screen and (min-width: 480px) {

html .fc .fc-toolbar.fc-header-toolbar {
                display: flex;
                align-items: center
        }

                html .fc .fc-toolbar.fc-header-toolbar .fc-left, html .fc .fc-toolbar.fc-header-toolbar .fc-right, html .fc .fc-toolbar.fc-header-toolbar .fc-center {
                    margin-bottom: 0;
                }

                    html .fc .fc-toolbar.fc-header-toolbar .fc-left, html .fc .fc-toolbar.fc-header-toolbar .fc-left > *, html .fc .fc-toolbar.fc-header-toolbar .fc-right, html .fc .fc-toolbar.fc-header-toolbar .fc-right > *, html .fc .fc-toolbar.fc-header-toolbar .fc-center, html .fc .fc-toolbar.fc-header-toolbar .fc-center > * {
                        margin: 0;
                        text-align: initial;
                    }

                html .fc .fc-toolbar.fc-header-toolbar .fc-left {
                    margin-right: auto;
                }
            }

@media only screen and (min-width: 660px) {
            }

@media only screen and (min-width: 770px) {
            }

@media only screen and (min-width: 1000px) {
            }

html .fc .fc-toolbar h2 {
            font-size: 21px;
            font-size: 1.5rem;
        }

html .fc .fc-state-default, html .fc .fc-state-default.fc-corner-left, html .fc .fc-state-default.fc-corner-right {
            display: inline-block;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            /* 不要記述削除 */
            float: none;
            height: auto;
            /*  */
            padding: 0.75em 1.5em;
            border: none;
            border-radius: calc(1em + 1em + 1em);
            box-shadow: 0 0 0 2px #083B71 inset;
            /*  */
            background-color: #083B71;
            background-image: none;
            color: #ffffff;
            font-size: 0.85em;
            line-height: 1;
            text-align: center;
            text-shadow: none;
            outline: none;
            transition: opacity 0.15s ease-in-out 0s,
            background 0.15s ease-in-out 0s,
            background-color 0.15s ease-in-out 0s,
            border-radius 0.15s ease-in-out 0s,
            border-color 0.15s ease-in-out 0s,
            color 0.15s ease-in-out 0s,
            box-shadow 0.15s ease-in-out 0s,
            text-shadow 0.15s ease-in-out 0s,
            outline 0.15s ease-in-out 0s;
        }

html .fc .fc-state-default:hover, html .fc .fc-state-default.fc-corner-left:hover, html .fc .fc-state-default.fc-corner-right:hover {
                background-color: transparent;
                color: #083B71;
            }

html .fc .fc-state-disabled, html .fc .fc-state-disabled:hover, html .fc .fc-state-disabled.fc-state-hover, html .fc .fc-state-disabled.fc-state-hover:hover {
            background-color: #161629;
            color: #ffffff;
        }

html .fc .fc-state-hover {
            background-position: initial;
        }

html .fc .fc-scroller {
            overflow-x: initial !important;
            overflow-y: initial !important;
            height: initial !important;
        }

html .fc.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
            float: none;
        }

html .fc.fc .fc-row .fc-content-skeleton table, html .fc.fc .fc-row .fc-content-skeleton td, html .fc.fc .fc-row .fc-helper-skeleton td {
            text-align: center;
            vertical-align: middle;
        }

/*
    margin-top: 20px;
    margin-bottom: 20px;
    */

.module-group .content > *, .module .content > *, .border-group .content > *, .border .content > *, .card-group .content > *, .card .content > *, .banner-group .content > *, .banner .content > * {
            display: block;
        }

.module-group .content > *:not(:last-child), .module .content > *:not(:last-child), .border-group .content > *:not(:last-child), .border .content > *:not(:last-child), .card-group .content > *:not(:last-child), .card .content > *:not(:last-child), .banner-group .content > *:not(:last-child), .banner .content > *:not(:last-child) {
            margin-bottom: 10px;
        }

.module-group .text, .module .text, .border-group .text, .border .text, .card-group .text, .card .text, .banner-group .text, .banner .text {
        line-height: 1;
    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

    }

@media only screen and (min-width: 1000px) {

    }


.module-group,
.border-group,
.card-group {
    margin: -10px;
}


.module-group > *, .border-group > *, .card-group > * {
        margin: 10px;
        box-sizing: border-box;
    }


@media only screen and (min-width: 480px) {


.module-group,
.border-group,
.card-group {
        display: flex;
        flex-wrap: wrap
}

        .module-group > *, .border-group > *, .card-group > * {
            flex: 1 0 40%;
        }
    }


@media only screen and (min-width: 660px) {

    }


@media only screen and (min-width: 770px) {
        .module-group > *, .border-group > *, .card-group > * {
            flex: 1 0 20%;
        }
    }


@media only screen and (min-width: 1000px) {

    }


/* モジュールのエリア */
/*----------------------------------------------------------------------------*/
.module-group > * > *, .module > * {
        display: block;
    }
.module-group > * > *:not(:last-child), .module > *:not(:last-child) {
        margin-bottom: 10px;
    }


/* ボーダーのエリア */
/*----------------------------------------------------------------------------*/
.border-group > *,
.border {
    border: 2px solid #213265;
    border-radius: 4px;
}
.border-group > * > *, .border > * {
        display: block;
    }
.border-group > * > *:not(:last-child), .border > *:not(:last-child) {
        margin-bottom: 10px;
    }
@media only screen and (min-width: 480px) {
    }
@media only screen and (min-width: 660px) {
    }
@media only screen and (min-width: 770px) {
.border-group > *,
.border {
        /* padding: 30px; */
}
    }
@media only screen and (min-width: 1000px) {
    }



/* カード(背景あり)のエリア */
/*----------------------------------------------------------------------------*/
.card-group > *,
.card {
    padding: 15px;
    background: #fff;
    color: #161629;
    display: flex;
    flex-direction: column;
    flex-basis: 0;
}
.card-group > * > *, .card > * {
        display: block;
    }
.card-group > * > *:not(:last-child), .card > *:not(:last-child) {
        margin-bottom: 10px;
    }
.card-group > * .thumbnail:first-child, .card .thumbnail:first-child {
        margin-top: -15px;
        margin-left: -15px;
        margin-right: -15px;
    }
.card-group > * .thumbnail:last-child, .card .thumbnail:last-child {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -15px;
    }
.card-group > * .card-footer, .card .card-footer {
        text-align: center;
        margin-top: auto;
    }
.card-group > *:not(.border), .card:not(.border) {
        border: 2px solid transparent;
    }
@media only screen and (min-width: 480px) {

    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {
.card-group > *,
.card {
        padding: 30px
}
        .card-group > * > *:not(:last-child), .card > *:not(:last-child) {
            margin-bottom: 30px;
        }
    }
@media only screen and (min-width: 1000px) {

    }


/* 枠線のエリア */
/*----------------------------------------------------------------------------*/
.banner-group > *:not(:last-child) {
        margin-bottom: 20px;
    }
.banner-group > *,
.banner {
    display: flex;
}
.banner-group > * > *, .banner > * {
        flex: 1 1 0%;
    }
.banner-group > * > .content, .banner > .content {
        padding: 15px;
        background: #fff;
    }
.banner-group > * > .content > *, .banner > .content > * {
            display: block;
        }
.banner-group > * > .content > *:not(:last-child), .banner > .content > *:not(:last-child) {
            margin-bottom: 10px;
        }


/* 横並びのナビゲーション */
/*----------------------------------------------------------------------------*/

.nav ul {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        padding-left: 0;
    }

.nav ul > li {
            margin: 0;
            padding: 0;
            line-height: 1;
            text-align: right;
            list-style-type: none;
        }

.nav ul > li a {
                display: flex;
                align-items: center;
                position: relative;
                box-sizing: border-box;
                padding: 10px 2px 11px;
                opacity: 1.0;
                line-height: 1;
            }

.nav ul > li a:not(.btn).active {
                    position: relative;
                    opacity: 1;
                    text-decoration: none !important;
                }

.nav ul > li a.btn {
                    justify-content: center;
                }

.nav ul > li + li {
                margin-left: 15px;
            }

.nav ul li.item a {
            color: #213265;
        }

.nav ul li.nav-nest {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 26px;
            padding: 0 15px;
            border: 1px solid #8d95ad;
            border: 1px solid rgba(141, 149, 173, 0.9);

            border-top: 0;
            border-bottom: 0;
        }

.nav ul li.nav-nest + li.nav-nest {
                border-left: 0;
                margin-left: 0;
            }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

    }

@media only screen and (min-width: 1000px) {

    }

.nav:not(.global-nav):not(.footer-nav) ul li a:not(.btn).active {
                position: relative;
                text-decoration: none !important;
            }

.nav:not(.global-nav):not(.footer-nav) ul li a:not(.btn).active::before {
                    display: block;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 4px;
                    background: #213265;
                    border-radius: 100em;
                    content: "";
                    pointer-events: none;
                    opacity: 1;
                }

.nav:not(.global-nav):not(.footer-nav) ul li a::before {
                display: block;
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 4px;
                background: #213265;
                border-radius: 100em;
                content: "";
                pointer-events: none;
                width: 0;
                opacity: 0.2;
                transition: all 150ms cubic-bezier(0.4, 0.0, 0.2, 1);
            }

.nav:not(.global-nav):not(.footer-nav) ul li a:hover {
                text-decoration: none !important;
            }

.nav:not(.global-nav):not(.footer-nav) ul li a:hover::before {
                    width: 100%;
                }



/* パンくずリスト */
/* https://getbootstrap.com/docs/4.1/components/breadcrumb/ */
/*----------------------------------------------------------------------------*/
.breadcrumb {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 140%;
}
.breadcrumb ol {
        display: flex;
        padding: 0;
        -webkit-transform-origin: top left;
                transform-origin: top left;
        -webkit-transform: scale(0.714);
                transform: scale(0.714);
        overflow: hidden;
    }
.breadcrumb ol li {
            display: block;
            margin: 0;
            font-size: 14px;
            line-height: 16px;
            white-space: nowrap;
        }
.breadcrumb ol li:last-child {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
.breadcrumb ol li + li::before {
            padding: 0 10px;
            font-size: 10px;
            display: inline-block;
            font-family: "original-icon-font";
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            vertical-align: baseline;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            -webkit-transform: scale(1);
            transform: scale(1);
            letter-spacing: 0;
            content: "\EA01";
        }
.breadcrumb ol .icon-home {
            font-size: 16px;
        }
@media only screen and (min-width: 660px) {
.breadcrumb {
        width: 100%
}

        .breadcrumb ol {
            -webkit-transform: scale(1);
                    transform: scale(1);
        }
    }
@media only screen and (min-width: 770px) {
    }
@media only screen and (min-width: 1000px) {
    }
@media only screen and (min-width: 1300px) {
    }




/* [課題] モーダル */
/* https://getbootstrap.com/docs/4.1/components/modal/ */
/*----------------------------------------------------------------------------*/


/* [課題] ポップオーバー、ツールチップス、ポップアップ */
/* https://getbootstrap.com/docs/4.1/components/popovers/ */
/* https://getbootstrap.com/docs/4.1/components/tooltips/ */
/* https://semantic-ui.com/modules/popup.html */
/*----------------------------------------------------------------------------*/


/* [課題] プログレス */
/* https://getbootstrap.com/docs/4.1/components/progress/ */
/* https://semantic-ui.com/modules/progress.html */
/*----------------------------------------------------------------------------*/
.progress {
    background: #dcdcdc;
    background: rgba(220, 220, 220, 0.5);
    min-height: 15px;
    /* border-radius: 4px; */
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}
.progress .progress-bar {
        background: #161629;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        font-size: 10px;
        line-height: 1;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

/* [課題] ローダー */
/* https://semantic-ui.com/elements/loader.html */
/*----------------------------------------------------------------------------*/


/* [課題] ステップ */
/* https://semantic-ui.com/elements/step.html */
/*----------------------------------------------------------------------------*/

.step-group {
    display: flex;
    position: relative;
}

.step-group .step {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        position: relative;
        max-width: 150px;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        cursor: default;
    }

.step-group .step .badge {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            position: relative;
            z-index: 1000;
            width: 24px;
            height: 24px;
            padding: 0;
            border-radius: 50%;
            box-shadow: 0 0 0 1px #777777 inset;
            background-color: #ffffff;
            color: #161629;
            font-weight: 700;
            font-size: 12px;
        }

.step-group .step .content {
            padding: 0 5px 0 10px;
            color: #161629;
            z-index: 10;
            background-color: #ffffff;
        }

.step-group .step .content .title {
                font-size: 14px;
            }

.step-group .step.active .badge {
                box-shadow: 0 0 0 1px #213265 inset;
                background-color: #213265;
                color: #ffffff;
            }

.step-group .step.active .content {
                color: #161629;
            }

.step-group .step:hover {
            opacity: 1.0;
        }

.step-group .step:not(:first-child)::before {
            display: inline-block;
            z-index: 0;
            width: 30px;
            height: 20px;
            margin-right: 3px;
            background-color: #fff;
            background-position: right;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url(../img/common/icon-long-arrow.svg);
            content: "";
        }

@media only screen and (min-width: 320px) {

.step-group .step:not(:first-child)::before {
                width: 40px
        }
            }

@media only screen and (min-width: 480px) {

.step-group .step:not(:first-child)::before {
                width: 73px
        }
            }

@media only screen and (min-width: 660px) {
        .step-group .step .badge {
            width: 28px;
            height: 28px;
            font-size: 16px;
        }

        .step-group .step .content {
            padding: 0 0 0 10px;
        }

            .step-group .step .content .title {
                font-size: 16px;
            }
    }



/* [課題] Accordion */
/*  https://semantic-ui.com/modules/accordion.html */
/*----------------------------------------------------------------------------*/


/* [課題] 検索できるドロップダウン */
/* https://semantic-ui.com/modules/dropdown.html */
/*----------------------------------------------------------------------------*/


/* [課題] Scrollspy */
/* https://getbootstrap.com/docs/4.1/components/scrollspy/ */
/*----------------------------------------------------------------------------*/







/* box */
/*----------------------------------------------------------------------------*/

.box {
    margin: 0;
    padding: 20px;
    width: 200px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    flex: 0 0 200px;
}

section.box {
        display: block;
    }

.box.box-img {
    padding: 0;
}
.box.box-text {
    width: auto;
    flex: 1 1 auto;
}

.box:not(.box-white) {
    background-color: #161629;
    color: #fff;
}

.box:not(.box-white) table,
        .box:not(.box-white) table thead,
        .box:not(.box-white) table tbody,
        .box:not(.box-white) table tfoot,
        .box:not(.box-white) table tr,
        .box:not(.box-white) table th,
        .box:not(.box-white) table td {
            border-color: #fff;
        }
.box.box-white {
    background-color: #fff;
    color: #161629;
}

@media only screen and (min-width: 480px) {
    .box-group {
        display: flex;
        /*
        & > .box {
            flex: 1 1 auto;
        }
        */
    }
}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {
    .box {
        padding: 20px;

    }
    .box-md {
        /* padding: 60px; */
        padding: 40px;
        width: 400px;
        min-height: 400px;
        flex: 0 0 400px;
    }
    .box-lg {
        /* padding: 100px; */
        padding: 80px;
        width: 600px;
        min-height: 400px;
        flex: 0 0 600px;
    }
    .box-fluid {
        width: auto;
        height: auto;
        padding: 80px;
        flex: 1 1 auto;
    }
}

/*
.box-title {
    height: auto;
}
*/

.box-text {
    min-height: 200px;
    height: auto;
}

.box-text .title {
        margin-bottom: 15px;
        line-height: 1;
    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

.box-text {
        max-width: 400px
}
    }

@media only screen and (min-width: 770px) {
        .box-text .title {
            margin-bottom: 25px;
            line-height: 1;
        }
    }

@media only screen and (min-width: 1000px) {

    }

@media only screen and (min-width: 1200px) {

    }

@media only screen and (min-width: 1400px) {

    }

@media only screen and (min-width: 1600px) {

    }


.box .btn {
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }


/* box */
/*----------------------------------------------------------------------------*/
.circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 0 0 2px #161629 inset;
    box-sizing: border-box;
}
@media only screen and (min-width: 480px) {

    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {
.circle {
        width: 160px;
        height: 160px
}
    }
@media only screen and (min-width: 1000px) {

    }
/* aタグの装飾をキャンセル */
html body .circle {
        border: none !important;
    }
/* ボタンのサイズ */
.circle.circle-lg {

    }
.circle.circle-sm {
        width: 70px;
        height: 70px;
    }
.circle,
    .circle.circle-fill {
        background-color: #083B71;
        color: #fff;
    }
a.circle:hover, a.circle.circle-fill:hover {
            background-color: transparent;
            color: #083B71;
        }
.circle.circle-outline {
        background-color: transparent;
        color: #083B71;
    }
a.circle.circle-outline:hover {
            background-color: #083B71;
            color: #fff;
        }
.circle.circle-skeleton {
        background-color: transparent;
        box-shadow: none;
        color: #161629;
    }
a.circle.circle-skeleton:hover {
            background-color: #000000;
            background-color: rgba(0, 0, 0, 0.2);
            color: #161629;
        }
.circle > span {
        display: block;
        text-align: center;
        line-height: 1.2;
    }
.circle > span span {
            display: inline-block;
        }
@media only screen and (min-width: 480px) {

        }
@media only screen and (min-width: 660px) {

        }
@media only screen and (min-width: 770px) {
.circle > span {
            line-height: 1.5
    }
        }
@media only screen and (min-width: 1000px) {

        }



















.m-notice {
    padding: 15px;
    margin-bottom: 40px;
}



















.m-notice .title {
        margin-bottom: 20px !important;
        font-size: 19px;
    }



















.m-notice :last-child {
        margin-bottom: 0;
    }



















.m-notice + p {
        margin-bottom: 80px;
    }



















@media only screen and (min-width: 340px) {



















.m-notice {
        padding: 25px
}
    }



















@media only screen and (min-width: 480px) {
    }



















@media only screen and (min-width: 660px) {
        .m-notice .title {
            font-size: 30px;
        }
    }



















@media only screen and (min-width: 770px) {



















.m-notice {
        padding: 40px 35px;
        margin-bottom: 40px
}
    }



















@media only screen and (min-width: 1000px) {
    }



.m-note {
    font-size: 12px;
}



.m-note .title {
        margin-bottom: 15px;
        line-height: 1;
    }



@media only screen and (min-width: 660px) {



.m-note {
        padding: 0 5px
}
    }
@media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {

}

/* 背景色 */
body {
    background: #ffffff;
}
.bg-white {
    background-color: #ffffff;
}
.bg-gray {
    background-color: #F4F5FA;
}
.bg-black {
    background-color: #161629;
    color: #ffffff;
}
.bg-black .module-group > a,
    .bg-black .border-group > a,
    .bg-black .card-group > a {
        color: #ffffff;
    }
.bg-black a.module,
    .bg-black a.border,
    .bg-black a.card {
        color: #ffffff;
    }
.bg-black .border-group > *,
    .bg-black .border {
        border-color: #ffffff;
    }

/* アニメーション */
/*----------------------------------------------------------------------------*/

a,
select,
button,
.btn,
.btn *,
input[type="submit"] {
    transition: all 250ms cubic-bezier(0.4, 0.0, 0.2, 1);
}

input,
textarea {
    transition: border 250ms cubic-bezier(0.4, 0.0, 0.2, 1);
}

.scroll-effect,
.parent-scroll-effect.list > * {
    /* transition: all 0.35s ease-out 0.25s; */
    /* transition: all 0.5s cubic-bezier(.4,0,.4,1) 0.4s; */
    transition: opacity 0.5s cubic-bezier(.4,0,.4,1) 0.4s,
                -webkit-transform 0.75s cubic-bezier(.4,0,.4,1) 0.2s;
    transition: transform 0.75s cubic-bezier(.4,0,.4,1) 0.2s,
                opacity 0.5s cubic-bezier(.4,0,.4,1) 0.4s;
    transition: transform 0.75s cubic-bezier(.4,0,.4,1) 0.2s,
                opacity 0.5s cubic-bezier(.4,0,.4,1) 0.4s,
                -webkit-transform 0.75s cubic-bezier(.4,0,.4,1) 0.2s;


}
.scroll-effect,
.parent-scroll-effect.list > * {
    -webkit-transform: none;
            transform: none;
    opacity: 1.0;
}
.scroll-effect.inactive,
.parent-scroll-effect.list > *.inactive {
    transition: none;
    -webkit-transform: translate(0, 20px);
            transform: translate(0, 20px);
    opacity: 0.0;
}

.scroll-effect.customize-body {
    transition-delay: 0.5s;
}

a .thumbnail,
a.thumbnail,
.thumbnail a,

a .thumbnail img,
a.thumbnail img,
.thumbnail a img,

a .thumbnail .mask,
a.thumbnail .mask,
.thumbnail a .mask {
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s,
                -webkit-transform 0.15s ease-in-out 0s;
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s,
                transform 0.15s ease-in-out 0s;
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s,
                transform 0.15s ease-in-out 0s,
                -webkit-transform 0.15s ease-in-out 0s;;
}

a:hover .thumbnail img,
a.thumbnail:hover img,
.thumbnail a:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 0.5;
}

a:hover .thumbnail .mask,
a.thumbnail:hover .mask,
.thumbnail a:hover .mask {

}
/*
.bx-wrapper .bx-viewport li {
    transition: all 0.1s ease 0s;
}
.bx-wrapper .bx-viewport li.active {
    transition: all 0.25s ease 0s;
}
*/
.bx-wrapper .bx-pager.bx-default-pager a {
    /* transition: all 0.15s ease 0.75s; */
}

/* 高さを画面幅に */
.scroll-effect.contain {
    box-sizing: border-box;
    position: relative;
}
body > .scroll-effect.contain:not(.parent-popup-content) {
    transition: all 0.75s ease-in-out;
}

.scroll-effect.contain .scroll-effect {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.parent-scroll-effect.parallax {
    position: relative;
}
.parent-scroll-effect.parallax > *:not(.not-move) {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}



/* カスタマイズ */
/*----------------------------------------------------------------------------*/
.mainvisual .slider li.active img {
    transition: -webkit-transform 12.0s ease-in 0s;
    transition: transform 12.0s ease-in 0s;
    transition: transform 12.0s ease-in 0s, -webkit-transform 12.0s ease-in 0s;
}
.mainvisual .slider li img {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
}
.mainvisual .slider li.active img {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
}

.slider li {
    transition: opacity 0.9s ease-in 0s;
}

.back-top {
    transition: margin 0.5s ease-in-out 0s,
                background 0.5s ease-in-out 0s,
                opacity 0.5s ease-in-out 0s,
                top 0.5s ease-in-out 0.25s,
                right 0.5s ease-in-out 0.25s,
                bottom 0.5s ease-in-out 0.25s,
                left 0.5s ease-in-out 0.25s;
}
.back-top.inactive {
    transition: margin 0.25s ease-in-out 0.25s,
                background 0.25s ease-in-out 0.25s,
                opacity 0.25s ease-in-out 0.25s,
                top 0.5s ease-in-out 1.0s,
                right 0.5s ease-in-out 1.0s,
                bottom 0.5s ease-in-out 1.0s,
                left 0.5s ease-in-out 1.0s;
}


.btn-responce {
    position: relative;
    overflow: hidden;
}

.btn-responce-animate {
    display: block;
    position: absolute;
    background: #213265;
    width: 235px;
    height: 235px;
    margin-top: -117.5px;
    margin-left: -117.5px;
    border-radius: 50%;
    opacity: 0.25;
    -webkit-animation: btn-responce 500ms ease-in forwards;
            animation: btn-responce 500ms ease-in forwards;
    pointer-events: none;
}

@-webkit-keyframes btn-responce {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    100% {
        -webkit-transform: scale(2.5);
                transform: scale(2.5);
    }
}

@keyframes btn-responce {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    100% {
        -webkit-transform: scale(2.5);
                transform: scale(2.5);
    }
}

@-webkit-keyframes img-01 {
    0%   {
        margin-left: 0;
    }
    100% {
        margin-left: -3%;
    }
}

@keyframes img-01 {
    0%   {
        margin-left: 0;
    }
    100% {
        margin-left: -3%;
    }
}


@-webkit-keyframes input-icon {
    0% {
        -webkit-transform: scale(0) rotate(-90deg);
                transform: scale(0) rotate(-90deg);
    }
    100% {
        -webkit-transform: scale(1) rotate(0);
                transform: scale(1) rotate(0);
    }
}


@keyframes input-icon {
    0% {
        -webkit-transform: scale(0) rotate(-90deg);
                transform: scale(0) rotate(-90deg);
    }
    100% {
        -webkit-transform: scale(1) rotate(0);
                transform: scale(1) rotate(0);
    }
}
body {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    overflow-x: hidden;
}

article,
.lead {
    margin-bottom: 48px;
}

@media only screen and (min-width: 660px) {

article,
.lead {
        margin-bottom: 40px
}
    }

main {
    box-sizing: border-box;

}

main .main-header {
        margin-bottom: 40px;
    }

@media only screen and (min-width: 480px) {

        }

@media only screen and (min-width: 660px) {

main .main-header {
            margin-bottom: 80px
    }
        }

@media only screen and (min-width: 770px) {

        }

@media only screen and (min-width: 1000px) {

main .main-header {
            margin-bottom: 120px
    }
        }

main .main-header + section {
                margin-top: 0;
                padding-top: 0;
            }

main .main-footer {
        margin-top: 120px;
    }

main .main-footer .container {
            display: flex;
            justify-content: center;
        }

main .main-footer .container > *:not(:last-child) {
                margin-right: 1em;
            }

main .main-footer .btn-group {
            width: 100%;
        }

main .main-footer .btn-group > *:not(:last-child) {
                margin-bottom: 0.5em;
            }

main .main-footer .btn-group .btn {
                width: 100%;
            }

@media only screen and (min-width: 480px) {
            main .main-footer .btn-group {
                display: flex;
                justify-content: center;
            }

                main .main-footer .btn-group > *:not(:last-child) {
                    margin-bottom: 0;
                    margin-right: 0.5em;
                }

                main .main-footer .btn-group .btn {
                    width: auto;
                    flex: 0 1 320px;
                }
        }

@media only screen and (min-width: 660px) {
        }

@media only screen and (min-width: 770px) {
        }

@media only screen and (min-width: 1000px) {

        }

@media only screen and (min-width: 660px) {

    main > section:last-child {
        margin-bottom: 120px;
    }
}

section {
    margin: 60px 0;

}

section .section-header {
        margin-bottom: 40px;
    }

@media only screen and (min-width: 770px) {

section .section-header {
            margin-bottom: 60px
    }
        }

section .section-content + .section-content {
        margin-top: 20px;
    }

section .section-footer {
        margin-top: 30px;
    }

section section:last-child {
        margin-bottom: 0;
    }

@media only screen and (min-width: 480px) {
    }

@media only screen and (min-width: 660px) {

section {
        margin: 80px 0

}

        section .section-footer {
            margin-top: 40px;
        }
    }

@media only screen and (min-width: 770px) {

section {
        /* padding: 120px 0; */

}
    }

@media only screen and (min-width: 1000px) {

    }


/* section 2階層目 */
article section,
section section {
    margin: 30px 0;
}
@media only screen and (min-width: 660px) {
article section,
section section {
        margin: 40px 0
}
    }
article section .section-header, section section .section-header {
        margin-bottom: 20px;
    }
@media only screen and (min-width: 480px) {
        }
@media only screen and (min-width: 660px) {

        }
@media only screen and (min-width: 770px) {
article section .section-header, section section .section-header {
            margin-bottom: 30px
    }
        }
@media only screen and (min-width: 1000px) {

        }
article section section:last-child, section section section:last-child {
        margin-bottom: 0;
    }

/* section 3階層目 */
article section section,
section section section {
    margin: 20px 0;
}
@media only screen and (min-width: 480px) {

    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {
article section section,
section section section {
        padding: 30px 0
}
    }
@media only screen and (min-width: 1000px) {

    }
article section section .section-header, section section section .section-header {
        margin-bottom: 10px;
    }
@media only screen and (min-width: 480px) {
            article section section .section-header .title, section section section .section-header .title {

            }
        }
@media only screen and (min-width: 660px) {

        }
@media only screen and (min-width: 770px) {
article section section .section-header, section section section .section-header {
            margin-bottom: 15px
    }
        }
@media only screen and (min-width: 1000px) {

        }
article section section section:last-child, section section section section:last-child {
        margin-bottom: 0;
    }


/*----------------------------------------------------------------------------*/
.container,
.container-harf,
.container-fluid {
    margin: 0 15px;
}
@media only screen and (min-width: 480px) {
    .container,
    .container-harf,
    .container-fluid {
        margin: 0 20px;
    }
}
@media only screen and (min-width: 660px) {
    .container,
    .container-harf,
    .container-fluid {

    }
    .container-sm {
        margin: 0 auto;
        width: 480px;
    }
}
@media only screen and (min-width: 770px) {
    .container-fluid {
        margin: 0 40px;
    }
    .container {
        margin: 0 auto;
        width: 730px;
    }
    .container-sm {
        margin: 0 auto;
        width: 480px;
    }
    .container-md {
        margin: 0 auto;
        width: 660px;
    }
    .container-lg {
        margin: 0 40px;
        width: auto;
    }
    .container-harf {

    }
}
@media only screen and (min-width: 1000px) {
    .container-fluid {
        margin: 0 80px;
    }
    .container {
        margin: 0 auto;
        width: 1000px;
    }
    .container-sm {
        margin: 0 auto;
        width: 480px;
    }
    .container-md {
        margin: 0 auto;
        width: 700px;
    }
    .container-lg {
        margin: 0 80px;
        width: auto;
    }
    .container-harf {

    }
}
@media only screen and (min-width: 1200px) {
}
@media only screen and (min-width: 1400px) {
}

.layout-group > *:not(:last-child) {
    margin-bottom: 20px;
}

.layout-group > * > *:not(:last-child), .layout > *:not(:last-child) {
        margin-bottom: 20px;
    }

@media only screen and (min-width: 480px) {

        }

@media only screen and (min-width: 660px) {

        }

@media only screen and (min-width: 770px) {

        }

@media only screen and (min-width: 1000px) {

        }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

.layout-group > *,
.layout {
        display: flex
}

        .layout-group > * > *, .layout > * {
            margin-top: 0;
            margin-bottom: 0;
            flex: 1 1 0%;
            width: auto;
        }
        .layout-group > * > *:not(:last-child), .layout > *:not(:last-child) {
            margin-bottom: 0;
            margin-right: 20px;
        }
    }

@media only screen and (min-width: 1000px) {

    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

    }

@media only screen and (min-width: 1000px) {

    }

@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 660px) {
}
@media only screen and (min-width: 770px) {
}
@media only screen and (min-width: 1000px) {
}

@media only screen and (min-width: 0px) and (max-width: 1199px) {
    body {
        padding-top: 50px;
    }
        html:not(.global-nav-active) .global-nav {
            -webkit-transform: scale(1.2);
                    transform: scale(1.2);
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
        }

    html.global-nav-active {
        overflow: hidden;
    }

        html.global-nav-active .global-nav {
            -webkit-transform: scale(1);
                    transform: scale(1);
            visibility: visible;
            opacity: 1;
            transition: all 250ms cubic-bezier(0.0, 0.0, 0.2, 1);
        }


    header {
        /* 共通 */
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 50000;
        box-sizing: border-box;
        width: 100%;
        /* スマホ */
        height: 50px;
        padding: 0 15px;
        box-shadow: 0 0 15px 0px rgba(0,0,0,0.16);
        background: #ffffff;
    }

        header .logo-nav {
            margin-top: 0;
            margin-bottom: 0;
        }

            header .logo-nav .logo {
                display: block;
                font-size: 20px;
                letter-spacing: -0.05em;
            }

                header .logo-nav .logo img {
                    display: block;
                    width: 200px;
                    height: 20px;
                }
        header .global-nav-btn {
            display: block;
            position: absolute;
            top: 0;
            right: 2px;
            bottom: 0;
            z-index: 1000;
            -webkit-transform: scale(0.8);
                    transform: scale(0.8);
            width: 50px;
            height: 50px;
        }
        header .global-nav {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow-x: auto;
            box-sizing: border-box;
            background: #ffffff;
            background: rgba(255,255,255,0.95);
        }

            header .global-nav > ul {
                padding: 50px 10px; /* firefoxで余白がつかない */
            }

            header .global-nav ul {
                flex-direction: column;
                justify-content: flex-start;
                flex: 1 0 auto;
                width: 230px;
            }

                header .global-nav ul .item {
                    width: 100%;
                }
                    header .global-nav ul .item a {
                        padding: 18px 15px;
                        font-weight: bold;
                        font-size: 1em;
                    }

                        header .global-nav ul .item a:not(.btn):hover {
                            background-color: #F4F5FA;
                        }

                header .global-nav ul .nav-nest {
                    position: relative;
                    width: 100%;
                    height: auto;
                    margin: 0;
                    padding: 0;
                    border: 1px solid #8d95ad;
                    border: 1px solid rgba(141, 149, 173, 0.9);

                    border-left: 0;
                    border-right: 0;
                }

                    header .global-nav ul .nav-nest a {
                        padding-left: 52px;
                    }

                     header .global-nav ul .nav-nest + .nav-nest {
                        border-top: 0;
                    }

                    header .global-nav ul .nav-nest.icon::before {
                        position: absolute;
                        top: 12px;
                        left: 15px;
                        z-index: 10;
                        font-size: 26px;
                    }

                header .global-nav ul li + li {
                    margin-left: 0;
                }
                header .global-nav ul li.login {
                    margin-top: 30px;
                }

                    header .global-nav ul li.login a {
                        min-width: 230px;
                        padding: 18px 3em;
                    }

                /* ハンバーガーメニューを上下中央寄せにする */
                header .global-nav ul > li:first-child {
                    margin-top: auto;
                }

                header .global-nav ul > li:last-child {
                    margin-bottom: auto;
                }
}

@media only screen and (min-width: 660px) and (max-width: 1199px) {

            header .global-nav ul {
                width: 480px;
            }

                header .global-nav ul .item a {
                    padding: 22px 20px;
                }

                header .global-nav ul .nav-nest a {
                    padding-left: 55px;
                }

                header .global-nav ul .nav-nest.icon::before {
                    top: 18px;
                    left: 20px;
                }
}
@media only screen and (min-width: 1200px) {
    body {
        padding-top: 90px;
    }
    header {
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 50000;
        box-sizing: border-box;
        width: 100%;
        height: 90px;
        padding-right: 20px;
        padding-left: 20px;
        box-shadow: 0 0 15px 0px rgba(0,0,0,0.16);
        background: #ffffff;
        font-weight: bold;
    }

        header .logo-nav {
            margin-top: 0;
            margin-bottom: 0;
        }

            header .logo-nav .logo {
                display: block;
                font-size: 30px;
                letter-spacing: -0.05em;
            }

                header .logo-nav .logo img {
                    display: block;
                    height: 30px;
                }
        header .global-nav-btn {
            display: none;
        }
        header .global-nav {
            display: block;
            margin-left: auto;
        }

            header .global-nav .logo-sp {
                display: none;
            }

            header .global-nav ul {
                width: 100%;
            }

                header .global-nav ul > li {
                    position: relative;
                }

                    header .global-nav ul > li a {
                        height: 90px;
                    }

                     header .global-nav ul > li + li {
                        margin-left: 20px;
                    }

                header .global-nav ul li.item a {
                    position: relative;
                    width: calc(100% + 20px);
                    padding: 10px 0px 11px;
                    color: #213265;
                    letter-spacing: 0.01em;
                }

                header .global-nav ul li.current::after {
                    position: absolute;
                    right: 0;
                    bottom: 20px;
                    left: 0;
                    width: 100%;
                    height: 4px;
                    border-radius: 100em;
                    background-color: #213265;
                    opacity: 0.2;
                    content: "";
                    pointer-events: none;
                }

                header .global-nav ul li.nav-nest {
                    padding: 0 20px;
                }

                    /* アイコン */
                    header .global-nav ul li.nav-nest::before {
                        margin-right: 20px;
                        font-size: 26px;
                    }
                        header .global-nav ul li.nav-nest.nav-recruitment::before {
                            color: #2C50A5;
                        }
                        header .global-nav ul li.nav-nest.nav-job-seeking::before {
                            color: #1a878e;
                        }
                    header .global-nav ul li.login .btn {
                        min-width: 110px;
                        height: 36px;
                        font-size: 14px;
                    }

                        header .global-nav ul li.login .btn::after {
                            content: none;
                        }

            header .global-nav .global-nav-line {
                position: absolute;
                bottom: 20px;
                height: 4px;
                border-radius: 100em;
                background-color: #213265;
                pointer-events: none;
                transition: all 250ms cubic-bezier(0.0, 0.0, 0.2, 1);
            }
}

@media only screen and (min-width: 1200px) and (max-width: 1270px) {
            header .global-nav li.item-home {
                position: absolute;
                overflow: hidden;
                visibility: hidden;
                width: 0;
            }
}




/* ハンバーガーメニューボタン */
.global-nav-btn {
    display: block;
    position: relative;
    width: 32px;
    height: 32px;
    padding: 0;
    border: none;
    cursor: pointer;
    transition: all 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
}
.global-nav-btn span {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 30px;
        height: 2px;
        margin: auto;
        border-radius: 1px;
        background: #083B71;
        transition: all 75ms cubic-bezier(0.4, 0.0, 0.2, 1);
        &:hover {
        transition: all 150ms cubic-bezier(0.4, 0.0, 0.2, 1);
    }
    }
.global-nav-btn span:nth-child(1) {
        -webkit-transform: translate(0px, -10px);
                transform: translate(0px, -10px);
    }
.global-nav-btn span:nth-child(4) {
        -webkit-transform: translate(0px, 10px);
                transform: translate(0px, 10px);
    }
@media only screen and (min-width: 480px) {
    }
@media only screen and (min-width: 660px) {
    }
@media only screen and (min-width: 770px) {
    }
@media only screen and (min-width: 1000px) {
.global-nav-btn {
        display: none
}
    }

html.global-nav-active .global-nav-btn {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }

html.global-nav-active .global-nav-btn span:nth-child(1) {
            -webkit-transform: translate(0px, 0);
                    transform: translate(0px, 0);
            opacity: 0;
        }

html.global-nav-active .global-nav-btn span:nth-child(4) {
            -webkit-transform: translate(0px, 0);
                    transform: translate(0px, 0);
            opacity: 0;
        }

html.global-nav-active .global-nav-btn span:nth-child(2) {
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
        }

html.global-nav-active .global-nav-btn span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
        }



.skip-link {
    position: absolute;
    top: 1em;
    left: 1em;
    z-index: 100000;
}



.skip-link:not(:focus) {
        overflow: hidden;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        white-space: nowrap;

        clip: rect(0 0 0 0);
        -webkit-clip-path: inset(50%);
                clip-path: inset(50%);
    }

@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 660px) {
}
@media only screen and (min-width: 770px) {
}
@media only screen and (min-width: 1000px) {
}

.l-footer {
    background-color: #161629;
}

.l-footer .bg-gray {
        background-color: #e0e0e5;
    }

.l-footer .contact-area {
        margin-bottom: 0;
        padding: 50px 0;
    }

.l-footer .contact-area .layout {
            align-items: center;
        }

.l-footer .contact-area .layout > * {
                flex: 1 1 auto;
            }

.l-footer .contact-area .m-contact-link {
            text-align: center;
            margin-bottom: 50px;
        }

.l-footer .contact-area .m-contact-link .text {
                margin: 30px 0;
            }

@media only screen and (min-width: 770px) {
            .l-footer .contact-area .m-contact-link {
                text-align: left;
                margin-bottom: 0;
            }
        }

.l-footer .footer-nav-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 28px 30px;
    }

.l-footer .footer-nav-area .logo-nav .logo {
                display: block;
            }

.l-footer .footer-nav-area .logo-nav .logo img {
                    display: block;
                    width: 330px;
                    max-width: 100%;
                    height: auto;
                }

.l-footer .footer-nav-area .nav .item a {
                color: #ffffff;
                font-size: 12px;
                padding: 10px 0px 11px;
            }

.l-footer .footer-nav-area .nav .nav-nest {
                padding: 0 20px;
            }

.l-footer .footer-copy-area {
        display: flex;
        align-items: center;
        justify-content: right;
        height: 90px;
        margin: 0 30px;
        text-align: right;

        border-top: 1px solid;
    }


.l-address-area {
    margin: 0;
    padding: 20px 15px;
    font-size: 14px;
}


.l-address-area a {
        color: #161629;
    }


.l-address-area .m-address {
        font-size: 14px;
        line-height: 1.5;
        margin-top: 20px;
    }


.l-address-area .m-address dt {
            font-weight: bold;
        }


.l-address-area .m-address dd {
        }


.l-address-area .m-text {
        margin-top: 20px;
        font-size: 12px;
        line-height: 1.3;
    }


@media only screen and (min-width: 770px) {


.l-address-area {
        width: 520px
}

        .l-address-area .m-address {
            font-size: 16px;
        }
    }

.copyright {
    width: 100%;
    font-size: 12px;
}

.copyright a {
        color: #ffffff;
    }



/* PCファースト */
@media only screen and (max-width: 1200px) {
    .l-footer .footer-nav-area {
        flex-direction: column;
        padding: 30px 15px;
    }

        .l-footer .footer-nav-area .logo-nav {
            margin-bottom: 15px;
        }

    .l-footer .footer-copy-area {
        text-align: center;
    }
}
@media only screen and (max-width: 770px) {
    .l-footer .footer-nav-area {
        flex-direction: column-reverse;
        padding: 0;
    }

        .l-footer .footer-nav-area .logo-nav {
            margin-bottom: 0;
            padding: 0 10px;
        }

        .l-footer .footer-nav-area .nav {
            width: 100%;
            margin-bottom: 40px;
        }

            .l-footer .footer-nav-area .nav ul {
                flex-direction: column;
            }

                .l-footer .footer-nav-area .nav ul .item {
                    width: 100%;

                    border-bottom: 1px solid rgba(141, 149, 173, 0.9);
                }
                    .l-footer .footer-nav-area .nav ul .item a {
                        display: flex;
                        justify-content: space-between;
                        padding: 18px;
                        font-weight: bold;
                        font-size: 1em;
                    }

                        .l-footer .footer-nav-area .nav ul .item a::after {
                            display: inline-block;
                            height: 10px;
                            color: #8d95ad;
                            color: rgba(141, 149, 173, 0.9);
                            font-size: 10px;
                            font-family: original-icon-font;
                            content: "\EA01";
                        }

                .l-footer .footer-nav-area .nav ul .nav-nest {
                    width: 100%;
                    height: auto;
                    margin: 0;
                    padding: 0;
                    border: 0;
                }

                    .l-footer .footer-nav-area .nav ul .nav-nest ul {
                        flex-direction: row;
                    }
                        .l-footer .footer-nav-area .nav ul .nav-nest ul li + li {
                            border-left: 1px solid rgba(141, 149, 173, 0.9);
                        }

                .l-footer .footer-nav-area .nav ul li + li {
                    margin-left: 0;
                }

    .l-footer .footer-copy-area {
        height: 60px;
        padding: 0 0 20px;

        border-top: 0;
    }

        .l-footer .footer-copy-area .copyright {
            font-size: 10px;
        }
}




/* footer */
/*----------------------------------------------------------------------------*/

.back-top {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 10000;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1.0;
    transition: opacity 0.5s ease-in-out 0s,
    -webkit-transform 0.5s ease-in-out 0s;
    transition: opacity 0.5s ease-in-out 0s,
    transform 0.5s ease-in-out 0s;
    transition: opacity 0.5s ease-in-out 0s,
    transform 0.5s ease-in-out 0s,
    -webkit-transform 0.5s ease-in-out 0s;
}

@media only screen and (min-width: 480px) {
    }

@media only screen and (min-width: 660px) {
    }

@media only screen and (min-width: 770px) {

.back-top {
        right: 40px;
        bottom: 40px
}
    }

@media only screen and (min-width: 1000px) {
    }

.back-top .btn {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 60px;
        height: 60px;
        padding: 10px;
        border: 1px solid #5a676b;
        border-radius: 50%;
        box-shadow: none;
        color: #5a676b;
        font-size: 10px;
        line-height: 1;
    }

.back-top .btn:not(.btn-sm)::before {
            display: none;
        }

.back-top .btn:not(.btn-sm)::after {
            position: static;
            -webkit-transform: none;
                    transform: none;
            /* margin: 0 auto 5px auto; */
            margin: auto;
            font-size: 24px;
        }

@media only screen and (min-width: 480px) {
        }

@media only screen and (min-width: 660px) {
        }

@media only screen and (min-width: 770px) {
            .back-top .btn:not(.btn-sm):hover {
                border-color: #3db5da;
                color: #3db5da;
            }

                .back-top .btn:not(.btn-sm):hover::after {
                    -webkit-transform: translate(0, -10px);
                            transform: translate(0, -10px);
                }
        }

@media only screen and (min-width: 1000px) {
        }

.back-top.inactive {
    -webkit-transform: translate(0, 200px);
            transform: translate(0, 200px);
    opacity: 0;
}
.back-top.mode-footer {
    position: absolute;
}
@media only screen and (min-width: 480px) {
    }
@media only screen and (min-width: 660px) {
    }
@media only screen and (min-width: 770px) {
    }
@media only screen and (min-width: 1000px) {
    }


.mainvisual {
    display: flex;
    align-items: normal;
    position: relative;
    box-sizing: border-box;
    min-height: 140px;
    padding: 15px 0;
}


.mainvisual .container {
        overflow: hidden;
    }


.mainvisual .title {
        display: flex;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
        width: 100%;
        height: 35px;
        margin: 0;
        line-height: 35px;
        text-align: center;
    }


.mainvisual .title .icon {
            height: 35px;
            margin-left: -15px;
            margin-right: 15px;
            font-size: 35px;
        }


@media only screen and (min-width: 480px) {


.mainvisual {
        min-height: 150px
}

        .mainvisual .title {
            font-size: 24px;
        }
    }


@media only screen and (min-width: 660px) {


.mainvisual {
        min-height: 190px;
        padding: 20px 0
}

        .mainvisual .title {
            font-size: 30px;
            height: 45px;
            line-height: 45px;
        }

            .mainvisual .title .icon {
                height: 45px;
                /* margin-left: -50px; */
                margin-right: 20px;
                font-size: 45px;
            }
    }


@media only screen and (min-width: 770px) {
    }


@media only screen and (min-width: 1000px) {
    }

body:not(.home) .mainvisual + section {
        padding-top: 0;
    }




.mainvisual {
    position: relative;
}




/* バグらないよう修正 */




.mainvisual .bxslider {
        height: 100%;
    }




.mainvisual .bxslider .slide {
            display: none;
        }




.mainvisual .bx-viewport {
        height: 100% !important;
    }




.mainvisual .bx-viewport .bxslider .slide {
            display: block;
        }




.mainvisual .bx-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 2;
        overflow: hidden;
        height: 560px;
        height: 100% !important;
        border: none;
        box-shadow: none;
        background: #E7F1FD;
    }




.mainvisual .bx-wrapper .bx-loading,
        .mainvisual .bx-wrapper .bx-prev,
        .mainvisual .bx-wrapper .bx-next {
            background: none;
        }




.mainvisual .slide {
        /* position: relative !important; */
        overflow: hidden;
        width: 100% !important;
        height: 100% !important;
    }




.mainvisual img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
        width: auto;
        max-width: unset;
        height: 100%;
    }




/* opacity: 0.58; */




@media only screen and (min-width: 1280px) {




.mainvisual img {
            width: 100%;
            max-width: 100%;
            height: auto
    }
        }




/* ページャー */




.mainvisual .bx-wrapper .bx-pager {
        position: absolute;
        bottom: 10px;
        width: 100%;
    }




.mainvisual .bx-wrapper .bx-pager.bx-default-pager a {
            width: 10px;
            height: 10px;
            margin: 0 5px;
            border-radius: 100%;
            background-color: #ffffff;
        }




.mainvisual .bx-wrapper .bx-pager.bx-default-pager a.active {
                background-color: #213265;
            }




@media only screen and (min-width: 660px) {




.mainvisual .bx-wrapper .bx-pager.bx-default-pager a {
                width: 15px;
                height: 15px;
                margin: 0 9px
        }
            }



/* ローディング */
.load-area {
    display: none;
    /* display: block; */
    /* background: #fff; */
    /* テスト用 */
    /* background: #fff !important; */
    /* opacity: 1 !important; */
    /* display: block !important; */
    /* テスト用 */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50000;
}

html.load-active body {
        background: #ffffff;
    }

html.load-active .load-area {
        display: block;
        width: 100%;
        height: 100%;
        background: #ffffff;
        transition: all 0.35s ease 0.0s;
    }

html.load-active .load-area .svg-load {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 50px;
            left: 0;
            width: 150px;
            margin: auto;
        }

@media only screen and (min-width: 480px) {
        }

@media only screen and (min-width: 660px) {
        }

@media only screen and (min-width: 770px) {
            html.load-active .load-area .svg-load {
                width: 200px;
            }
        }

@media only screen and (min-width: 1000px) {
        }

/* opacityだけだと、アニメーションが正常に動作しない場合がある */
html.load-active:not(.load-fadeout) {
}
html.load-fadeout .load-area {
        opacity: 0;
    }
html:not(.load-active) body {
        background: #ffffff;
    }
@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 660px) {
}
@media only screen and (min-width: 770px) {
}
@media only screen and (min-width: 1000px) {
}

.post-group > a:not(:last-child) {
        margin-bottom: 2em;
    }

.post-group > a {
        display: block;
        font-size: 16px;
    }

.post-group > a > *:not(:last-child) {
            margin-bottom: 10px;
        }

.post-group > a .title {
            font-weight: normal;
            font-size: 16px;
        }

.post-group > a .content > * {
                display: block;
            }

.post-group > a .content > *:not(:last-child) {
                margin-bottom: 0.5em;
            }

.post-group > a .content .date-area {
                display: flex;
                align-items: baseline;
            }

.post-group > a .content .date-area > *:not(:last-child) {
                    margin-right: 0.5em;
                }

@media only screen and (min-width: 480px) {
        }

@media only screen and (min-width: 660px) {
        }

@media only screen and (min-width: 770px) {
        }

@media only screen and (min-width: 1000px) {
        }

/* お知らせ */

.post-group ul {
        padding: 0;
    }

.post-group ul li {
            list-style-type: none;

            border-bottom: 1px solid #bababa;
        }

.post-group ul li a {
                display: flex;
                padding: 20px 0;
            }

.post-group ul li a .date-area {
                    margin-right: 20px;
                    color: #161629;
                }

.post-area {
    margin-bottom: 40px;
    table-layout: auto;
}

.post-area .post-group, .post-area .pagination {
        margin-bottom: 40px;
    }

@media only screen and (min-width: 480px) {
        }

@media only screen and (min-width: 660px) {
        }

@media only screen and (min-width: 770px) {

.post-area .post-group, .post-area .pagination {
            margin-bottom: 60px
    }
        }

@media only screen and (min-width: 1000px) {
        }

.post-area .post-group:first-child, .post-area .pagination:first-child {
            margin-top: 0;
        }

.post-area .post-group:last-child, .post-area .pagination:last-child {
            margin-bottom: 0;
        }

.post-area .clickable {
        cursor: pointer;
        transition: all 200ms cubic-bezier(0.0, 0.0, 0.2, 1);
        &:hover {
        transition: all 250ms cubic-bezier(0.4, 0.0, 0.2, 1);
    }
        &:active {
        transition: all 100ms cubic-bezier(0.4, 0.0, 1,   1);

        transition: background-color 75ms cubic-bezier(0.4, 0.0, 1,   1);
    }
    }

.post-area .clickable:hover {
            background: #F4F5FA;
            color: #083B71;
        }
/*
.recruitment .post-area .clickable:hover {
    background: $color-recruitment-gray;
}

.job-seeking .post-area .clickable:hover {
    background: $color-job-gray;
} */

.post-nav .title {
        display: block;
        margin-bottom: 10px;
    }
.post-nav-area {
    padding: 30px 0 30px;
}
.post-nav-area .title {
        text-align: center;
    }
.post-nav-area .post-nav:not(.last-child) {
        margin-bottom: 10px;
    }
.post-nav-area table {
        margin-top: 0;
        margin-bottom: 0;

        border-top: 0;
        border-bottom: 0;
        border-right: 0;
        border-left: 0;
    }
.post-nav-area table *:not(input):not(select) {
            border-right: 0;
            border-left: 0;
        }
.post-nav-area table tbody {
            border-top: 0;
            border-bottom: 0;
        }
.post-nav-area table th:first-child {
            width: 100%;
            padding: 15px 0 0;
        }
@media only screen and (min-width: 480px) {
.post-nav-area table th:first-child {
                width: 135px !important;
                padding: 0
        }
            }
.post-nav-area table tr:first-child {
            border-top: 0;
        }
.post-nav-area table tr:first-child th, .post-nav-area table tr:first-child td {
                border-top: 0;
            }
.post-nav-area table tr:last-child {
            border-bottom: 0;
        }
.post-nav-area table tr:last-child th, .post-nav-area table tr:last-child td {
                border-bottom: 0;
            }
.post-nav-area input, .post-nav-area select {
        max-width: 360px;
        min-height: 40px;
        border-color: #213265;
    }
.post-nav-area label {
        flex-direction: column;
        align-items: flex-start;
    }
/*
    input[type="radio"].before-icon, input[type="checkbox"].before-icon {
        & + .icon {
            // IE バグ border-boxを取ったため、widthとheightを少し小さくした
            width: 22px; // 24px
            height: 22px; // 24px
            border-color: $color-link;
            color: $color-link;
            font-size: 24px;
        }
    } */
.post-nav-area .field-radio, .post-nav-area .field-checkbox {
        margin: -5px;
        padding: 0;
    }
.post-nav-area .field-radio > label, .post-nav-area .field-checkbox > label {
            padding: 10px 0 10px 40px;
            color: #083B71;
        }
.post-nav-area .field-radio > label *, .post-nav-area .field-checkbox > label * {
                line-height: 24px;
            }
.post-nav-area .field-radio > label .icon, .post-nav-area .field-checkbox > label .icon {
                position: absolute;
                top: 5px;
                left: 20px;
                width: 22px;
                height: 22px;
                border-color: #213265;
            }
@media only screen and (min-width: 660px) {
.post-nav-area .field-radio > label, .post-nav-area .field-checkbox > label {
                padding: 5px 0 5px 40px
        }
            }
.post-nav-area input.before-icon:checked+.icon::before {
        content: "";
        background-size: 24px;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
    }
.post-nav-area input[type=radio].before-icon:checked+.icon::before {
        background-image: url(../img/common/icon-radio-checked.svg);
    }
.post-nav-area input[type=checkbox].before-icon:checked+.icon::before {
        background-image: url(../img/common/icon-checked.svg);
    }
.post-nav-area .field-checkbox-or > label:not(.invisible) ~ label {
            margin-top: 1em;
            position: relative;
        }
.post-nav-area .field-checkbox-or > label:not(.invisible) ~ label::before {
                content: "";
                display: block;
                position: absolute;
                top: -0.5em;
                left: 0;
                margin: 0 auto;
                width: 100%;
                height: 1px;
                font-size: 12px;
                line-height: 1;
                background: #8d95ad;
                background: rgba(141, 149, 173, 0.9);
            }
.post-nav-area .field-checkbox-or > label:not(.invisible) ~ label::after {
                content: "OR";
                display: block;
                position: absolute;
                top: -1em;
                left: -2px;
                right: 0;
                /* margin: 0 auto; */
                width: 3em;
                height: 1em;
                font-size: 12px;
                text-align: center;
                line-height: 1;
                background: #F4F5FA;
            }
.post-nav-area .post-nav-footer {
        margin-top: 10px;
    }
.post-nav-area .post-nav-footer .btn-group {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            flex-direction: column;
        }
.post-nav-area .post-nav-footer .btn-group .btn {
                margin: 0;
            }
.post-nav-area .post-nav-footer .btn-group .btn + .btn {
                    margin-top: 10px;
                }
.post-nav-area .post-nav-footer .btn-group .btn.btn-skeleton:hover {
                    background-color: #083B71;
                    color: #ffffff;
                }
@media only screen and (min-width: 480px) {
        .post-nav-area .field-checkbox > label .icon {
            top: 10px;
        }

        .post-nav-area label {
            flex-direction: row;
            align-items: center;
        }
    }
@media only screen and (min-width: 660px) {
.post-nav-area {
        padding: 40px 0 40px
}

        .post-nav-area table {
            margin-top: 20px;
        }

        .post-nav-area .field-checkbox > label .icon {
            top: 5px;
        }

        .post-nav-area .post-nav-footer {
            margin-top: 20px;
        }
    }
@media only screen and (min-width: 770px) {
    }
@media only screen and (min-width: 1000px) {
    }


article .article-header {
        margin-bottom: 30px;
    }


article .article-header .date {
            display: block;
            margin-bottom: 20px;
            text-align: right;
        }


article .article-header title {
            line-height: calc(1em + 10px);
        }


@media only screen and (min-width: 480px) {
        }


@media only screen and (min-width: 660px) {


article .article-header {
            margin-bottom: 40px
    }
        }


@media only screen and (min-width: 770px) {
            article .article-header .date {
                margin-bottom: 80px;
            }
        }


@media only screen and (min-width: 1000px) {
        }


@media only screen and (min-width: 1300px) {
        }


article .article-content p {
            margin: 1em 0;
        }


article .article-content img {
            display: block;
            margin: 1.5em auto;
        }


article .article-content table th, article .article-content table td {
                padding: 10px 20px;
            }


article .article-content table td {
                font-weight: normal;
                line-height: calc(1em + 10px);
                white-space: pre-line;
            }


article .article-content table td.white-space-unset {
                white-space: unset;
            }


@media only screen and (min-width: 480px) {
        }


@media only screen and (min-width: 660px) {


article .article-content {
            min-height: 80px;
            margin-bottom: 50px
    }
        }


@media only screen and (min-width: 770px) {
            article .article-content p {
                margin: 40px 0;
            }

            article .article-content img {
                display: block;
                margin: 40px auto;
            }
        }


@media only screen and (min-width: 1000px) {
        }


@media only screen and (min-width: 1300px) {
        }

.article-nav-area {
    margin: 30px 0px 60px;
    background: #ffffff;
}

.article-nav-area .article-nav {
        justify-content: space-between;
        width: 100%;
        border: 0;
        position: relative;
    }

.article-nav-area .article-nav * {
            min-width: 65px;
            border: 1px solid #213265;
            border-radius: 2px;
            text-align: center;
        }

.article-nav-area .article-nav *.page-numbers::before, .article-nav-area .article-nav *.page-numbers::after {
                color: #213265 !important;
            }

.article-nav-area .article-nav .prev.page-numbers {
            margin-right: auto;
        }

.article-nav-area .article-nav .top.page-numbers {
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
        }

.article-nav-area .article-nav .next.page-numbers {
            margin-left: auto;
        }

@media only screen and (min-width: 480px) {
    }

@media only screen and (min-width: 660px) {

.article-nav-area {
        margin: 50px 0px 80px
}
    }

@media only screen and (min-width: 770px) {
    }

@media only screen and (min-width: 1000px) {
    }


.archive table.post-area tr:not(:last-child) {
            margin-bottom: 0;
        }


.archive table.post-area td {
            min-height: 40px;
            padding: 10px;
            font-weight: normal;
            line-height: calc(1em + 10px);
        }


.archive table.post-area td.date {
                background: #F4F5FA;
            }


@media only screen and (min-width: 480px) {
            .archive table.post-area td {
                height: 60px;
                min-height: 60px;
                padding: 15px 10px;
                font-weight: normal;
                font-weight: 400;
                line-height: calc(1em + 10px);
            }

                .archive table.post-area td.date {
                    background: transparent;
                }

            .archive table.post-area .table-record {
                width: 120px;
                max-width: 120px;
            }

            .archive table.post-area .table-organization {
                width: 27.5%;
                max-width: 275px;
            }

            .archive table.post-area .table-business {
                width: 33.5%;
                max-width: 335px;
            }
        }



.tems * {
        line-height: 1.5;
    }



.tems ul li, .tems ol li {
        list-style-type: none;
        padding-left: 3px;
        text-indent: -24px;
    }



.tems ul li li, .tems ol li li {
        list-style-type: none;
        padding-left: 27px;
        text-indent: -29px;
    }



.tems ol ol {
        margin-top: .5em;
    }



.tems ol ol > li::before {
        display: none;
        margin-right: 0;
        content: "";
    }



.tems h2 {
        font-size: 18px;
        margin-bottom: 15px;
        margin-top: 40px;
    }



.tems h2:nth-of-type(1) {
            margin-top: 0;
        }



.tems dt {
        font-weight: bold;
        margin-bottom: 10px;
    }



@media only screen and (min-width: 480px) {
        .tems ul li, .tems ol li {
            list-style-type: none;
            padding-left: 3px;
            text-indent: -27px;
        }

        .tems ul li li, .tems ol li li {
            list-style-type: none;
            padding-left: 33px;
            text-indent: -34px;
        }
    }


@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 660px) {
}
@media only screen and (min-width: 770px) {
}
@media only screen and (min-width: 1000px) {
}

body.home main {
        margin-top: 0;
    }

body.home .mainvisual {
        position: relative;
        box-sizing: border-box;
        height: 900px;
        max-height: 435px;
        background-color: #5c6e87;
        background-image: url(../img/home/mainvisual-01.jpg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;
    }

body.home .mainvisual .mainvisual-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            position: relative;
            right: 0;
            left: 0;
            z-index: 1000;
            width: 100%;
            margin-bottom: 20px;
            color: #213265;
            font-weight: bold;
            pointer-events: none;
        }

/* bx-pagerとかぶってしまうため */

body.home .mainvisual .mainvisual-content .catch {
                font-size: 30px;
            }

body.home .mainvisual .mainvisual-content .catch-sub {
                margin-top: calc(1em - 5px);
                font-size: 20px;
            }

body.home .mainvisual img {
            -webkit-animation-name: img-01;
                    animation-name: img-01; /* 名前 */
            -webkit-animation-duration: 4400ms;
                    animation-duration: 4400ms; /* 時間 */
            -webkit-animation-timing-function: cubic-bezier(0.4, 0.0, 1,   1);
                    animation-timing-function: cubic-bezier(0.4, 0.0, 1,   1); /* アニメ */

            -webkit-animation-fill-mode: both;

                    animation-fill-mode: both; /* 実行前・実行後の状態 */
        }

@media only screen and (max-width: 339px) {

body.home .mainvisual {
            /* iphone 5 */
            max-height: 350px
    }

                body.home .mainvisual .mainvisual-content .catch {
                    font-size: 29px;
                }
        }

@media only screen and (max-width: 479px) {
            body.home .mainvisual .slide-01 img {
            }
            body.home .mainvisual .slide-02 img {
            }
            body.home .mainvisual .slide-03 img {
                left: 100%;
            }
            body.home .mainvisual .slide-04 img {
                left: 0;
            }
        }

@media only screen and (min-width: 480px) {

            body.home .mainvisual .mainvisual-content {
                text-align: left;
            }

                body.home .mainvisual .mainvisual-content .catch {
                    font-size: 40px;
                }
        }

@media only screen and (min-width: 660px) {

body.home .mainvisual {
            max-height: 560px
    }
                body.home .mainvisual .mainvisual-content .catch {
                    font-size: 60px;
                    letter-spacing: 0.035em;
                }
                body.home .mainvisual .mainvisual-content .catch-sub {
                    font-size: 30px;
                    letter-spacing: 0.045em;
                }

            body.home .mainvisual img {
                -webkit-animation-name: none;
                        animation-name: none;
            }
        }

@media only screen and (min-width: 770px) {
        }

@media only screen and (min-width: 1280px) {

body.home .mainvisual {
            height: calc(100vw * 560 / 1280);
            max-height: 700px
    }
        }

body.home .home-service-area .img {
            position: relative;
            z-index: 1000;
        }

body.home .home-service-area .content {
            position: relative;
            /* max-width: 60%; */
            box-sizing: border-box;
            padding: 30px;
        }

body.home .home-service-area .content .logo {
                display: block;
                width: 200px;
                margin-left: -15px;
            }

body.home .home-service-area .content .btn {
                display: inline-block;
                margin-top: 40px;
            }

@media only screen and (min-width: 480px) {
            body.home .home-service-area .layout:not(.layout-reverse) {
                display: block;
            }

                body.home .home-service-area .layout:not(.layout-reverse) > :not(:last-child) {
                    margin-right: 0;
                    margin-bottom: 30px;
                }
            body.home .home-service-area .img {
                /* margin-right: -20px; */
            }
        }

@media only screen and (min-width: 660px) {
            body.home .home-service-area .layout:not(.layout-reverse) {
                display: flex;
            }

                body.home .home-service-area .layout:not(.layout-reverse) > :not(:last-child) {
                    margin-right: 30px;
                    margin-bottom: 0;
                }
        }

@media only screen and (min-width: 770px) {
                body.home .home-service-area .layout:not(.layout-reverse) .img {
                    flex: 1 1 50%;
                    margin-right: -20px;
                }
                body.home .home-service-area .layout:not(.layout-reverse) .content {
                    flex: 1 1 50%;
                    margin-top: 50px;
                    margin-left: -20px;
                    padding: 40px 60px;
                }

                    body.home .home-service-area .layout:not(.layout-reverse) .content .btn {
                        display: inline-block;
                        margin-top: 40px;
                    }
        }

@media only screen and (min-width: 1000px) {
                body.home .home-service-area .layout:not(.layout-reverse) .img {
                    margin-right: -40px;
                }

                body.home .home-service-area .layout:not(.layout-reverse) .content {
                    margin-left: -40px;
                    padding: 50px 100px;
                }

                    body.home .home-service-area .layout:not(.layout-reverse) .content .logo {
                        width: 300px;
                        margin-left: -25px;
                    }

                    body.home .home-service-area .layout:not(.layout-reverse) .content .btn {
                        display: inline-block;
                        margin-top: 40px;
                    }
        }

@media only screen and (min-width: 1300px) {
            body.home .home-service-area .content {
                padding: 70px 130px;
            }

                body.home .home-service-area .content .btn {
                    display: inline-block;
                    margin-top: 80px;
                }
        }


.l-home-consept {
    text-align: center;
}


.l-home-consept .section-header {
        margin-bottom: 30px;
    }


@media only screen and (max-width: 340px) {


.l-home-consept .section-header {
            /* iphone 5 */
    }
            .l-home-consept .section-header .title {
                font-size: 18px;
            }
        }


@media only screen and (min-width: 660px) {


.l-home-consept .section-header {
            margin-bottom: 40px
    }
        }


.l-home-guide .module-front {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }


.l-home-guide .module-front .module-header {
            margin-bottom: 0;
        }


.l-home-guide .module-front .module-header .title {
                display: flex;
                align-items: center;
            }


.l-home-guide .module-front .btn {
            box-shadow: 0 0 0 2px #2C50A5 inset;
            background-color: #2C50A5;
            color: #ffffff;
        }


.l-home-guide .module-front .btn:hover {
                background-color: transparent;
                color: #2C50A5;
            }


.l-home-guide .module-front .btn.btn-lg {
                width: 100%;
            }


.l-home-guide .module-front .bg-gray {
            margin-right: -20px;
            margin-left: -20px;
            padding: 30px 20px;
            background-color: #e9ecf4;
            text-align: center;
        }


.l-home-guide .module-front .bg-gray p {
                margin-bottom: 25px;
                text-align: left;
            }


.l-home-guide .module-front .bg-gray .btn {
                width: 100%;
                max-width: 230px;
            }


@media only screen and (min-width: 660px) {


.l-home-guide .module-front .bg-gray {
                margin-right: 0;
                margin-left: 0;
                padding: 25px
        }

                .l-home-guide .module-front .bg-gray .btn {
                    max-width: 300px;
                }
            }


.l-home-guide .module-front .content {
            height: 100%;
            padding: 30px 20px 10px;
            border: 2px solid #ccd4e6;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;

            border-top: 0;
        }


.l-home-guide .module-front .content .text {
                margin-bottom: 30px;
                font-weight: bold;
                line-height: calc(1em + 10px);
            }


.l-home-guide .module-front .content .text + .btn {
                    margin: 30px auto;
                }


.l-home-guide .module-front + .module-front .module-header {
                background-color: #1a878e;
            }


.l-home-guide .module-front + .module-front .btn {
                box-shadow: 0 0 0 2px #1a878e inset;
                background-color: #1a878e;
                color: #ffffff;
            }


.l-home-guide .module-front + .module-front .btn:hover {
                    background-color: transparent;
                    color: #1a878e;
                }


.l-home-guide .module-front + .module-front .bg-gray {
                background-color: #E9F1F7;
            }


@media only screen and (min-width: 480px) {
    }


@media only screen and (min-width: 660px) {
        .l-home-guide .content {
            padding: 30px 20px;
        }
    }


@media only screen and (min-width: 770px) {

                .l-home-guide .module-front .content .text {
                    margin-bottom: 20px;
                    font-size: 18px;
                    line-height: 30px;
                }


                     .l-home-guide .module-front .content .text + .btn {
                        margin: 20px 0;
                    }
    }


@media only screen and (min-width: 1000px) {
            .l-home-guide .module-front .content {
                padding: 35px 40px 20px;
            }

                .l-home-guide .module-front .content .text {
                    margin-bottom: 35px;
                }

                     .l-home-guide .module-front .content .text + .btn {
                        margin: 35px 0 40px;
                    }
    }



.l-home-news .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
    }



.l-home-news .section-header h1 {
            margin: 0;
        }



.l-home-news .section-header .btn {
            min-width: auto;
            max-width: 130px;
            padding: 0.75em 1.5em;
            font-size: 14px;
        }



.l-home-news .newarrival-joboffer .post-group a {
                border: 3px solid #2c4c99;
                color: #2c4c99;
                font-weight: bold;
                background-color: #eaedfb;
            }



.l-home-news .newarrival-joboffer .post-group a .title {
                    font-weight: bold;
                }



.l-home-news .newarrival-joboffer .post-group a:hover {
                    background-color: #cfd7fa;
                }



.l-home-news .post-group {
        padding: 0;
    }



@media only screen and (max-width: 659px) {



.l-home-news .post-group {
            margin-bottom: 15px
    }
        }



.l-home-news .post-group a {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            box-sizing: border-box;
            width: 100%;
            height: 120px;
            padding: 20px;
            border-radius: 4px;
            background-color: #F4F5FA;
            color: #213265;
        }



.l-home-news .post-group a:hover {
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                background-color: #e9ecf4;
            }



.l-home-news .post-group .title {
            display: -webkit-box;
            overflow: hidden;
            width: 100%;
            height: 100%;
            margin-bottom: 0;
            font-weight: normal;
            -webkit-line-clamp: 2;
        }



.l-home-news .post-group .date-area {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            font-size: 13px;
        }



.l-home-news .post-group + .post-group {
            margin-top: 16px;
        }



@media only screen and (min-width: 480px) {
    }



@media only screen and (min-width: 660px) {

        .l-home-news .section-header .btn {
            width: 100%;
            max-width: 200px;
            padding: 18px 3em;
            font-size: 16px;
        }

        .l-home-news .content {
            display: flex;
            flex-wrap: wrap;
            margin: -10px;
        }

        .l-home-news .post-group {
            display: inline-block;
            width: calc( (100% / 2) - 20px);
            margin: 10px;
            padding: 0;
        }

            .l-home-news .post-group a {
                padding: 30px 20px;
            }

            .l-home-news .post-group .title {
                line-height: 27px;
            }

            .l-home-news .post-group .date-area {
                margin-bottom: 25px;
            }

             .l-home-news .post-group + .post-group {
                margin-top: 10px;
            }
    }



@media only screen and (min-width: 770px) {
    }



@media only screen and (min-width: 1000px) {
        .l-home-news .post-group {
            width: calc( (1000px / 4) - 15px) !important;
        }

            .l-home-news .post-group a {
                height: 180px;
            }

             .l-home-news .post-group + .post-group {
                margin-top: 10px;
            }
    }



@media only screen and (min-width: 1400px) {
    }





.module-header {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 72px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #2C50A5;
}





.module-header .content {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }





.module-header .title {
        color: #ffffff;
    }





@media only screen and (min-width: 660px) {





.module-header {
        min-height: 100px
}
    }

@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 660px) {
}
@media only screen and (min-width: 770px) {
}
@media only screen and (min-width: 1000px) {
}


.login .m-notice .title {
            text-align: center;
        }


@media only screen and (min-width: 660px) {


.login .m-notice .title {
                margin-bottom: 40px !important
        }
            }


.l-guide {
    margin-top: 0;
    margin-bottom: 80px;
}


.l-guide .card {
        padding: 25px;
    }


.l-guide .card .card-header {
            text-align: center;
            margin-bottom: 20px;
        }


@media only screen and (min-width: 770px) {
        .l-guide .card {
            min-height: 290px;
            padding: 35px 30px;
        }

            .l-guide .card .card-header {
                margin-bottom: 35px;
            }

    }


@media only screen and (max-width: 479px) {
        .about .mainvisual .title {
            font-size: 16px;
        }
    }



.news .post-nav-area {
        margin-top: 0;
        padding-top: 0;
    }



.news .post-nav-area .nav ul>li a {
            padding-top: 0;
        }



.news .post-area {
        margin-top: 0;
    }



/* お知らせ */



.news .post-group ul {
            padding: 0;
        }



.news .post-group ul li {
                list-style-type: none;
                margin: 0;
                border-bottom: 1px solid #bababa;
            }



.news .post-group ul li a {
                    display: flex;
                    padding: 20px 0;
                }



.news .post-group ul li a .date-area {
                        margin-right: 20px;
                        color: #161629;
                    }



.news .post-group ul li a .title {
                        line-height: calc(1em + 10px);
                    }


.terms section {
        margin-top: 0;
    }


.job-sheeking {

}



/* 確認画面 confirm */
/*----------------------------------------------------------------------------*/

body.confirm table th, body.confirm table td {
            padding: 10px 20px;
        }

body.confirm table td {
            padding: 20px;
            font-size: 16px;
            font-weight: normal;
        }

body.confirm .field {
        line-height: calc(1em + 10px);
    }

body.confirm .field-checkbox,
    body.confirm .field-radio {
        margin: inherit;
        padding: 0;
    }

body.confirm .field-note {
        padding: 0;
        margin-bottom: 10px;
    }