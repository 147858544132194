h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    line-height: $line-height-title;
    /* margin-top: 2em;
    margin-bottom: 1em; */
}

h1 { font-size: 20px; }
h2 { font-size: 18px; }
h3 { font-size: 14px; }
h4 { font-size: 14px; }
h5 { font-size: 14px; }
h6 { font-size: 14px; }

@media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 660px) {
    h1 { font-size: 30px; }
    h2 { font-size: 26px; }
    h3 { font-size: 16px; }
    h4 { font-size: 14px; }
    h5 { font-size: 14px; }
    h6 { font-size: 14px; }
}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {

}


.title {
    font-weight: bold;
    line-height: $line-height-text;

    span {
        display: inline-block;
    }

    .title-small {
        font-size: calc(1em - 0.2em);
    }

    &.icon::before {
        margin-right: 15px;
        font-size: calc(1em + 15px);
        vertical-align: bottom;
    }

    @media only screen and (min-width: 660px) {
        line-height: 1;
    }
}


.title-01 {
    font-size: 20px;
    letter-spacing: 0.05em;

    @media only screen and (min-width: 660px) {
        font-size: 30px;
    }
}

.title-02 {
    font-size: 18px;

    @media only screen and (min-width: 660px) {
        font-size: 26px;
    }
}

.title-03 {
    font-size: 14px;

    @media only screen and (min-width: 660px) {
        font-size: 16px;
    }
}
