/*
アイコンのsvgファイルを以下のディレクトリに保存するとアイコンフォントとして使用可能。
1. /src/font/svg/ に.svgファイルを保存
2. gulpを実行
3. 保存したファイル名が「icon-arrow.svg」の場合、
　 「<i class="icon icon-arrow"></i>」でアイコンが表示される

上記、処理が「03-mixin-pre-consolidate.css」内に記述。
*/

/*
.icon-arrow::before {
    @mixin icon;
}
*/





/* 以下、フォント以外のアイコン */
/*----------------------------------------------------------------------------*/

.icon-user {
    color: $color-job;
}

.icon-building {
    color: $color-recruitment;
}


.icon-arrow-animation {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;

    span {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 24px;
        height: 2px;
        margin: auto;
        background: #000000;
        transition: all 0.15s ease-in-out 0s;
    }
}
.icon-arrow-animation {
    span:nth-child(1), span:nth-child(2) {
        transform-origin: 100% 50%;
        width: 12px;
        margin-right: 3px;
    }
    span:nth-child(1) {
        transform: rotate(45deg);
    }
    span:nth-child(2) {
        transform: rotate(-45deg);
    }
}
.icon-arrow-animation:hover {
    span:nth-child(1), span:nth-child(2) {
        transform-origin: 100% 50%;
        width: 12px;
        margin-right: -7px;
    }
    span:nth-child(3) {
        box-shadow: 10px 0 0 0 #111111;
    }
}

html .loading {
    position: relative;
    color: transparent !important;
    pointer-events: none;

    & *, & *::before, & *::after {
        color: transparent !important;
        pointer-events: none;
    }
    & > * {
        opacity: 0.5 !important;
    }

    &::before, &::after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        width: 1rem;
        height: 1rem;
        margin: auto;
        border-radius: 0.5rem;
        content: "";
    }

    &::before {
        z-index: 900;
        border: 2px solid rgba(0, 0, 0, 0.5);
    }
    &::after {
        z-index: 1000;
        border: 2px solid rgba(255, 255, 255, 1.0);
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
        animation: icon-load-animation 1.0s linear 0s infinite;
    }

    ::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0);
    }
    ::-moz-placeholder {
        opacity: 1;
        color: rgba(0, 0, 0, 0);
    }
    :-ms-input-placeholder {
        color: rgba(0, 0, 0, 0);
    }
    :input-placeholder {
        color: rgba(0, 0, 0, 0);
    }
}

@keyframes icon-load-animation {
    0% { transform: rotate(0deg); }
    40% { transform: rotate(360deg); }
    100% { transform: rotate(720deg); }
}