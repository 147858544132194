/* アニメーション */
/*----------------------------------------------------------------------------*/

a,
select,
button,
.btn,
.btn *,
input[type="submit"] {
    transition: all 250ms $easingStandard;
}

input,
textarea {
    transition: border 250ms $easingStandard;
}

.scroll-effect,
.parent-scroll-effect.list > * {
    // transition: all 0.35s ease-out 0.25s;
    // transition: all 0.5s cubic-bezier(.4,0,.4,1) 0.4s;
    transition: transform 0.75s cubic-bezier(.4,0,.4,1) 0.2s,
                opacity 0.5s cubic-bezier(.4,0,.4,1) 0.4s;


}
.scroll-effect,
.parent-scroll-effect.list > * {
    transform: none;
    opacity: 1.0;
}
.scroll-effect.inactive,
.parent-scroll-effect.list > *.inactive {
    transition: none;
    transform: translate(0, 20px);
    opacity: 0.0;
}

.scroll-effect.customize-body {
    transition-delay: 0.5s;
}

a .thumbnail,
a.thumbnail,
.thumbnail a,

a .thumbnail img,
a.thumbnail img,
.thumbnail a img,

a .thumbnail .mask,
a.thumbnail .mask,
.thumbnail a .mask {
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s,
                transform 0.15s ease-in-out 0s;;
}

a:hover .thumbnail img,
a.thumbnail:hover img,
.thumbnail a:hover img {
    transform: scale(1.1);
    opacity: 0.5;
}

a:hover .thumbnail .mask,
a.thumbnail:hover .mask,
.thumbnail a:hover .mask {

}
/*
.bx-wrapper .bx-viewport li {
    transition: all 0.1s ease 0s;
}
.bx-wrapper .bx-viewport li.active {
    transition: all 0.25s ease 0s;
}
*/
.bx-wrapper .bx-pager.bx-default-pager a {
    // transition: all 0.15s ease 0.75s;
}

/* 高さを画面幅に */
.scroll-effect.contain {
    box-sizing: border-box;
    position: relative;
}
body > .scroll-effect.contain:not(.parent-popup-content) {
    transition: all 0.75s ease-in-out;
}

.scroll-effect.contain .scroll-effect {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.parent-scroll-effect.parallax {
    position: relative;
}
.parent-scroll-effect.parallax > *:not(.not-move) {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}



/* カスタマイズ */
/*----------------------------------------------------------------------------*/
.mainvisual .slider li.active img {
    transition: transform 12.0s ease-in 0s;
}
.mainvisual .slider li img {
    transform: scale(1.0);
}
.mainvisual .slider li.active img {
    transform: scale(1.25);
}

.slider li {
    transition: opacity 0.9s ease-in 0s;
}

.back-top {
    transition: margin 0.5s ease-in-out 0s,
                background 0.5s ease-in-out 0s,
                opacity 0.5s ease-in-out 0s,
                top 0.5s ease-in-out 0.25s,
                right 0.5s ease-in-out 0.25s,
                bottom 0.5s ease-in-out 0.25s,
                left 0.5s ease-in-out 0.25s;
}
.back-top.inactive {
    transition: margin 0.25s ease-in-out 0.25s,
                background 0.25s ease-in-out 0.25s,
                opacity 0.25s ease-in-out 0.25s,
                top 0.5s ease-in-out 1.0s,
                right 0.5s ease-in-out 1.0s,
                bottom 0.5s ease-in-out 1.0s,
                left 0.5s ease-in-out 1.0s;
}


.btn-responce {
    position: relative;
    overflow: hidden;
}

.btn-responce-animate {
    display: block;
    position: absolute;
    background: $color-link;
    width: 235px;
    height: 235px;
    margin-top: -117.5px;
    margin-left: -117.5px;
    border-radius: 50%;
    opacity: 0.25;
    animation: btn-responce 500ms ease-in forwards;
    pointer-events: none;
}

@keyframes btn-responce {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(2.5);
    }
}

@keyframes img-01 {
    0%   {
        margin-left: 0;
    }
    100% {
        margin-left: -3%;
    }
}


@keyframes input-icon {
    0% {
        transform: scale(0) rotate(-90deg);
    }
    100% {
        transform: scale(1) rotate(0);
    }
}