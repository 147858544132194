@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 660px) {
}
@media only screen and (min-width: 770px) {
}
@media only screen and (min-width: 1000px) {
}

.post-group {
    & > a:not(:last-child) {
        margin-bottom: 2em;
    }
    & > a {
        display: block;
        font-size: 16px;

        & > *:not(:last-child) {
            margin-bottom: 10px;
        }


        .title {
            font-weight: normal;
            font-size: 16px;
        }

        .content {
            & > * {
                display: block;
            }
            & > *:not(:last-child) {
                margin-bottom: 0.5em;
            }
            .date-area {
                display: flex;
                align-items: baseline;

                & > *:not(:last-child) {
                    margin-right: 0.5em;
                }
            }
        }

        @media only screen and (min-width: 480px) {
        }
        @media only screen and (min-width: 660px) {
        }
        @media only screen and (min-width: 770px) {
        }
        @media only screen and (min-width: 1000px) {
        }
    }


    // お知らせ
    ul {
        padding: 0;

        li {
            list-style-type: none;

            border-bottom: 1px solid #bababa;

            a {
                display: flex;
                padding: 20px 0;

                .date-area {
                    margin-right: 20px;
                    color: $color-black;
                }
            }
        }
    }
}

.post-area {
    margin-bottom: 40px;
    table-layout: auto;

    .post-group, .pagination {
        margin-bottom: 40px;

        @media only screen and (min-width: 480px) {
        }
        @media only screen and (min-width: 660px) {
        }
        @media only screen and (min-width: 770px) {
            margin-bottom: 60px;
        }
        @media only screen and (min-width: 1000px) {
        }

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    .clickable {
        cursor: pointer;
        @mixin mix-transition-btn;

        &:hover {
            background: $color-bg-gray;
            color: $color-btn;
        }
    }
}
/*
.recruitment .post-area .clickable:hover {
    background: $color-recruitment-gray;
}

.job-seeking .post-area .clickable:hover {
    background: $color-job-gray;
} */

.post-nav {
    .title {
        display: block;
        margin-bottom: 10px;
    }
}
.post-nav-area {
    padding: 30px 0 30px;

    .title {
        text-align: center;
    }

    .post-nav:not(.last-child) {
        margin-bottom: 10px;
    }

    table {
        margin-top: 0;
        margin-bottom: 0;

        border-top: 0;
        border-bottom: 0;
        border-right: 0;
        border-left: 0;

        *:not(input):not(select) {
            border-right: 0;
            border-left: 0;
        }

        tbody {
            border-top: 0;
            border-bottom: 0;
        }

        th:first-child {
            width: 100%;
            padding: 15px 0 0;

            @media only screen and (min-width: 480px) {
                width: 135px !important;
                padding: 0;
            }
        }

        tr:first-child {
            border-top: 0;
            th, td {
                border-top: 0;
            }
        }

        tr:last-child {
            border-bottom: 0;
            th, td {
                border-bottom: 0;
            }
        }
    }

    input, select {
        max-width: 360px;
        min-height: 40px;
        border-color: $color-link;
    }

    label {
        flex-direction: column;
        align-items: flex-start;
    }
    /*
    input[type="radio"].before-icon, input[type="checkbox"].before-icon {
        & + .icon {
            // IE バグ border-boxを取ったため、widthとheightを少し小さくした
            width: 22px; // 24px
            height: 22px; // 24px
            border-color: $color-link;
            color: $color-link;
            font-size: 24px;
        }
    } */

    .field-radio, .field-checkbox {
        margin: -5px;
        padding: 0;

        & > label {
            padding: 10px 0 10px 40px;
            color: $color-btn;

            * {
                line-height: 24px;
            }

            .icon {
                position: absolute;
                top: 5px;
                left: 20px;
                width: 22px;
                height: 22px;
                border-color: $color-link;
            }

            @media only screen and (min-width: 660px) {
                padding: 5px 0 5px 40px;
            }
        }
    }

    input.before-icon:checked+.icon::before {
        content: "";
        background-size: 24px;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
    }

    input[type=radio].before-icon:checked+.icon::before {
        background-image: url(../img/common/icon-radio-checked.svg);
    }

    input[type=checkbox].before-icon:checked+.icon::before {
        background-image: url(../img/common/icon-checked.svg);
    }

    .field-checkbox-or {
        & > label:not(.invisible) ~ label {
            margin-top: 1em;
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: -0.5em;
                left: 0;
                margin: 0 auto;
                width: 100%;
                height: 1px;
                font-size: 12px;
                line-height: 1;
                background: $color-border;
            }
            &::after {
                content: "OR";
                display: block;
                position: absolute;
                top: -1em;
                left: -2px;
                right: 0;
                // margin: 0 auto;
                width: 3em;
                height: 1em;
                font-size: 12px;
                text-align: center;
                line-height: 1;
                background: $color-bg;
            }
        }
    }

    .post-nav-footer {
        margin-top: 10px;

        .btn-group {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            flex-direction: column;

            .btn {
                margin: 0;

                + .btn {
                    margin-top: 10px;
                }

                &.btn-skeleton:hover {
                    background-color: $color-btn;
                    color: $color-white;
                }
            }
        }
    }

    @media only screen and (min-width: 480px) {
        .field-checkbox > label .icon {
            top: 10px;
        }

        label {
            flex-direction: row;
            align-items: center;
        }
    }
    @media only screen and (min-width: 660px) {
        padding: 40px 0 40px;

        table {
            margin-top: 20px;
        }

        .field-checkbox > label .icon {
            top: 5px;
        }

        .post-nav-footer {
            margin-top: 20px;
        }
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
    }
}


article {
    .article-header {
        margin-bottom: 30px;

        .date {
            display: block;
            margin-bottom: 20px;
            text-align: right;
        }

        title {
            line-height: $line-height-text;
        }

        @media only screen and (min-width: 480px) {
        }
        @media only screen and (min-width: 660px) {
            margin-bottom: 40px;
        }
        @media only screen and (min-width: 770px) {
            .date {
                margin-bottom: 80px;
            }
        }
        @media only screen and (min-width: 1000px) {
        }
        @media only screen and (min-width: 1300px) {
        }
    }

    .article-content {

        p {
            margin: 1em 0;
        }

        img {
            display: block;
            margin: 1.5em auto;
        }

        table {
            th, td {
                padding: 10px 20px;
            }

            td {
                font-weight: normal;
                line-height: $line-height-text;
                white-space: pre-line;
            }

            td.white-space-unset {
                white-space: unset;
            }

        }


        @media only screen and (min-width: 480px) {
        }
        @media only screen and (min-width: 660px) {
            min-height: 80px;
            margin-bottom: 50px;
        }
        @media only screen and (min-width: 770px) {
            p {
                margin: 40px 0;
            }

            img {
                display: block;
                margin: 40px auto;
            }
        }
        @media only screen and (min-width: 1000px) {
        }
        @media only screen and (min-width: 1300px) {
        }
    }
}

.article-nav-area {
    margin: 30px 0px 60px;
    background: $color-white;

    .article-nav {
        justify-content: space-between;
        width: 100%;
        border: 0;
        position: relative;

        * {
            min-width: 65px;
            border: 1px solid $color-link;
            border-radius: 2px;
            text-align: center;

            &.page-numbers::before, &.page-numbers::after {
                color: $color-link !important;
            }
        }

        .prev.page-numbers {
            margin-right: auto;
        }
        .top.page-numbers {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
        .next.page-numbers {
            margin-left: auto;
        }
    }

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
        margin: 50px 0px 80px;
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
    }
}


.archive {
    table.post-area {

        tr:not(:last-child) {
            margin-bottom: 0;
        }

        td {
            min-height: 40px;
            padding: 10px;
            font-weight: normal;
            line-height: $line-height-text;

            &.date {
                background: $color-bg;
            }
        }


        @media only screen and (min-width: 480px) {
            td {
                height: 60px;
                min-height: 60px;
                padding: 15px 10px;
                font-weight: normal;
                font-weight: 400;
                line-height: $line-height-text;

                &.date {
                    background: transparent;
                }
            }

            .table-record {
                width: 120px;
                max-width: 120px;
            }

            .table-organization {
                width: 27.5%;
                max-width: 275px;
            }

            .table-business {
                width: 33.5%;
                max-width: 335px;
            }
        }
    }
}



.tems {

    * {
        line-height: 1.5;
    }

    ul li, ol li {
        list-style-type: none;
        padding-left: 3px;
        text-indent: -24px;
    }

    ul li li, ol li li {
        list-style-type: none;
        padding-left: 27px;
        text-indent: -29px;
    }

    ol ol {
        margin-top: .5em;
    }

    ol ol > li::before {
        display: none;
        margin-right: 0;
        content: "";
    }

    h2 {
        font-size: 18px;
        margin-bottom: 15px;
        margin-top: 40px;

        &:nth-of-type(1) {
            margin-top: 0;
        }
    }

    dt {
        font-weight: bold;
        margin-bottom: 10px;
    }

    @media only screen and (min-width: 480px) {
        ul li, ol li {
            list-style-type: none;
            padding-left: 3px;
            text-indent: -27px;
        }

        ul li li, ol li li {
            list-style-type: none;
            padding-left: 33px;
            text-indent: -34px;
        }
    }
}
