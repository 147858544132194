@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 660px) {
}
@media only screen and (min-width: 770px) {
}
@media only screen and (min-width: 1000px) {
}


.login {
    .m-notice {
        .title {
            text-align: center;

            @media only screen and (min-width: 660px) {
                margin-bottom: 40px !important;
            }
        }
    }
}


.l-guide {
    margin-top: 0;
    margin-bottom: 80px;

    .card {
        padding: 25px;

        .card-header {
            text-align: center;
            margin-bottom: 20px;
        }
    }

    @media only screen and (min-width: 770px) {
        .card {
            min-height: 290px;
            padding: 35px 30px;

            .card-header {
                margin-bottom: 35px;
            }
        }

    }
}


.about {
    @media only screen and (max-width: 479px) {
        .mainvisual .title {
            font-size: 16px;
        }
    }
}



.news {
    .post-nav-area {
        margin-top: 0;
        padding-top: 0;

        .nav ul>li a {
            padding-top: 0;
        }
    }

    .post-area {
        margin-top: 0;
    }

    .post-group {

        // お知らせ
        ul {
            padding: 0;

            li {
                list-style-type: none;
                margin: 0;
                border-bottom: 1px solid #bababa;

                a {
                    display: flex;
                    padding: 20px 0;

                    .date-area {
                        margin-right: 20px;
                        color: $color-black;
                    }

                    .title {
                        line-height: $line-height-text;
                    }
                }
            }
        }
    }
}


.terms {
    section {
        margin-top: 0;
    }
}


.job-sheeking {

}



/* 確認画面 confirm */
/*----------------------------------------------------------------------------*/

body.confirm {
    table {
        th, td {
            padding: 10px 20px;
        }

        td {
            padding: 20px;
            font-size: 16px;
            font-weight: normal;
        }
    }

    .field {
        line-height: $line-height-text;
    }


    .field-checkbox,
    .field-radio {
        margin: inherit;
        padding: 0;
    }

    .field-note {
        padding: 0;
        margin-bottom: 10px;
    }
}