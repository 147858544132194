html {
    color: $color-base;
    font-size: 14px;
    font-family: $font-gothic;
    font-feature-settings: "pkna" 1;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.035em;
    word-wrap: break-word;

    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {
        font-size: 16px;
    }
    @media only screen and (min-width: 1000px) {

    }
}

p {
    margin-top: 1.0em;
    margin-bottom: 1.0em;
    line-height: $line-height-text;
}

.text {
    line-height: $line-height-text;
}

/* a:link, a:visited を追加するとボタンの文字色にも影響がある */
a {
    color: $color-link;
    text-decoration: none;
}
a:not(.btn):not(.btn-responce):not(.logo):not(.page-numbers):hover,
a:not(.btn):not(.btn-responce):not(.logo):not(.page-numbers).active {
    /* color: $color-link-hover; */
    /* opacity: .5; */
    text-decoration: underline;
}

.global-nav a:not(.btn):not(.btn-responce):not(.logo):hover,
.global-nav a:not(.btn):not(.btn-responce):not(.logo):active {
    /* color: $color-link-hover; */
    /* opacity: .5; */
    text-decoration: none;
}

[target="_blank"] {

    &::after {
        margin-left: 0.25em;
        @mixin icon;
        @mixin icon-outlink;
        color: $color-link;
    }
}

@media only screen and (min-width: 480px) {

    [href^="tel:"] {
        pointer-events: none;
    }
}

/*----------------------------------------------------------------------------*/
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-center { text-align: center !important; }
.text-justify { text-align: justify !important; }
.vertical-top { vertical-align: top !important; }
.vertical-middle { vertical-align: middle !important; }
.vertical-bottom { vertical-align: bottom !important; }
.vertical-baseline { vertical-align: baseline !important; }
.text-notmal { font-weight: normal; }
.text-bold { font-weight: bold; }
.text-italic { font-style: italic; }
.text-oblique { font-style: oblique; }
.text-underline { text-decoration: underline; }
.text-line-through { text-decoration: line-through; }
.text-nowrap { white-space: nowrap; }
.text-lg {
    font-size: 1.2em;
}
.text-sm {
    font-size: 0.8em;
}
.text-note {
    display: block;
    color: #404040;
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px;
    line-height: $line-height-text;
    white-space: pre-wrap;

    @media only screen and (min-width: 660px) {
        font-size: 14px;
        margin-top: 15px;
    }
}

.invalid-feedback, .text-attention {
    display: block;
    color: $color-danger;
    font-size: 14px;
}
.valid-feedback {
    display: block;
    color: $color-success;
}
.text-base {
    color: $color-base;
}
em, strong {
    font-weight: bold;
}

.required {
    display: inline-block;
    margin-left: 0.5em;
    font-weight: normal;
    font-size: 12px;
    color: $color-danger;

    @media only screen and (min-width: 660px) {
        font-size: 14px;
    }
}

.m-text-error,
.error,
.mw_wp_form form .error {
    width: 100%;
    font-size: 14px;
    color: $color-danger;
    font-weight: normal;
}
