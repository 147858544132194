/* テーブル全般 */
/*----------------------------------------------------------------------------*/
table {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 2em;
    line-height: $line-height-title;
    table-layout: fixed;

    th, td {
        vertical-align: middle;

        img, iframe {
            vertical-align: top;
        }
    }
    th {
        background: $color-bg-gray !important;
        text-align: left;
        white-space: nowrap;
        padding: 10px 15px;

        p {
            margin: 0; // WordPressの自動整形機能
        }
    }
    td {
        padding: 15px 0;
    }
    &, thead, tbody, tfoot, tr, th, td {
        box-sizing: border-box;
    }
    &, thead, tbody, tfoot, tr {
        border-top: 1px none $color-border-light;
        border-bottom: 1px none $color-border-light;
    }
    caption {
        margin-bottom: 0.75em;
        font-weight: bold;
        text-align: left;
    }

    tbody {
        tr {
            font-weight: bold;
        }
    }

    @media only screen and (min-width: 340px) {
        th {
            padding: 10px 20px;
        }
        td {
            padding: 15px 0;
        }
    }

    @media only screen and (min-width: 480px) {
        th, td {
            padding: 0.75em;
            padding: 20px 10px;
            line-height: $line-height-text;
        }

        tbody {
            th:first-child {
                width: 140px;
            }
        }
    }
    @media only screen and (min-width: 660px) {
        thead {
            th {
                padding: 10px;
            }
        }
        tbody {
            th {
                width: 200px;

                &:first-child  {
                    width: 200px;
                }
            }
        }
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
    }
}

.table-sm {
    font-size: 0.85em;

    th, td {
        padding: 0.5em;
    }
}
.table-lg {
    font-size: 1.2em;

    th, td {
        padding: 1.0em;
    }
}
.table-fixed {
    table-layout: fixed;
}


/* テーブル (横線) */
/*----------------------------------------------------------------------------*/
table, table.table-border-horizontal {
    &, thead, tbody, tfoot, tr, th, td {
        border-top-style: solid;
        border-bottom-style: solid;
    }

    @media only screen and (min-width: 0px) and (max-width: 769px) {
    }
    @media only screen and (min-width: 770px) {
        &:not(.table-calender):not(.table-bordered) {
            th, td {
                &:first-child {
                    /* padding-left: 0; */
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}


/* テーブル (枠線) */
/*----------------------------------------------------------------------------*/
table.table-bordered {
    th {
        background: $color-bg;
    }
    &, thead, tbody, tfoot, tr, th, td {
        border-style: solid;
    }
}


/* テーブル (枠線なし) */
/*----------------------------------------------------------------------------*/
table.table-border-none {
    &, thead, tbody, tfoot, tr, th, td {
        border-width: 0;
        border-style: none;
        border-color: transparent;
        background: none;
    }
}


/* レスポンシブ */
/*----------------------------------------------------------------------------*/

table:not(.not-responsive) {
    @media only screen and (min-width: 0px) and (max-width: 479px) {
        &, caption, thead, tbody, tfoot, tr, th, td {
            display: block;
            border-style: none;
        }

        &:not(.table-border-horizontal) {
            thead, tbody, tfoot {
                border-right-style: solid;
                border-left-style: solid;
            }
        }

        thead, tbody, tfoot {
            &:not(:last-child) {
                margin-bottom: 0.5em;
            }
        }
        tr {
            /* margin-bottom: 0.5em; */
            border-top-style: solid;
        }
        tr:last-child {
            margin-bottom: 0;
            border-bottom-style: solid;
        }
        th, td {
            border-bottom-style: dotted;
        }
        td:last-child, th:last-child {
            border-bottom-style: none;
        }

        th:empty {
            display: none;

            &::before {
                content: "-";
            }
        }
        th {
            // padding-top: 0.5em;
            // padding-bottom: 0.5em;
        }
    }
}


.fc table, .not-responsive {
    display: table;

    thead {
        display: table-header-group;
    }
    tbody {
        display: table-row-group;
    }
    tfoot {
        display: table-footer-group;
    }
    tr {
        display: table-row;
    }
    td, th {
        display: table-cell;
    }
}



/* スマホとPCとで縦横が入れ替わるテーブル。折り返しが発生すると崩れるのでいまいち */
table.not-responsive.table-reverse {

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
    }
    @media only screen and (min-width: 1300px) {
        display: block;

        tbody {
            display: flex;
        }

        tr {
            display: block;
        }
        td, th {
            display: block;
        }

        tr:first-child {
            td {
                white-space: nowrap;
            }
        }

        tr:not(:last-child) {
            th, td {
                border-right: none;
            }
        }
        tr {
            th:not(:last-child), td:not(:last-child) {
                border-bottom: none;
            }
        }
    }
}


/* レスポンシブ対応のテーブル */
@media only screen and (min-width: 0px) and (max-width: 479px) {
    table:not(.not-responsive).table-scroll {
        display: flex;

        thead {
            display: flex;
        }
        tbody, tfoot {
            display: flex;
            overflow-x: auto;
        }
        tr {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }
        th {
            height: 100%;
            font-size: 0.85em;
        }
        thead th:not(:first-child), thead td:not(:first-child) {
            height: 100%;
        }
        thead {
            flex-grow: 1;
        }
        tbody td {
            display: block;
            height: 100%;
            white-space: nowrap;
        }
    }
    table:not(.not-responsive):not(.no-border).table-scroll tr + tr {
        margin-top: 0;
    }
    table:not(.not-responsive):not(.no-border).table-scroll thead {
        margin-bottom: 0;
    }




    table.table-smart {
        border: 0;

        thead {
            position: absolute;
            overflow: hidden;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;

            clip: rect(0 0 0 0);
        }
        tr {
            display: block;
            margin-bottom: 0.625em;
        }
        td {
            display: block;
            min-height: 44px;
        }
        td:before {
            display: block;
            margin-bottom: 0.5em;
            font-weight: bold;
            font-size: 0.85em;
            content: attr(data-label);
        }
        td:last-child {
            border-bottom: 0;
        }

        ul, ol {
            clear: both;
            margin-top: 10px;
            text-align: left;
        }
    }
}




/* テーブルのソート機能 */
/*----------------------------------------------------------------------------*/

.table-sort {
    color: $color-link;
    cursor: pointer;
    display: block;
    border: 0;
    background-color: transparent;
    font-size: 16px;
    width: 100%;
    text-align: left;
    padding: 0;

    &::after {
        font-size: 10px;
        transition: inherit;
        @mixin icon;
        @mixin icon-arrow-simple;
        transform: scale(1) rotate(90deg);
        margin-left: 8px;
    }

    &[data-order="asc"]::after {
        transform: rotate(-90deg);
    }
    &[data-order="desc"]::after {
        transform: rotate(90deg);
    }
}
