.mainvisual {
    display: flex;
    align-items: normal;
    position: relative;
    box-sizing: border-box;
    min-height: 140px;
    padding: 15px 0;

    .container {
        overflow: hidden;
    }

    .title {
        display: flex;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 35px;
        margin: 0;
        line-height: 35px;
        text-align: center;

        .icon {
            height: 35px;
            margin-left: -15px;
            margin-right: 15px;
            font-size: 35px;
        }
    }

    @media only screen and (min-width: 480px) {
        min-height: 150px;

        .title {
            font-size: 24px;
        }
    }
    @media only screen and (min-width: 660px) {
        min-height: 190px;
        padding: 20px 0;

        .title {
            font-size: 30px;
            height: 45px;
            line-height: 45px;

            .icon {
                height: 45px;
                /* margin-left: -50px; */
                margin-right: 20px;
                font-size: 45px;
            }
        }
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
    }
}

body:not(.home) {
    .mainvisual + section {
        padding-top: 0;
    }
}




.mainvisual {
    position: relative;


    // バグらないよう修正
    .bxslider {
        height: 100%;

        .slide {
            display: none;
        }
    }

    .bx-viewport {
        height: 100% !important;

        .bxslider .slide {
            display: block;
        }
    }


    .bx-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 2;
        overflow: hidden;
        height: 560px;
        height: 100% !important;
        border: none;
        box-shadow: none;
        background: #E7F1FD;

        .bx-loading,
        .bx-prev,
        .bx-next {
            background: none;
        }
    }

    .slide {
        /* position: relative !important; */
        overflow: hidden;
        width: 100% !important;
        height: 100% !important;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: auto;
        max-width: unset;
        height: 100%;
        /* opacity: 0.58; */

        @media only screen and (min-width: 1280px) {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }

    // ページャー
    .bx-wrapper .bx-pager {
        position: absolute;
        bottom: 10px;
        width: 100%;

        &.bx-default-pager a {
            width: 10px;
            height: 10px;
            margin: 0 5px;
            border-radius: 100%;
            background-color: $color-white;

            &.active {
                background-color: $color-link;
            }

            @media only screen and (min-width: 660px) {
                width: 15px;
                height: 15px;
                margin: 0 9px;
            }
        }
    }
}


