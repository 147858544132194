/* 「03-mixin.css」を変更したい場合は、「03-mixin-pre-consolidate.css」を変更 */
/*----------------------------------------------------------------------------*/

@font-face {
    font-family: "original-icon-font";
    src: url('../font/original-icon-font.eot');
    src: url('../font/original-icon-font.eot?#iefix') format('eot'),
        url('../font/original-icon-font.woff2') format('woff2'),
        url('../font/original-icon-font.woff') format('woff'),
        url('../font/original-icon-font.ttf') format('truetype'),
        url('../font/original-icon-font.svg#original-icon-font') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@define-mixin icon {
	display: inline-block;
	font-family: "original-icon-font";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
    vertical-align: baseline;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    transform: scale(1);
    letter-spacing: 0;
}

.icon::before {
	@mixin icon;
}

/* ズレ補正 */
.icon {
    /*
    vertical-align: middle;
    font-size: 110%;
    letter-spacing: 0;
    text-indent: 0;
    */
}
.icon::before {
    /*
    margin: -0.25rem 0 0 0;
    display: inline-block;
    */
}


@define-mixin icon-arrow-simple { content: "\EA01"; }
.icon-arrow-simple::before { content: "\EA01"; }

@define-mixin icon-building { content: "\EA02"; }
.icon-building::before { content: "\EA02"; }

@define-mixin icon-checked { content: "\EA03"; }
.icon-checked::before { content: "\EA03"; }

@define-mixin icon-close { content: "\EA04"; }
.icon-close::before { content: "\EA04"; }

@define-mixin icon-email { content: "\EA05"; }
.icon-email::before { content: "\EA05"; }

@define-mixin icon-home { content: "\EA06"; }
.icon-home::before { content: "\EA06"; }

@define-mixin icon-outlink { content: "\EA07"; }
.icon-outlink::before { content: "\EA07"; }

@define-mixin icon-radio-checked { content: "\EA08"; }
.icon-radio-checked::before { content: "\EA08"; }

@define-mixin icon-search { content: "\EA09"; }
.icon-search::before { content: "\EA09"; }

@define-mixin icon-tel { content: "\EA0A"; }
.icon-tel::before { content: "\EA0A"; }

@define-mixin icon-time { content: "\EA0B"; }
.icon-time::before { content: "\EA0B"; }

@define-mixin icon-user { content: "\EA0C"; }
.icon-user::before { content: "\EA0C"; }







//------------------------------------------------------------------------------
// アニメーション
// https://material.io/design/motion/speed.html#duration
//------------------------------------------------------------------------------

// 通常
    $easingStandard:   cubic-bezier(0.4, 0.0, 0.2, 1);
// 上に登る
    $easingDecelerate: cubic-bezier(0.0, 0.0, 0.2, 1);
// 下に落ちる
    $easingAccelerate: cubic-bezier(0.4, 0.0, 1,   1);


// シンプルなアニメーション
/*
@mixin mix-transition;
*/
@define-mixin mix-transition {
    transition: all 75ms $easingStandard;

    &:hover {
        transition: all 150ms $easingStandard;
    }
}

// ボタンのアニメーション
/*
@mixin mix-transition-btn;
*/
@define-mixin mix-transition-btn {
    transition: all 200ms $easingDecelerate;

    &:hover {
        transition: all 250ms $easingStandard;
    }

    &:active {
        transition: all 100ms $easingAccelerate;

        transition: background-color 75ms $easingAccelerate;
    }
}


@define-mixin mix-empty-none {
    &:empty {
        display: none;
    }
}