/* [課題] 複数ボタンのときのクラスを用意 */
/* https://getbootstrap.com/docs/4.1/components/buttons/*/
/* https://semantic-ui.com/elements/button.html */
/*----------------------------------------------------------------------------*/

.btn-group {
    & > *:not(:last-child) {
        margin-bottom: 0.5em;
    }

    @media only screen and (min-width: 480px) {
        display: flex;
        align-items: center;

        & > *:not(:last-child) {
            margin-right: 0.5em;
            margin-bottom: 0;
        }
    }
    @media only screen and (min-width: 660px) {
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
    }
}

input.btn, button.btn {
    outline: none;
}

.btn, input[type="submit"] {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    min-width: 200px;
    max-width: 230px;
    padding: 17px 3em;
    border: none;
    box-shadow: 0 0 0 1px $color-btn inset;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    outline: none;

    @media only screen and (min-width: 660px) {
        width: auto;
        min-width: 200px;
        max-width: unset;
        font-size: 16px;
    }


    /* ボタンのサイズ */
    &.btn-lg {
        max-width: 280px;
        font-size: 14px;

        @media only screen and (min-width: 480px) {
        }
        @media only screen and (min-width: 660px) {
            width: 100%;
            max-width: 410px;
            font-size: 18px;
        }
        @media only screen and (min-width: 770px) {
        }
        @media only screen and (min-width: 1000px) {
        }
    }
    &.btn-md {
        width: 100%;
        max-width: 230px;
        font-size: 14px;

        @media only screen and (min-width: 660px) {
            max-width: 200px;
            font-size: 16px;
        }
    }
    &.btn-sm {
        min-width: auto;
        font-size: 14px;
    }
    &.btn-block {
        display: block;
        max-width: 320px;
    }

    /* ボタンの形 */
    &.btn-rect {
        padding: 1.25em 2em;
        border-radius: 4px;

        &.btn-lg {
            padding: 1.25em 2em;
        }
        &.btn-sm {
            padding: 0.75em 1.5em;
        }
    }
    &, &.btn-pill {
        padding: 17px 3em;
        border-radius: calc(1em + 1em + 1em);

        &.btn-lg {
            box-sizing: border-box;
            padding: 18px 3em;

            @media only screen and (min-width: 660px) {
                padding: 26px 3em;
            }
        }
        &.btn-md {
            box-sizing: border-box;
            padding: 18px 3em;
            @media only screen and (min-width: 660px) {
                padding: 17px 3em;
            }
        }
        &.btn-sm {
            padding: 0.75em 1.5em;
        }
    }
    &.btn-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5em;
        min-width: auto;
        height: 2.5em;
        min-height: auto;
        padding: 0;
        border-radius: 2.5em;
        line-height: 1;
        letter-spacing: 0;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1em;
            height: 1em;
            line-height: 1;
            letter-spacing: 0;

            &::before {
                display: block;
                line-height: 1;
                letter-spacing: 0;
            }
        }
    }

    /* ボタンの文字色、背景色、枠線の色(box-shadow) */
    &, &.btn-fill {
        background-color: $color-btn;
        color: $color-white;

        &:hover {
            background-color: transparent;
            color: $color-btn;
        }
    }
    &.btn-outline, &[name="submitBack"] {
        background-color: transparent;
        color: $color-btn;

        &:hover {
            background-color: $color-btn;
            color: $color-white;
        }
    }
    &.btn-skeleton {
        box-shadow: none;
        background-color: transparent;
        color: $color-btn;

        &:hover {
            background-color: $color-bg;
            color: $color-text;
        }
    }

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
    }

    &.disabled, &:disabled, &[readonly] {
        &, &:hover {
            box-shadow: 0 0 0 1px #777777 inset;
            background-color: #777777;
            opacity: 1.0;
            color: #aaaaaa;
            cursor: default;

            &::after {
                transform: none;
            }
        }
    }
}

.btn-white {
    box-shadow: 0 0 0 2px $color-white inset;

    /* ボタンの文字色、背景色、枠線の色(box-shadow) */
    &, &.btn-fill {
        background-color: $color-white;
        color: $color-btn;

        &:hover {
            background-color: transparent;
            color: $color-white;
        }
    }
    &.btn-outline {
        background-color: transparent;
        color: $color-white;

        &:hover {
            background-color: $color-white;
            color: $color-btn;
        }
    }
    &.btn-skeleton {
        box-shadow: none;
        background-color: transparent;
        color: $color-white;

        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
            color: $color-white;
        }
    }
}



.pagination-area {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 40px 0;
    font-size: 14px;

    p {
        margin: 0;
    }

    .field select {
        box-sizing: border-box;
        width: 64px;
        height: 30px;
        min-height: 30px;
        margin-left: 10px;
        padding: 0 8px;
        border-color: $color-border-light;
        border-radius: 1px;
        background-image: url(../img/common/icon-select-margin-gray.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 8px) center;
        background-size: 9.4px 6.1px;
        font-size: 14px;
    }

    // PCファースト
    @media only screen and (max-width: 659px) {
        flex-direction: column;
        align-items: baseline;
        justify-content: space-between;
        font-size: 14px;

        p {
            margin: 0;
            /* order: 1; */
        }

        .field {
            order: 2;
            margin: 15px 0;
        }

        .pagination {
            order: 3;
        }
    }
}


.pagination {
    display: flex;
    align-items: center;
    margin: auto;
    border: 1px solid $color-border-light;
    border-radius: 1px;

    & > * {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        min-width: calc(1em + 25px);
        min-height: 37px;
        padding: 10px 10px;
        border: none;
        box-shadow: none;
        background-color: transparent;
        // &.btn-skeleton { } からコピペ
        background-color: transparent;
        color: $color-black;
        color: $color-text;
        font-size: 1em;
        font-size: 1em;
        line-height: 1;
        line-height: 1;
        text-align: center;
        text-align: center;
        white-space: nowrap;
        white-space: nowrap;

        &:hover {
            position: relative;
            z-index: 1000;
            background: $color-recruitment-gray;
            opacity: 1 !important;
        }
    }

    & > *:not(:first-child) {
        border-left: 1px solid $color-border-light;
    }

    & > *.current, & > *.current:hover {
        position: relative;
        z-index: 1000;
        box-shadow: 0 0 0 2px $color-btn inset;
        background-color: $color-btn;
        color: $color-white;
    }

    & > span, & > *.disabled, & > *:disabled, & > *[readonly] {
        &, &:hover {
            box-shadow: 0 0 0 1px $color-bg inset;
            background-color: $color-bg;
            opacity: 1.0;
            cursor: default;

            &::after {
                transform: none;
            }
        }
    }

    .page-numbers:hover {
        &::before, &::after {
            color: $color-black;
        }
    }

    .prev.page-numbers, .next.page-numbers {
        width: 65px;
        min-height: 37px;

        &.disabled {
            color: #777777;
            pointer-events: none;
        }
    }

    .prev.page-numbers::before, .next.page-numbers::after {
        color: $color-border-light;
        font-size: 10px;
        transition: inherit;
        @mixin icon;
        @mixin icon-arrow-simple;
    }

    .prev.page-numbers:hover::before, .next.page-numbers:hover::after {
        color: $color-black !important;
    }

    .prev.page-numbers::before {
        transform: scale(1) rotate(180deg);
        margin-right: 4px;
    }

    .next.page-numbers::after {
        transform: scale(1);
        margin-left: 4px;
    }
}


.m-page-prev {
    margin: 30px 0;

    a {
        margin: 30px 0;
        color: $color-link;

        &::before {
            @mixin icon;
            @mixin icon-arrow-simple;
            transform: scale(1) rotate(180deg);
            margin-right: 4px;
            font-size: 12px;
            transition: inherit;
        }
    }

    @media only screen and (min-width: 660px) {
        margin: 35px 0;
    }
}
