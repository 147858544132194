@page {
    size: A4;
    margin: 10mm;
}

@media print {

    body.single-recruitment,
    body.single-job_seeking {
        width: 100%;
        -webkit-print-color-adjust: exact;

        header,
        .m-notice,
        .m-page-prev,
        .l-footer {
            display: none;
        }

        .l-form .container .section-footer {
            display: none;
        }

        .container {
            width: 100%;
        }
    }
}
