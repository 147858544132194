@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 660px) {
}
@media only screen and (min-width: 770px) {
}
@media only screen and (min-width: 1000px) {
}

.l-footer {
    background-color: $color-black;

    .bg-gray {
        background-color: $color-bg-base;
    }

    .contact-area {
        margin-bottom: 0;
        padding: 50px 0;

        .layout {
            align-items: center;

             > * {
                flex: 1 1 auto;
            }
        }

        .m-contact-link {
            text-align: center;
            margin-bottom: 50px;

            .text {
                margin: 30px 0;
            }
        }

        @media only screen and (min-width: 770px) {
            .m-contact-link {
                text-align: left;
                margin-bottom: 0;
            }
        }
    }

    .footer-nav-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 28px 30px;

        .logo-nav {
            .logo {
                display: block;

                img {
                    display: block;
                    width: 330px;
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        .nav {
            .item a {
                color: $color-white;
                font-size: 12px;
                padding: 10px 0px 11px;
            }

            .nav-nest {
                padding: 0 20px;
            }
        }
    }

    .footer-copy-area {
        display: flex;
        align-items: center;
        justify-content: right;
        height: 90px;
        margin: 0 30px;
        text-align: right;

        border-top: 1px solid;
    }
}


.l-address-area {
    margin: 0;
    padding: 20px 15px;
    font-size: 14px;

    a {
        color: $color-text;
    }

    .m-address {
        font-size: 14px;
        line-height: 1.5;
        margin-top: 20px;

        dt {
            font-weight: bold;
        }

        dd {
        }
    }

    .m-text {
        margin-top: 20px;
        font-size: 12px;
        line-height: 1.3;
    }

    @media only screen and (min-width: 770px) {
        width: 520px;

        .m-address {
            font-size: 16px;
        }
    }
}

.copyright {
    width: 100%;
    font-size: 12px;

    a {
        color: $color-white;
    }
}



// PCファースト
@media only screen and (max-width: 1200px) {
    .l-footer .footer-nav-area {
        flex-direction: column;
        padding: 30px 15px;

        .logo-nav {
            margin-bottom: 15px;
        }
    }

    .l-footer .footer-copy-area {
        text-align: center;
    }
}
@media only screen and (max-width: 770px) {
    .l-footer .footer-nav-area {
        flex-direction: column-reverse;
        padding: 0;

        .logo-nav {
            margin-bottom: 0;
            padding: 0 10px;
        }

        .nav {
            width: 100%;
            margin-bottom: 40px;

            ul {
                flex-direction: column;

                .item {
                    width: 100%;

                    border-bottom: 1px solid $color-border;
                    a {
                        display: flex;
                        justify-content: space-between;
                        padding: 18px;
                        font-weight: bold;
                        font-size: 1em;

                        &::after {
                            display: inline-block;
                            height: 10px;
                            color: $color-border;
                            font-size: 10px;
                            font-family: original-icon-font;
                            content: "\EA01";
                        }
                    }
                }

                .nav-nest {
                    width: 100%;
                    height: auto;
                    margin: 0;
                    padding: 0;
                    border: 0;

                    ul {
                        flex-direction: row;
                        li + li {
                            border-left: 1px solid $color-border;
                        }
                    }
                }

                li + li {
                    margin-left: 0;
                }
            }
        }
    }

    .l-footer .footer-copy-area {
        height: 60px;
        padding: 0 0 20px;

        border-top: 0;

        .copyright {
            font-size: 10px;
        }
    }
}




/* footer */
/*----------------------------------------------------------------------------*/

.back-top {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 10000;
    transform: translate(0, 0);
    opacity: 1.0;
    transition: opacity 0.5s ease-in-out 0s,
    transform 0.5s ease-in-out 0s;

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
    }
    @media only screen and (min-width: 770px) {
        right: 40px;
        bottom: 40px;
    }
    @media only screen and (min-width: 1000px) {
    }
    .btn {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 60px;
        height: 60px;
        padding: 10px;
        border: 1px solid #5a676b;
        border-radius: 50%;
        box-shadow: none;
        color: #5a676b;
        font-size: 10px;
        line-height: 1;

        &:not(.btn-sm)::before {
            display: none;
        }
        &:not(.btn-sm)::after {
            position: static;
            transform: none;
            // margin: 0 auto 5px auto;
            margin: auto;
            font-size: 24px;
        }

        @media only screen and (min-width: 480px) {
        }
        @media only screen and (min-width: 660px) {
        }
        @media only screen and (min-width: 770px) {
            &:not(.btn-sm):hover {
                border-color: #3db5da;
                color: #3db5da;

                &::after {
                    transform: translate(0, -10px);
                }
            }
        }
        @media only screen and (min-width: 1000px) {
        }
    }
}

.back-top.inactive {
    transform: translate(0, 200px);
    opacity: 0;
}
.back-top.mode-footer {
    position: absolute;

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
    }
}
