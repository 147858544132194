/* ローディング */
.load-area {
    display: none;
    // display: block;
    // background: #fff;
    /* テスト用 */
    // background: #fff !important;
    // opacity: 1 !important;
    // display: block !important;
    /* テスト用 */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50000;
}

html.load-active {
    body {
        background: #ffffff;
    }
    .load-area {
        display: block;
        width: 100%;
        height: 100%;
        background: #ffffff;
        transition: all 0.35s ease 0.0s;

        .svg-load {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 50px;
            left: 0;
            width: 150px;
            margin: auto;
        }

        @media only screen and (min-width: 480px) {
        }
        @media only screen and (min-width: 660px) {
        }
        @media only screen and (min-width: 770px) {
            .svg-load {
                width: 200px;
            }
        }
        @media only screen and (min-width: 1000px) {
        }
    }
}

/* opacityだけだと、アニメーションが正常に動作しない場合がある */
html.load-active:not(.load-fadeout) {
}
html.load-fadeout {
    .load-area {
        opacity: 0;
    }
}
html:not(.load-active) {
    body {
        background: #ffffff;
    }
}