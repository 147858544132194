.module-group,
.module,
.border-group,
.border,
.card-group,
.card,
.banner-group,
.banner {
    /*
    margin-top: 20px;
    margin-bottom: 20px;
    */

    .content {

        & > * {
            display: block;
        }
        & > *:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .text {
        line-height: $line-height-title;
    }

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {

    }
    @media only screen and (min-width: 1000px) {

    }
}


.module-group,
.border-group,
.card-group {
    margin: -10px;

    & > * {
        margin: 10px;
        box-sizing: border-box;
    }

    @media only screen and (min-width: 480px) {
        display: flex;
        flex-wrap: wrap;

        & > * {
            flex: 1 0 40%;
        }
    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {
        & > * {
            flex: 1 0 20%;
        }
    }
    @media only screen and (min-width: 1000px) {

    }
}


/* モジュールのエリア */
/*----------------------------------------------------------------------------*/
.module-group > *,
.module {
    & > * {
        display: block;
    }
    & > *:not(:last-child) {
        margin-bottom: 10px;
    }
}


/* ボーダーのエリア */
/*----------------------------------------------------------------------------*/
.border-group > *,
.border {
    border: 2px solid $color-link;
    border-radius: 4px;

    & > * {
        display: block;
    }
    & > *:not(:last-child) {
        margin-bottom: 10px;
    }

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
    }
    @media only screen and (min-width: 770px) {
        // padding: 30px;
    }
    @media only screen and (min-width: 1000px) {
    }
}



/* カード(背景あり)のエリア */
/*----------------------------------------------------------------------------*/
.card-group > *,
.card {
    padding: 15px;
    background: #fff;
    color: $color-text;
    display: flex;
    flex-direction: column;
    flex-basis: 0;

    & > * {
        display: block;
    }
    & > *:not(:last-child) {
        margin-bottom: 10px;
    }

    .thumbnail:first-child {
        margin-top: -15px;
        margin-left: -15px;
        margin-right: -15px;
    }
    .thumbnail:last-child {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -15px;
    }

    .card-footer {
        text-align: center;
        margin-top: auto;
    }

    &:not(.border) {
        border: 2px solid transparent;
    }

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {
        padding: 30px;
        & > *:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    @media only screen and (min-width: 1000px) {

    }
}


/* 枠線のエリア */
/*----------------------------------------------------------------------------*/
.banner-group {
    & > *:not(:last-child) {
        margin-bottom: 20px;
    }
}
.banner-group > *,
.banner {
    display: flex;

    & > * {
        flex: 1 1 0%;
    }

    & > .content {
        padding: 15px;
        background: #fff;

        & > * {
            display: block;
        }
        & > *:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}


/* 横並びのナビゲーション */
/*----------------------------------------------------------------------------*/
@define-mixin nav-underline {
	display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: $color-link;
    border-radius: 100em;
    content: "";
    pointer-events: none;
}

.nav {

    ul {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        padding-left: 0;

        & > li {
            margin: 0;
            padding: 0;
            line-height: 1;
            text-align: right;
            list-style-type: none;

            a {
                display: flex;
                align-items: center;
                position: relative;
                box-sizing: border-box;
                padding: 10px 2px 11px;
                opacity: 1.0;
                line-height: 1;

                &:not(.btn).active {
                    position: relative;
                    opacity: 1;
                    text-decoration: none !important;
                }

                &.btn {
                    justify-content: center;
                }
            }

            + li {
                margin-left: 15px;
            }
        }


        li.item a {
            color: $color-link;
        }

        li.nav-nest {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 26px;
            padding: 0 15px;
            border: 1px solid $color-border;

            border-top: 0;
            border-bottom: 0;

            + li.nav-nest {
                border-left: 0;
                margin-left: 0;
            }
        }
    }

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {

    }
    @media only screen and (min-width: 1000px) {

    }

    &:not(.global-nav):not(.footer-nav) {

        ul li a {

            &:not(.btn).active {
                position: relative;
                text-decoration: none !important;

                &::before {
                    @mixin nav-underline;
                    opacity: 1;
                }
            }

            &::before {
                @mixin nav-underline;
                width: 0;
                opacity: 0.2;
                transition: all 150ms $easingStandard;
            }

            &:hover {
                text-decoration: none !important;

                &::before {
                    width: 100%;
                }
            }
        }
    }
}



/* パンくずリスト */
/* https://getbootstrap.com/docs/4.1/components/breadcrumb/ */
/*----------------------------------------------------------------------------*/
.breadcrumb {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 140%;

    ol {
        display: flex;
        padding: 0;
        transform-origin: top left;
        transform: scale(0.714);
        overflow: hidden;

        li {
            display: block;
            margin: 0;
            font-size: 14px;
            line-height: 16px;
            white-space: nowrap;

            &:last-child {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        li + li::before {
            padding: 0 10px;
            font-size: 10px;
            @mixin icon;
            @mixin icon-arrow-simple;
        }

        .icon-home {
            font-size: 16px;
        }
    }

    @media only screen and (min-width: 660px) {
        width: 100%;

        ol {
            transform: scale(1);
        }
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
    }
    @media only screen and (min-width: 1300px) {
    }
}




/* [課題] モーダル */
/* https://getbootstrap.com/docs/4.1/components/modal/ */
/*----------------------------------------------------------------------------*/


/* [課題] ポップオーバー、ツールチップス、ポップアップ */
/* https://getbootstrap.com/docs/4.1/components/popovers/ */
/* https://getbootstrap.com/docs/4.1/components/tooltips/ */
/* https://semantic-ui.com/modules/popup.html */
/*----------------------------------------------------------------------------*/


/* [課題] プログレス */
/* https://getbootstrap.com/docs/4.1/components/progress/ */
/* https://semantic-ui.com/modules/progress.html */
/*----------------------------------------------------------------------------*/
.progress {
    background: rgba(220, 220, 220, 0.5);
    min-height: 15px;
    // border-radius: 4px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    .progress-bar {
        background: $color-base;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        font-size: 10px;
        line-height: 1;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/* [課題] ローダー */
/* https://semantic-ui.com/elements/loader.html */
/*----------------------------------------------------------------------------*/


/* [課題] ステップ */
/* https://semantic-ui.com/elements/step.html */
/*----------------------------------------------------------------------------*/

.step-group {
    display: flex;
    position: relative;

    .step {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        position: relative;
        max-width: 150px;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        cursor: default;

        .badge {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            position: relative;
            z-index: 1000;
            width: 24px;
            height: 24px;
            padding: 0;
            border-radius: 50%;
            box-shadow: 0 0 0 1px #777777 inset;
            background-color: $color-white;
            color: $color-black;
            font-weight: 700;
            font-size: 12px;
        }
        .content {
            padding: 0 5px 0 10px;
            color: $color-black;
            z-index: 10;
            background-color: $color-white;

            .title {
                font-size: 14px;
            }
        }

        &.active {
            .badge {
                box-shadow: 0 0 0 1px $color-link inset;
                background-color: $color-link;
                color: $color-white;
            }
            .content {
                color: $color-base;
            }
        }
        &:hover {
            opacity: 1.0;
        }
    }

    .step:not(:first-child) {
        &::before {
            display: inline-block;
            z-index: 0;
            width: 30px;
            height: 20px;
            margin-right: 3px;
            background-color: #fff;
            background-position: right;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url(../img/common/icon-long-arrow.svg);
            content: "";

            @media only screen and (min-width: 320px) {
                width: 40px;
            }

            @media only screen and (min-width: 480px) {
                width: 73px;
            }
        }
    }

    @media only screen and (min-width: 660px) {
        .step .badge {
            width: 28px;
            height: 28px;
            font-size: 16px;
        }

        .step .content {
            padding: 0 0 0 10px;

            .title {
                font-size: 16px;
            }
        }
    }
}



/* [課題] Accordion */
/*  https://semantic-ui.com/modules/accordion.html */
/*----------------------------------------------------------------------------*/


/* [課題] 検索できるドロップダウン */
/* https://semantic-ui.com/modules/dropdown.html */
/*----------------------------------------------------------------------------*/


/* [課題] Scrollspy */
/* https://getbootstrap.com/docs/4.1/components/scrollspy/ */
/*----------------------------------------------------------------------------*/







/* box */
/*----------------------------------------------------------------------------*/

.box {
    margin: 0;
    padding: 20px;
    width: 200px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    flex: 0 0 200px;

    section& {
        display: block;
    }
}

.box.box-img {
    padding: 0;
}
.box.box-text {
    width: auto;
    flex: 1 1 auto;
}

.box:not(.box-white) {
    background-color: $color-base;
    color: #fff;

    table {
        &,
        thead,
        tbody,
        tfoot,
        tr,
        th,
        td {
            border-color: #fff;
        }
    }
}
.box.box-white {
    background-color: #fff;
    color: $color-base;
}

@media only screen and (min-width: 480px) {
    .box-group {
        display: flex;
        /*
        & > .box {
            flex: 1 1 auto;
        }
        */
    }
}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {
    .box {
        padding: 20px;

    }
    .box-md {
        // padding: 60px;
        padding: 40px;
        width: 400px;
        min-height: 400px;
        flex: 0 0 400px;
    }
    .box-lg {
        // padding: 100px;
        padding: 80px;
        width: 600px;
        min-height: 400px;
        flex: 0 0 600px;
    }
    .box-fluid {
        width: auto;
        height: auto;
        padding: 80px;
        flex: 1 1 auto;
    }
}

/*
.box-title {
    height: auto;
}
*/

.box-text {
    min-height: 200px;
    height: auto;

    .title {
        margin-bottom: 15px;
        line-height: 1;
    }

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {
        max-width: 400px;
    }
    @media only screen and (min-width: 770px) {
        .title {
            margin-bottom: 25px;
            line-height: 1;
        }
    }
    @media only screen and (min-width: 1000px) {

    }
    @media only screen and (min-width: 1200px) {

    }
    @media only screen and (min-width: 1400px) {

    }
    @media only screen and (min-width: 1600px) {

    }
}


.box {
    .btn {
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }
}


/* box */
/*----------------------------------------------------------------------------*/
.circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 0 0 2px $color-base inset;
    box-sizing: border-box;

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {
        width: 160px;
        height: 160px;
    }
    @media only screen and (min-width: 1000px) {

    }

    /* aタグの装飾をキャンセル */
    html body & {
        border: none !important;
    }

    /* ボタンのサイズ */
    &.circle-lg {

    }
    &.circle-sm {
        width: 70px;
        height: 70px;
    }

    &,
    &.circle-fill {
        background-color: $color-btn;
        color: #fff;

        a&:hover {
            background-color: transparent;
            color: $color-btn;
        }
    }
    &.circle-outline {
        background-color: transparent;
        color: $color-btn;

        a&:hover {
            background-color: $color-btn;
            color: #fff;
        }
    }
    &.circle-skeleton {
        background-color: transparent;
        box-shadow: none;
        color: $color-text;

        a&:hover {
            background-color: rgba(0, 0, 0, 0.2);
            color: $color-text;
        }
    }

    & > span {
        display: block;
        text-align: center;
        line-height: 1.2;

        span {
            display: inline-block;
        }

        @media only screen and (min-width: 480px) {

        }
        @media only screen and (min-width: 660px) {

        }
        @media only screen and (min-width: 770px) {
            line-height: 1.5;
        }
        @media only screen and (min-width: 1000px) {

        }
    }
}



















.m-notice {
    padding: 15px;
    margin-bottom: 40px;

    .title {
        margin-bottom: 20px !important;
        font-size: 19px;
    }

    :last-child {
        margin-bottom: 0;
    }

    + p {
        margin-bottom: 80px;
    }


    @media only screen and (min-width: 340px) {
        padding: 25px;
    }

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
        .title {
            font-size: 30px;
        }
    }
    @media only screen and (min-width: 770px) {
        padding: 40px 35px;
        margin-bottom: 40px;
    }
    @media only screen and (min-width: 1000px) {
    }
}



.m-note {
    font-size: 12px;

    .title {
        margin-bottom: 15px;
        line-height: 1;
    }

    @media only screen and (min-width: 660px) {
        padding: 0 5px;
    }
}