ul, ol {
    margin-top: 1.0em;
    margin-bottom: 1.0em;
    padding-left: 1.5em;
    line-height: $line-height-text;
    list-style-position: outside;

    ul, ol {
        margin-top: 1.0em;
        margin-bottom: 1.0em;
    }

    .title + & {
        margin-top: 0;
    }

    @media only screen and (min-width: 480px) {
        // margin-top: 1.5em;
        // margin-bottom: 1.5em;
    }
    @media only screen and (min-width: 660px) {
    }
    @media only screen and (min-width: 770px) {
        // margin-top: 2em;
        // margin-bottom: 2em;
    }
    @media only screen and (min-width: 1000px) {
    }
}
li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

    li {
        margin-top: 0.5em;
        margin-bottom: 0.5em;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    & > p {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
    }
}
ul {
    & > li {
        list-style-type: disc;
    }
    ul {
        & > li {
            list-style-type: circle;
        }
    }
}
ol {
    & > li {
        list-style-type: decimal;
    }
    ol {
        padding-left: 0;

        & > li {
            display: flex;
            // list-style-type: decimal;
            list-style-type: none;
            counter-increment: cnt;

            &::before {
                display: marker;
                margin-right: 0.5em;
                content: "(" counter(cnt) ") ";
            }
        }
    }
}

/* 注釈リスト */
/*----------------------------------------------------------------------------*/
.list-note {
    list-style-position: outside;

    & > li {
        list-style-type: none;
    }
    & > li::before {
        display: marker;
        width: 1em;
        margin-right: 0.5em;
        margin-left: -1.5em;
        content: "※";
    }
}



/* 通常リスト */
/*----------------------------------------------------------------------------*/

.list-text {
    padding: 0;

    li {
        position: relative;
        margin: 0;
        padding-left: 1em;
        list-style-type: none;

        &::before {
            position: absolute;
            left: 0;
            counter-increment: li;
            content: "\30FB";
        }

        @media all and (-ms-high-contrast:none){
            *::-ms-backdrop, &::before { top: 10px; }
        }
    }
}
