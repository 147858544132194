@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 660px) {
}
@media only screen and (min-width: 770px) {
}
@media only screen and (min-width: 1000px) {
}

body.home {

    main {
        margin-top: 0;
    }

    .mainvisual {
        position: relative;
        box-sizing: border-box;
        height: 900px;
        max-height: 435px;
        background-color: #5c6e87;
        background-image: url(../img/home/mainvisual-01.jpg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;

        .mainvisual-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            position: relative;
            right: 0;
            left: 0;
            z-index: 1000;
            width: 100%;
            margin-bottom: 20px;
            color: $color-link;
            font-weight: bold;
            pointer-events: none; // bx-pagerとかぶってしまうため

            .catch {
                font-size: 30px;
            }
            .catch-sub {
                margin-top: calc(1em - 5px);
                font-size: 20px;
            }
        }






        img {
            animation-name: img-01; /* 名前 */
            animation-duration: 4400ms; /* 時間 */
            animation-timing-function: $easingAccelerate; /* アニメ */

            animation-fill-mode: both; /* 実行前・実行後の状態 */
        }

        @media only screen and (max-width: 339px) {
            // iphone 5
            max-height: 350px;

            .mainvisual-content {

                .catch {
                    font-size: 29px;
                }
            }
        }

        @media only screen and (max-width: 479px) {
            .slide-01 img {
            }
            .slide-02 img {
            }
            .slide-03 img {
                left: 100%;
            }
            .slide-04 img {
                left: 0;
            }
        }

        @media only screen and (min-width: 480px) {

            .mainvisual-content {
                text-align: left;

                .catch {
                    font-size: 40px;
                }
            }
        }
        @media only screen and (min-width: 660px) {
            max-height: 560px;

            .mainvisual-content {
                .catch {
                    font-size: 60px;
                    letter-spacing: 0.035em;
                }
                .catch-sub {
                    font-size: 30px;
                    letter-spacing: 0.045em;
                }
            }

            img {
                animation-name: none;
            }
        }
        @media only screen and (min-width: 770px) {
        }
        @media only screen and (min-width: 1280px) {
            height: calc(100vw * 560 / 1280);
            max-height: 700px;
        }
    }



    .home-service-area {
        .img {
            position: relative;
            z-index: 1000;
        }
        .content {
            position: relative;
            // max-width: 60%;
            box-sizing: border-box;
            padding: 30px;

            .logo {
                display: block;
                width: 200px;
                margin-left: -15px;
            }

            .btn {
                display: inline-block;
                margin-top: 40px;
            }
        }

        @media only screen and (min-width: 480px) {
            .layout:not(.layout-reverse) {
                display: block;

                & > :not(:last-child) {
                    margin-right: 0;
                    margin-bottom: 30px;
                }
            }
            .img {
                // margin-right: -20px;
            }
        }
        @media only screen and (min-width: 660px) {
            .layout:not(.layout-reverse) {
                display: flex;

                & > :not(:last-child) {
                    margin-right: 30px;
                    margin-bottom: 0;
                }
            }
        }
        @media only screen and (min-width: 770px) {
            .layout:not(.layout-reverse) {
                .img {
                    flex: 1 1 50%;
                    margin-right: -20px;
                }
                .content {
                    flex: 1 1 50%;
                    margin-top: 50px;
                    margin-left: -20px;
                    padding: 40px 60px;

                    .btn {
                        display: inline-block;
                        margin-top: 40px;
                    }
                }
            }
        }
        @media only screen and (min-width: 1000px) {
            .layout:not(.layout-reverse) {
                .img {
                    margin-right: -40px;
                }

                .content {
                    margin-left: -40px;
                    padding: 50px 100px;

                    .logo {
                        width: 300px;
                        margin-left: -25px;
                    }

                    .btn {
                        display: inline-block;
                        margin-top: 40px;
                    }
                }
            }
        }
        @media only screen and (min-width: 1300px) {
            .content {
                padding: 70px 130px;

                .btn {
                    display: inline-block;
                    margin-top: 80px;
                }
            }
        }
    }
}


.l-home-consept {
    text-align: center;

    .section-header {
        margin-bottom: 30px;

        @media only screen and (max-width: 340px) {
            // iphone 5
            .title {
                font-size: 18px;
            }
        }

        @media only screen and (min-width: 660px) {
            margin-bottom: 40px;
        }
    }
}


.l-home-guide {

    .module-front {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .module-header {
            margin-bottom: 0;

            .title {
                display: flex;
                align-items: center;
            }
        }


        .btn {
            box-shadow: 0 0 0 2px $color-recruitment inset;
            background-color: $color-recruitment;
            color: $color-white;

            &:hover {
                background-color: transparent;
                color: $color-recruitment;
            }

            &.btn-lg {
                width: 100%;
            }
        }


        .bg-gray {
            margin-right: -20px;
            margin-left: -20px;
            padding: 30px 20px;
            background-color: $color-recruitment-gray;
            text-align: center;

            p {
                margin-bottom: 25px;
                text-align: left;
            }

            .btn {
                width: 100%;
                max-width: 230px;
            }

            @media only screen and (min-width: 660px) {
                margin-right: 0;
                margin-left: 0;
                padding: 25px;

                .btn {
                    max-width: 300px;
                }
            }
        }
        .content {
            height: 100%;
            padding: 30px 20px 10px;
            border: 2px solid #ccd4e6;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;

            border-top: 0;

            .text {
                margin-bottom: 30px;
                font-weight: bold;
                line-height: $line-height-text;


                 + .btn {
                    margin: 30px auto;
                }
            }
        }

         + .module-front {
            .module-header {
                background-color: $color-job;
            }

            .btn {
                box-shadow: 0 0 0 2px $color-job inset;
                background-color: $color-job;
                color: $color-white;

                &:hover {
                    background-color: transparent;
                    color: $color-job;
                }
            }

            .bg-gray {
                background-color: $color-job-gray;
            }
        }
    }


    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
        .content {
            padding: 30px 20px;
        }
    }
    @media only screen and (min-width: 770px) {

        .module-front {
            .content {

                .text {
                    margin-bottom: 20px;
                    font-size: 18px;
                    line-height: 30px;


                     + .btn {
                        margin: 20px 0;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1000px) {
        .module-front {
            .content {
                padding: 35px 40px 20px;

                .text {
                    margin-bottom: 35px;

                     + .btn {
                        margin: 35px 0 40px;
                    }
                }
            }
        }
    }
}



.l-home-news {

    .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        h1 {
            margin: 0;
        }

        .btn {
            min-width: auto;
            max-width: 130px;
            padding: 0.75em 1.5em;
            font-size: 14px;
        }
    }

    .newarrival-joboffer {

        .post-group {

            a {
                border: 3px solid #2c4c99;
                color: #2c4c99;
                font-weight: bold;
                background-color: #eaedfb;

                .title {
                    font-weight: bold;
                }

                &:hover {
                    background-color: #cfd7fa;
                }
            }
        }
    }

    .post-group {
        padding: 0;

        @media only screen and (max-width: 659px) {
            margin-bottom: 15px;
        }

        a {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            box-sizing: border-box;
            width: 100%;
            height: 120px;
            padding: 20px;
            border-radius: 4px;
            background-color: $color-bg-gray;
            color: $color-link;

            &:hover {
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                background-color: $color-recruitment-gray;
            }
        }

        .title {
            display: -webkit-box;
            overflow: hidden;
            width: 100%;
            height: 100%;
            margin-bottom: 0;
            font-weight: normal;

            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        .date-area {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            font-size: 13px;
        }

         + .post-group {
            margin-top: 16px;
        }
    }

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {

        .section-header .btn {
            width: 100%;
            max-width: 200px;
            padding: 18px 3em;
            font-size: 16px;
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            margin: -10px;
        }

        .post-group {
            display: inline-block;
            width: calc( (100% / 2) - 20px);
            margin: 10px;
            padding: 0;

            a {
                padding: 30px 20px;
            }

            .title {
                line-height: 27px;
            }

            .date-area {
                margin-bottom: 25px;
            }

             + .post-group {
                margin-top: 10px;
            }
        }
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
        .post-group {
            width: calc( (1000px / 4) - 15px) !important;

            a {
                height: 180px;
            }

             + .post-group {
                margin-top: 10px;
            }
        }
    }

    @media only screen and (min-width: 1400px) {
    }
}





.module-header {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 72px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: $color-recruitment;

    .content {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .title {
        color: $color-white;
    }

    @media only screen and (min-width: 660px) {
        min-height: 100px;
    }
}
